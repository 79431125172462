import {
  COMMODITY_ELECTRICITY,
  COMMODITY_GAS,
  PARTNER_HOUSEHOLD,
  PERIOD_MONTH,
  CONTRACT_DELIVERY_EMAIL,
  PAYMENT_METHOD_BANK_TRANSFER,
  SEND_INVOICES_EMAIL,
  FILL_DATA_MYSELF,
  CONTRACT_VALIDITY_CERTAIN_TIME,
  NEW,
  TYPE_NEW_OM,
} from "../constants";

export default {
  commodityValue: COMMODITY_GAS,
  partnerValue: PARTNER_HOUSEHOLD,
  pricePeriod: PERIOD_MONTH,
  process: TYPE_NEW_OM,
  calculate: {
    [COMMODITY_ELECTRICITY]: {
      knownConsumption: true,
      detail: {},
      appliances: {},
    },
    [COMMODITY_GAS]: {
      knownConsumption: true,
      detail: {},
      appliances: {},
    },
  },
  contact: {
    addresses: {
      deliveryAddressIsSame: true,
    },
    contractDelivery: CONTRACT_DELIVERY_EMAIL,
  },
  payments: {
    paymentsFrequency: PERIOD_MONTH,
    paymentMethods: {
      deposits: {
        paymentType: PAYMENT_METHOD_BANK_TRANSFER,
      },
      arrears: {
        paymentType: PAYMENT_METHOD_BANK_TRANSFER,
      },
      overpayments: {
        paymentType: PAYMENT_METHOD_BANK_TRANSFER,
      },
      depositPaymentTypeForAll: false,
    },
    sendInvoices: {
      sendInvoiceType: SEND_INVOICES_EMAIL,
    },
  },
  supplyPoint: {
    fillDataType: FILL_DATA_MYSELF,
    data: {
      supplyPointAddressIsSame: false,
      currentSupplier: {
        contractValidity: CONTRACT_VALIDITY_CERTAIN_TIME,
      },
    },
  },
  transfer: {
    previousCustomer: {
      previousPartnerValue: PARTNER_HOUSEHOLD,
    },
  },
};
