import React from "react";

import File from "../../components/File";
import locs from "../../localization";

import { UPLOAD_ATTACHMENTS_PATH } from "../../api";
import isNotEmpty from "../../validations/isNotEmpty";
import formValidation from "../../validations/formValidation";
import { Field } from "redux-form";
import formatName from "../../helpers/formatName";
import { MEGABYTE } from "../../constants";

interface InvoiceFileProps {
  showDropzone?: boolean;
  theme?: any;
  isRequired?: boolean;
  name: string;
}

function InvoiceFile({ showDropzone = true, isRequired = false, theme, name }: InvoiceFileProps) {
  return (
    <Field
      name={formatName(name, "invoice")}
      fixedName
      type="file"
      props={{
        endPoints: { request: UPLOAD_ATTACHMENTS_PATH },
        showDropzone,
        theme,
        sizeLimit: 6 * MEGABYTE,
      }}
      component={File}
      validate={isRequired ? [formValidation(isNotEmpty, locs("errors.fileIsRequired"))] : undefined}
    />
  );
}

export default InvoiceFile;
