/**
 * Returns true if value
 *  - is true
 *  - is string with true
 *
 * @param value
 * @return {boolean}
 */
export default function isChecked(value) {
  return value === true || value === "true";
}
