import React from "react";
import classNames from "classnames";
import locs from "../../../localization";

import { priceFormatter } from "../../../helpers";
import styles from "./styles.scss";
import { ProductPrices } from "../../../reducers/types";

export interface PriceDetailProps {
  prices?: Omit<ProductPrices, "contractDuration">;
  className?: string;
}

export default function PriceDetail({ className, prices }: PriceDetailProps) {
  return (
    <div className={classNames(styles.priceDetail, className)}>
      <div className={styles.row}>
        <div className={styles.cell}>{locs("labels.pp_price")}</div>
        <div className={classNames(styles.cell, styles.price)}>{priceFormatter(prices.supplies, 2)} Kč</div>
      </div>
      <div className={styles.row}>
        <div className={styles.cell}>{locs("labels.regulated_price")}</div>
        <div className={classNames(styles.cell, styles.price)}>{priceFormatter(prices.distribution, 2)} Kč</div>
      </div>
      <div className={styles.row}>
        <div className={styles.cell}>{locs("labels.vat")}</div>
        <div className={classNames(styles.cell, styles.price)}>{priceFormatter(prices.taxes, 2)} Kč</div>
      </div>
      <hr />
      <div className={classNames(styles.row, styles.total)}>
        <div className={styles.cell}>{locs("labels.sum")}</div>
        <div className={classNames(styles.cell, styles.price)}>{priceFormatter(prices.total, 2)} Kč</div>
      </div>
      <hr />
      <div className={styles.comment}>{locs("texts.offer_is_valid")}</div>
    </div>
  );
}
