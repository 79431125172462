import { createSelector } from "reselect";
import getValue from "lodash/get";
import find from "lodash/find";
import filter from "lodash/filter";
import {
  ENUMERATION_APPLIANCE,
  ENUMERATION_BANK,
  ENUMERATION_CATEGORY,
  ENUMERATION_CIRCUIT_BREAKER,
  ENUMERATION_COMMODITY,
  ENUMERATION_ESTIMATE,
  ENUMERATION_INSTALLATION_FLOOR,
  ENUMERATION_PARTNER,
  ENUMERATION_POSITIONS,
  ENUMERATION_PRODUCT,
  ENUMERATION_SETTINGS,
  ENUMERATION_SUPPLIER,
  ENUMERATION_TARIFF,
  ENUMERATION_TITLE_PREFIX,
  ENUMERATION_TITLE_SUFFIX,
} from "../constants/enumerations";
import { getCommodity, getPartner } from "./contract";
import { Estimate, Tariff } from "../constants/interfaces";

type EnumName =
  | typeof ENUMERATION_APPLIANCE
  | typeof ENUMERATION_BANK
  | typeof ENUMERATION_CATEGORY
  | typeof ENUMERATION_CIRCUIT_BREAKER
  | typeof ENUMERATION_COMMODITY
  | typeof ENUMERATION_ESTIMATE
  | typeof ENUMERATION_PARTNER
  | typeof ENUMERATION_PRODUCT
  | typeof ENUMERATION_SUPPLIER
  | typeof ENUMERATION_TARIFF
  | typeof ENUMERATION_TITLE_PREFIX
  | typeof ENUMERATION_TITLE_SUFFIX
  | typeof ENUMERATION_POSITIONS
  | typeof ENUMERATION_INSTALLATION_FLOOR
  | typeof ENUMERATION_SETTINGS;

/**
 * Pull enumeration data from store blob
 *
 * @param {Object} state
 * @param {String} name
 * @return {Array}
 */
export const getEnumeration = <K>(state, name: EnumName): K[] => getValue(state, ["enumerations", name, "data"], []);

export const getEnumerationRecordByValue = <K>(state, name, value): K | undefined => find(getEnumeration(state, name), { value });

export const getEnumerationRecordById = <K>(state, name, id): K | undefined => find(getEnumeration(state, name), { id });

export const getAllTariffs = state => getEnumeration<Tariff>(state, ENUMERATION_TARIFF);

export const getTariffs = createSelector(
  getCommodity,
  getPartner,
  getAllTariffs,
  (commodity, partner, tariffs) => filter(tariffs, { commodityId: commodity.id, partnerId: partner.id }) as Tariff[]
);

export const getAllEstimates = state => getEnumeration<Estimate>(state, ENUMERATION_ESTIMATE);

export const getEstimates = createSelector(
  getCommodity,
  getPartner,
  getAllEstimates,
  (commodity, partner, tariffs) => filter(tariffs, { commodityId: commodity.id, partnerId: partner.id }) as Estimate[]
);
