import {
  CONTRACT_CHECK_NEW_TRANSFER_REQUEST,
  CONTRACT_CHECK_NEW_TRANSFER_SUCCESS,
  CONTRACT_CHECK_NEW_TRANSFER_FAILURE,
} from "../constants/actions";

import { DATA_FAILURE, DATA_REQUEST, DATA_SUCCESS } from "../constants/async";

export interface NewTransferState {
  status?: string;
  error?: any;
}

export const newTransferInitialState: NewTransferState = {
  status: null,
};

export default (state = newTransferInitialState, action) => {
  switch (action.type) {
    case CONTRACT_CHECK_NEW_TRANSFER_REQUEST:
      return {
        ...state,
        status: DATA_REQUEST,
      };

    case CONTRACT_CHECK_NEW_TRANSFER_SUCCESS:
      return {
        ...state,
        status: DATA_SUCCESS,
      };

    case CONTRACT_CHECK_NEW_TRANSFER_FAILURE:
      return {
        ...state,
        status: DATA_FAILURE,
        error: action.payload,
      };

    default:
      return state;
  }
};
