import React, { PureComponent } from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { connect, ConnectedProps } from "react-redux";
import locs from "../../../localization";
import throttle from "lodash/throttle";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import Container from "../../../containers/Container";
import Button from "../../../components/Button";
import Link from "../../../components/Link";

import styles from "../styles.scss";

import { createPdf } from "../../../actions/contract";

import { compose } from "redux";
import { DATA_REQUEST, DATA_SUCCESS } from "../../../constants/async";
import Loader from "../../../components/Loader";
import Checkbox from "../../../components/Checkbox";
import formValidation from "../../../validations/formValidation";
import isChecked from "../../../validations/isChecked";
import Column from "../../../containers/Column";
import Title from "../../../components/Title";
import { isGas } from "../../../selectors/contract";
import { GlobalState } from "../../../reducers/types";
import SignatureArea from "../../../composites/SignatureArea";
import InlineLoader from "../../../components/Loader/InlineLoader";
import TooltipContainer from "../../../containers/TooltipContainer";

const formKey = "calculator";

// Decorate with connect to read form values

function mapStateToProps(state: GlobalState) {
  const { pdfStatus } = state.pdf;

  const isLoaded = pdfStatus === DATA_SUCCESS;

  return {
    isLoadable: pdfStatus === DATA_SUCCESS || pdfStatus === null,
    isLoading: pdfStatus === DATA_REQUEST,
    isLoaded,
    isGas: isGas(state),
    pdfAvailable: pdfStatus === DATA_SUCCESS,
  };
}

const mapDispatchToProps = {
  createPdf,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface PdfProps {
  previousPage: () => void;
}

interface PdfState {
  width?: number;
  numPages?: number;
  pageNumber: number;
}

export class Pdf extends PureComponent<PdfProps & PropsFromRedux & InjectedFormProps, PdfState> {
  state = {
    numPages: null,
    pageNumber: 1,
    width: null,
  };

  private containerDivRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    this.props.createPdf();

    // Set div size
    this.setDivSize();
    window.addEventListener("resize", this.setDivSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setDivSize);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isLoaded === false && nextProps.isLoaded) {
      setTimeout(this.setDivSize, 300);
    }
  }

  setDivSize = throttle(() => {
    this.setState({
      width: this.containerDivRef.current?.getBoundingClientRect().width,
    });
  }, 500);

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  render() {
    const { handleSubmit, previousPage, isLoaded, submitting } = this.props;

    const { numPages, width } = this.state;

    let tooltip = <span className={styles.signButtonTooltip}>{locs("texts.signature_confirmation_tooltip")}</span>;

    return (
      <Loader loaded={isLoaded} title={locs("actions.create_contract")} subTitle={locs("actions.please_be_patient")}>
        <Container>
          <Column>
            <Title name={locs("titles.checkPdfContract")} underline className={styles.title} />
          </Column>

          <div ref={this.containerDivRef}>
            {width && (
              <Document file="/api/v1/contract/generated_pdf" onLoadSuccess={this.onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} width={this.state.width} />
                ))}
              </Document>
            )}
          </div>

          <div style={{ marginTop: "2rem" }}>
            <Field
              name="confirmContract"
              component={Checkbox}
              type="checkbox"
              label={locs(`texts.confirmContract`)}
              props={{
                dangerHTML: true,
              }}
              validate={[formValidation(isChecked)]}
            />
            <Field
              name="confirmValidData"
              component={Checkbox}
              type="checkbox"
              label={locs("texts.confirmValidData")}
              props={{
                dangerHTML: true,
              }}
              validate={[formValidation(isChecked)]}
            />
          </div>

          <Field name="signature" component={SignatureArea} />

          <div className={`${styles.footer} ${styles.pdfFooter}`}>
            <Link label={locs("actions.goBackToForm")} onClick={previousPage} />
            <div className={styles.signatureFlexbox}>
              <span dangerouslySetInnerHTML={{ __html: locs("labels.agree_with_a_contract") }} />
              <TooltipContainer overlay={tooltip} placement="top">
                <Button disabled={submitting} onClick={handleSubmit}>
                  {submitting ? <InlineLoader height={12} /> : locs("actions.confirm_signature")}
                </Button>
              </TooltipContainer>
            </div>
          </div>
        </Container>
      </Loader>
    );
  }
}

export default compose(
  reduxForm({
    form: formKey, // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  }),
  connector
)(Pdf);
