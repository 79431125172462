import SignatureCanvas from "react-signature-canvas";

/**
 * Returns a validation function that checks if the trimmed canvas area is large enough
 * Currently the area is 50x50 pixels or equivalent.
 *
 * @param canvasRef A reference to the SignatureCanvas component to validate.
 * @returns A validation function that returns true if the canvas area is valid.
 */
export default function isValidSizeValidation(canvasRef: SignatureCanvas) {
  function isValidSize(_value: unknown) {
    if (!canvasRef) {
      return true;
    }

    const trimmed = canvasRef.getTrimmedCanvas();
    const area = trimmed.width * trimmed.height;
    return area >= 2500; // 50x50 pixels or same area
  }

  return isValidSize;
}
