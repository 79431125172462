import React from "react";
import classNames from "classnames";
import Filename from "react-fine-uploader/filename";
import Filesize from "react-fine-uploader/filesize";
import ProgressBar from "react-fine-uploader/progress-bar";
import RetryButton from "react-fine-uploader/retry-button";
import styles from "./styles.scss";
import locs from "../../localization";
import { formatSizeAndUnits } from "../../helpers";

export interface FileDetailTheme {
  fileContainer?: string;
  fileContainerDetails?: string;
  fileLabel?: string;
  error?: string;
  deleteButton?: string;
  fileDeleteIcon?: string;
  progressBarContainer?: string;
}

export interface FileDetailProps {
  id: number;
  uploader: any;
  removeFile: (id: number) => void;
  fileUploadData?: {
    uploaded: number;
    total: number;
    error?: string;
  };
  theme?: FileDetailTheme;
}

export default function FileDetail(props: FileDetailProps) {
  const { uploader, id, fileUploadData: { uploaded, total, error } = {}, theme = {} } = props;

  let fileSize;
  if (uploaded === total) {
    fileSize = <Filesize id={id} uploader={uploader} />;
  } else {
    fileSize = (
      <span>
        {formatSizeAndUnits(uploaded)} z {formatSizeAndUnits(total)}
      </span>
    );
  }

  const removeFile = e => {
    e.preventDefault();
    props.removeFile(id);
  };

  return (
    <div className={classNames(styles.fileContainer, theme.fileContainer)}>
      <div className={classNames(styles.fileContainerDetails, theme.fileContainerDetails)}>
        <div className={classNames(styles.fileLabel, theme.fileLabel)}>
          <Filename id={id} uploader={uploader} />
          <span> (</span>
          {fileSize}
          <span>)</span>
          {error && <div className={classNames(styles.error, theme.error)}>{error}</div>}
        </div>
        <button
          className={classNames("react-fine-uploader-delete-button", styles.deleteButton, theme.deleteButton)}
          onClick={removeFile}>
          <div className={classNames(styles.fileDeleteIcon, theme.fileDeleteIcon)} />
        </button>
        <RetryButton id={id} uploader={uploader}>
          {locs("actions.try_again")}
        </RetryButton>
      </div>
      <div className={classNames(styles.progressBarContainer, theme.progressBarContainer)}>
        <ProgressBar id={id} uploader={uploader} />
      </div>
    </div>
  );
}
