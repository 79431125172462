import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { getLogin, getName, isSigned } from "../../selectors/user";
import { logout } from "../../actions/user";
import locs from "../../localization/index";

import Link from "../../components/Link/index";
import Icon, { USER } from "../../components/UI/Icon";

import styles from "./styles.scss";

const mapStateToProps = state => ({
  signed: isSigned(state),
  login: getLogin(state),
  name: getName(state),
});

const mapDispatchToProps = {
  onLogOut: logout,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

function User({ signed, login, name, onLogOut }: PropsFromRedux) {
  if (!signed) return null;

  return (
    <div>
      <hr className={styles.separatorBefore} />
      <div className={styles.user}>
        <div className={styles.userIcon}>
          <Icon icon={USER} />
        </div>
        <span>
          <b>{name}</b>
          {login && <span>{` (${login})`}</span>}
        </span>
        <div className={styles.logout}>
          <Link label={locs("actions.logout")} onClick={onLogOut} />
        </div>
      </div>
    </div>
  );
}

export default connector(User);
