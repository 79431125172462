const fileUploadOptions = (endPoints, customHeaders) => ({
  options: {
    autoUpload: true,
    chunking: {
      enabled: false,
      mandatory: false,
    },
    deleteFile: {
      enabled: true,
      endpoint: endPoints.request,
      customHeaders,
    },
    request: {
      endpoint: endPoints.request,
      customHeaders,
    },
    retry: {
      enableAuto: false,
    },
    resume: {
      enabled: true,
    },
  },
});

export default fileUploadOptions;
