import React from "react";
import styles from "./styles.scss";

import TooltipContainer from "../../containers/TooltipContainer";

export default function Help({ children }: { children: any }) {
  return (
    <TooltipContainer overlay={children} placement="right" align={{ offset: [20, 0] }}>
      <div className={styles.icon} />
    </TooltipContainer>
  );
}
