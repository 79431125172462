import React, { Component } from "react";
import compose from "recompose/compose";
import { connect, ConnectedProps } from "react-redux";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import merge from "lodash/merge";
import { checkNewTransfer } from "../../actions/contract";

import CalculatorPage1 from "./CalculatorPage";
import ProductSelectPage2 from "./ProductSelectPage";
import ContactPage3 from "./ContactPage";
import PaymentsPage4 from "./PaymentsPage";
import SupplyPointPage5 from "./SupplyPointPage";
import RecapitulationPage6 from "./RecapitulationPage";
import FinalPage7 from "./FinalPage";

/** Transfer basics */
import TransferSummary from "./Transfer/Summary";
import TransferRecapitulation from "./Transfer/Recapitulation";
import TransferPdf from "./Transfer/Pdf";

/** Init transfer */
import InitTransfer from "./NewTransfer/Init";
import TransferDocuments from "./NewTransfer/Documents";

import styles from "./styles.scss";

import { isSigned } from "../../selectors/user";
import { getContractValues, getLifecycle } from "../../selectors/contract";
import { visitRoute } from "../../actions/routing";
import { getVisitedRoutes } from "../../selectors/routing";
import ProcessSelectPage from "./ProcessSelectPage";
import PreviousCustomer from "./OwnerChange/PreviousCustomer";
import { Pages } from "../../lifecycles";
import Footer from "../../components/Footer";

interface DesktopFormProps {
  initialValues?: any;
  onDataChange: (data: any) => void;
  onSubmit: (values: any) => void;
  fetchProducts: () => void;
  onGetData: (waitForInit?: boolean) => void;
  createForm: (values: any) => void;
}

const mapStateToProps = state => ({
  signed: isSigned(state),
  values: getContractValues(state),
  visited: getVisitedRoutes(state),
  lifecycle: getLifecycle(state),
});

const mapDispatchToProps = {
  visitRoute,
  checkNewTransfer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

class DesktopForm extends Component<DesktopFormProps & PropsFromRedux & RouteComponentProps> {
  static defaultProps = {
    initialValues: null,
    values: {},
    onDataChange() {},
    onSubmit() {},
    createForm() {},
  };

  redirectTo = path => () => {
    if (!path) return;
    this.props.history.push(path);
    window.scrollTo(0, 0);
  };

  toTheSpecificPage = path => {
    this.redirectTo(path)();
  };

  persistData = (data = null) => this.props.onDataChange(this.mergeFormData(data));
  createContract = (data = null) => this.props.createForm(this.mergeFormData(data));

  // inject default data to the form
  // or append extra data (is used in "call me back" form)
  mergeFormData = (data = null) => merge(this.props.values, data);

  handleSubmit = () => this.props.onSubmit(this.props.values);

  pageRenderer = (page, previousPage, nextPage) => () => {
    const { visited } = this.props;
    const { component: Page, ...props } = page;

    // Prevent "skipping" to not yet visited page in the middle of the wizard
    if (previousPage && !visited[previousPage.path]) {
      // Actually, allow skipping in development
      if (process.env.NODE_ENV !== "development") {
        return <Redirect to={previousPage.path} />;
      }
    }

    // Marks the page as visited for "skip checking" above
    // FIXME: React doesn't like this update (dispatch) in render.
    this.props.visitRoute(page.path);

    return (
      <Page
        {...props}
        previousPage={this.redirectTo(previousPage && previousPage.path)}
        onSubmit={() => Promise.resolve(props.onSubmit && props.onSubmit()).then(this.redirectTo(nextPage && nextPage.path))}
      />
    );
  };

  renderRoute = (page, pageNo, pages) => {
    const previousPage = pages[pageNo - 1];
    const nextPage = pages[pageNo + 1];

    return <Route key={page.path} exact path={page.path} render={this.pageRenderer(page, previousPage, nextPage)} />;
  };

  render() {
    const { initialValues, signed, lifecycle } = this.props;

    const pageProps = {
      initialValues,
      toTheSpecificPage: this.toTheSpecificPage,
      signed,
    };

    const pages = lifecycle.desktopPages.map((page, index) => {
      switch (page) {
        case Pages.TransferSummary:
          return {
            // There are multiple root pages, but this one is re-used again in TransferNew process, that means it must not be as default
            path: index == 0 ? "/" : "/summary",
            component: TransferSummary,
            ...pageProps,
            onSubmit: () => Promise.resolve(null).then(this.persistData).then(this.props.fetchProducts),
          };

        case Pages.TransferInit:
          return {
            path: "/",
            component: InitTransfer,
            ...pageProps,
            // Persist form & check transfer & get new data to transform values (don't wait for user data, next page does not need them)
            onSubmit: () =>
              Promise.resolve(null)
                .then(this.createContract)
                .then(this.persistData)
                .then(this.props.checkNewTransfer)
                .then(() => this.props.onGetData(false)),
          };

        case Pages.TransferDocuments:
          return {
            path: "/documents",
            component: TransferDocuments,
            ...pageProps,
            onSubmit: () => Promise.resolve(null).then(this.persistData),
          };

        case Pages.TransferSummary:
          return {
            path: "/transfer",
            component: TransferSummary,
            ...pageProps,
            onSubmit: () => Promise.resolve(null).then(this.persistData).then(this.props.fetchProducts),
          };

        case Pages.Calculator:
          return {
            path: "/",
            component: CalculatorPage1,
            ...pageProps,
            onSubmit: () => Promise.resolve(null).then(this.createContract).then(this.persistData).then(this.props.fetchProducts),
          };

        case Pages.Product:
          return {
            path: "/product",
            component: ProductSelectPage2,
            ...pageProps,
            persistData: this.persistData,
            getData: this.props.onGetData,
            onSubmit: () =>
              Promise.resolve(null)
                .then(this.persistData)
                .then(() => signed && this.props.onGetData()),
          };

        // Select process family type
        case Pages.ProcessSelect:
          return {
            path: "/process",
            component: ProcessSelectPage,
            ...pageProps,
            onSubmit: () => this.persistData(),
          };

        case Pages.PreviousCustomer:
          return {
            path: "/previous_customer",
            component: PreviousCustomer,
            ...pageProps,
            onSubmit: () => this.persistData(),
          };

        case Pages.Contact:
          return {
            path: "/contact",
            component: ContactPage3,
            ...pageProps,
            onSubmit: () => this.persistData(),
          };

        case Pages.Payment:
          return {
            path: "/payments",
            component: PaymentsPage4,
            ...pageProps,
            onSubmit: () => this.persistData(),
          };

        case Pages.SupplyPoint:
          return {
            path: "/supply_point",
            component: SupplyPointPage5,
            ...pageProps,
            onSubmit: () => this.persistData(),
          };

        case Pages.Recapitulation:
          return {
            path: "/recapitulation",
            component: RecapitulationPage6,
            ...pageProps,
            onSubmit: () => Promise.resolve(null).then(this.persistData),
          };

        case Pages.TransferRecapitulation:
          return {
            path: "/recapitulation",
            component: TransferRecapitulation,
            ...pageProps,
            onSubmit: () => Promise.resolve(null).then(this.persistData),
          };

        case Pages.Pdf:
          return {
            path: "/pdf",
            component: TransferPdf,
            ...pageProps,
            onSubmit: () => Promise.resolve(null).then(this.persistData).then(this.handleSubmit),
          };

        case Pages.Final:
          return {
            path: "/final",
            component: FinalPage7,
            ...pageProps,
          };
      }
    });

    return (
      <div className={styles.container}>
        <Switch>
          {pages.map(this.renderRoute)}
          <Redirect from="*" to={pages[0].path} />
        </Switch>
        <Footer />
      </div>
    );
  }
}

/**
 * withRouter will pass updated match, location, and history props
 * to the wrapped component whenever it renders.
 *
 * @see: https://reacttraining.com/react-router/web/api/withRouter
 */
export default compose(withRouter, connector)(DesktopForm) as React.ComponentClass<DesktopFormProps>;
