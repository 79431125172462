import React from "react";
import { Field, formValueSelector, InjectedFormProps, reduxForm, WrappedFieldProps } from "redux-form";
import { connect, ConnectedProps } from "react-redux";
import locs from "../../../localization";

import Container from "../../../containers/Container";
import Column from "../../../containers/Column";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Date from "../../../components/Date";
import styles from "../styles.scss";
import codeInputTheme from "../../../forms/SupplyPoint/theme.scss";

import formValidation from "../../../validations/formValidation";
import eicFormatter from "../../../formatters/eic";
import dateFormatter from "../../../formatters/date";
import eanFormatter from "../../../formatters/ean";

import { isElectricity, getTransfer } from "../../../selectors/contract";

import { compose } from "redux";
import CommodityType from "../../Calculator/CommodityType";
import paymentsTheme from "../../Payments/theme.scss";
import { EAN_PREFIX, EIC_PREFIX, TYPE_TRANSFER_NEW_DEATH, TYPE_TRANSFER_NEW } from "../../../constants";
import Group from "../../../components/Group";
import isEIC from "../../../validations/isEIC";
import isEAN from "../../../validations/isEAN";
import isNotEmpty from "../../../validations/isNotEmpty";
import isAdult from "../../../validations/isAdult";
import isDate from "../../../validations/isDate";
import isICO from "../../../validations/isICO";
import getValue from "lodash/get";
import { checkNewTransfer } from "../../../actions/contract";
import { DATA_FAILURE, DATA_REQUEST } from "../../../constants/async";
import InlineLoader from "../../../components/Loader/InlineLoader";
import { CalculatorValues, GlobalState } from "../../../reducers/types";

const formKey = "calculator";
const selector = formValueSelector(formKey);

function mapStateToProps(state: GlobalState) {
  const { status, error } = state.newTransfer;

  return {
    electricity: isElectricity(state),
    transfer: getTransfer(state),

    /** Ico from form */
    ico: selector(state, "transfer.in"),

    /** Check new transfer loading */
    isLoading: status === DATA_REQUEST,
    isError: status === DATA_FAILURE,
    error,
  };
}

function mapDispatchToProps() {
  return {
    checkNewTransfer,
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

function TransferInitForm(props: PropsFromRedux & InjectedFormProps) {
  const { electricity, handleSubmit, submitting, isError, error, ico } = props;

  const eicProps = {
    label: locs("labels.eicCode"),
    placeholder: locs("placeholders.eic"),
    prefix: EIC_PREFIX,
    validate: [formValidation(isNotEmpty), formValidation(isEIC)],
    normalize: eic => (eic && eic.indexOf(EIC_PREFIX) === -1 ? `${EIC_PREFIX}${eic}` : eic).toUpperCase(),
    format: eicFormatter,
    theme: codeInputTheme,
  };

  const eanProps = {
    label: locs("labels.eanCode"),
    placeholder: locs("placeholders.ean"),
    prefix: EAN_PREFIX,
    validate: [formValidation(isNotEmpty), formValidation(isEAN)],
    normalize: ean => (ean && ean.indexOf(EAN_PREFIX) === -1 ? `${EAN_PREFIX}${ean}` : ean).toUpperCase(),
    format: eanFormatter,
    theme: codeInputTheme,
  };

  const icoBirthDateValidation = (value, allValues) => {
    const ico = getValue(allValues, "transfer.in");
    const birthDate = getValue(allValues, "transfer.birthDate");

    if (ico || birthDate) {
      return null;
    }

    return locs("errors.isIcoOrBirthDate");
  };

  // If IC is selected, don't offer "death-options"
  const transferTypes = [{ label: locs("labels.transferChange"), value: TYPE_TRANSFER_NEW }];

  if (!ico || ico.length === 0) {
    transferTypes.push({ label: locs("labels.transferDeath"), value: TYPE_TRANSFER_NEW_DEATH });
  }

  return (
    <Container>
      <div>
        <Column>
          <Title name={locs("titles.transfer")} tag="h1" big={true} />
        </Column>
        <Column>
          <Title name={locs("titles.fillOpmCode")} underline />
        </Column>
        <Title name={locs("titles.supplyPointData")} tag="h3" />
        <Column weights={["1_11", 1]} columns={2}>
          <CommodityType label={locs("labels.commodity")} theme={paymentsTheme} />
        </Column>
        <Column weights={["1_11", 1]} columns={2}>
          <Field name="supplyPoint.data.code" type="text" component={Input} {...(electricity ? eanProps : eicProps)} />
        </Column>
        <Title name={locs("titles.transferCheckPrevious")} tag="h3" />
        <Column weights={["1_11", 1]} columns={2}>
          <Field
            name="transfer.birthDate"
            type="text"
            component={Date}
            normalize={dateFormatter}
            validate={[formValidation(isDate), formValidation(isAdult), icoBirthDateValidation]}
            props={{
              label: locs("labels.birthDate"),
              theme: codeInputTheme,
              viewMode: "years",
            }}
          />
        </Column>
        <Column weights={["1_11", 1]} columns={2}>
          <Field
            name="transfer.in"
            type="text"
            component={Input}
            validate={[formValidation(isICO), icoBirthDateValidation]}
            props={{
              label: locs("labels.companyIn"),
              theme: codeInputTheme,
            }}
          />
        </Column>
        {isError && <div className={styles.transferNewError}>{error.response.data.message}</div>}
        <Title name={locs("titles.transferReason")} tag="h3" />
        <Group name="process" data={transferTypes} theme={paymentsTheme} />
        <div className={styles.singleButtonFooter}>
          <Button onClick={handleSubmit} disabled={submitting}>
            {submitting ? <InlineLoader height={12} /> : locs("actions.nextTransfer")}
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default compose(
  reduxForm({
    form: formKey, // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    onChange: (values: CalculatorValues, dispatch, props) => {
      const { process, transfer } = values;
      const { change: changeField } = props;

      if (transfer.in && transfer.in.length > 0 && process === TYPE_TRANSFER_NEW_DEATH) {
        dispatch(changeField("process", TYPE_TRANSFER_NEW));
      }
    },
  }),
  connector
)(TransferInitForm) as React.ComponentClass<{}, {}>;
