import React from "react";
import { Field as OriginalField, InjectedFormProps } from "redux-form";
import ElectricityConsumption from "./Electricity/Consumption";
import GasConsumption from "./Gas/Consumption";
import { isGas } from "../../../helpers";

import { WrappedFieldProps } from "redux-form";
import { Commodity, Estimate } from "../../../constants/interfaces";
import { Calculation, CalculatorValues } from "../../../reducers/types";

interface UiAppliances {
  lighting?: boolean;
  cooking?: boolean;
  waterHeating?: boolean;
  heating?: boolean;
  overheating?: boolean;
  heatPump?: boolean;
  electricCar?: boolean;
}

const similarityComparison: Array<[string, string, number]> = [
  // [applianceKey (local), estimateKey (API), weight]
  ["lighting", "lighting", 1],
  ["cooking", "cooking", 2],
  ["waterHeating", "waterHeating", 2],
  ["heating", "heating", 3],
  ["overheating", "overheating", 3],
  ["heatPump", "heatPump", 3],
  ["electricCar", "electricCar", 3],
];

/**
 * This estimates how similar two sets are
 * For identical data returns 0
 *
 * @param {Object} appliances
 * @param {Object} estimate
 * @return {number}
 */
function getSimilarity(appliances: UiAppliances, estimate: Estimate) {
  let similarity = 0;

  similarityComparison.forEach(([applianceKey, estimateKey, weight]) => {
    if ((appliances[applianceKey] || false) !== (estimate[estimateKey] || false)) {
      similarity += weight;
    }
  });

  return similarity;
}

function findEstimate(appliances: UiAppliances, estimates: Estimate[]) {
  const sorted = estimates
    .map(estimate => ({
      estimate,
      similarity: getSimilarity(appliances, estimate),
      consumption: Number(estimate.consumption),
    }))
    .sort((a, b) => {
      // Higher consumption wins (if similarities are equal)
      if (a.similarity === b.similarity) return b.consumption - a.consumption;
      // Lower similarity wins
      return a.similarity - b.similarity;
    })
    .map(({ estimate }) => estimate);

  return sorted[0] || null;
}

export function calculateFormChange(
  values: CalculatorValues,
  estimates: Estimate[],
  commodityValue: string,
  dispatch,
  props: InjectedFormProps,
  previousValues: CalculatorValues
) {
  if (values.calculate && previousValues.calculate) {
    const nextValue = values.calculate[commodityValue];
    const previousValue = previousValues.calculate[commodityValue];

    computeEstimate(estimates, nextValue, previousValue, {
      ...props,
      dispatch,
      name: `calculate.${commodityValue}`,
    });

    // Only one heating is allowed for gas
    if (nextValue.appliances?.overheating && nextValue.appliances?.heating) {
      // Check what was "clicked" last
      if (previousValue.appliances?.overheating) {
        dispatch(props.change(`calculate.${commodityValue}.appliances.heating`, true));
        dispatch(props.change(`calculate.${commodityValue}.appliances.overheating`, false));
      } else {
        dispatch(props.change(`calculate.${commodityValue}.appliances.heating`, false));
        dispatch(props.change(`calculate.${commodityValue}.appliances.overheating`, true));
      }
    }
  }
}

interface CalculateProps {
  commodity: Commodity;
  theme?: any;
  doNotGroup?: boolean;
  hideLabel?: boolean;
  estimates: Estimate[];
}

interface ParentComputeEstimateProps {
  name: string;
  dispatch: (any) => void;
  change: (key: string, value: any) => void;
  untouch: (key: string) => void;
}

/**
 * Compute estimateID
 *
 * @param estimates
 * @param calculation
 * @param previousCalculation
 * @param dispatch
 * @param change
 */
export function computeEstimate(
  estimates: Estimate[],
  nextValue: Calculation<any>,
  value: Calculation<any>,
  props: ParentComputeEstimateProps
) {
  const { dispatch, change, name, untouch } = props;

  if (!nextValue.knownConsumption && nextValue.appliances !== value.appliances) {
    const estimate = findEstimate(nextValue.appliances, estimates) || { id: null };
    if (value.estimateId !== estimate.id) {
      dispatch(change(`${name}.estimateId`, estimate.id));
    }
  }

  if (nextValue.knownConsumption !== value.knownConsumption) {
    if (nextValue.knownConsumption) {
      dispatch(untouch(`${name}.detail`));
    } else {
      dispatch(untouch(`${name}.appliances`));
    }
  }
}

export default function Calculate(props: CalculateProps & WrappedFieldProps) {
  const { input: { value: { knownConsumption } = {}, name } = {}, commodity } = props;

  const renderConsumption = () => {
    const { doNotGroup, hideLabel, theme } = props;
    const Consumption = isGas(commodity) ? GasConsumption : ElectricityConsumption;

    return (
      <OriginalField
        name={`${name}.detail`}
        component={Consumption}
        props={{
          theme,
          doNotGroup,
          hideLabel,
        }}
      />
    );
  };

  return <div>{renderConsumption()}</div>;
}
