import React, { PureComponent } from "react";
import Autosuggest from "react-autosuggest";
import classNames from "classnames";
import layout from "../UI/styles.scss";
import styles from "./styles.scss";
import inputStyles from "../Input/styles.scss";
import { showErrorMessage } from "../../helpers";
import { WrappedFieldProps } from "redux-form";

export interface AutosuggestInputProps<K> {
  disabled?: boolean;
  suggestionThreshold?: number;
  suggestionDelay?: number;
  getSuggestionValue?: (item: K) => string;
  renderSuggestion?: (item: K) => React.ReactNode;
  suggestionsResolve: (value: string) => Promise<K[]>;
  onSuggestionSelected: (event, data) => {};
  alwaysRenderSuggestions?: boolean;
  theme?: any;
  label?: string;
  placeholder?: string;
  type?: string;
  onChange?: (event, data) => void;
}

export interface AutosuggestInputState<K> {
  suggestions: K[];
}

export default class AutosuggestInput<K> extends PureComponent<
  WrappedFieldProps & AutosuggestInputProps<K> & typeof AutosuggestInput.defaultProps,
  AutosuggestInputState<K>
> {
  // We can't cancel request easily, but we could assign values to ignore older ones
  private requestsCount: number = 0;
  private requestedId?: any;

  state = {
    suggestions: [],
  };

  static defaultProps = {
    disabled: false,
    suggestionDelay: 100,
    suggestionThreshold: 2,
    getSuggestionValue: suggestion => suggestion,
    renderSuggestion: suggestion => <span>{suggestion}</span>,
    alwaysRenderSuggestions: false,
    onSuggestionSelected: (event, data) => {},
    type: "text",
    theme: {},
  };

  handleChange = (event, data) => {
    // custom onChange handler for hooking some additional logic
    this.props.onChange && this.props.onChange(event, data);
    if (data.method !== "escape") {
      // redux form onChange
      this.props.input.onChange(data.newValue);
    }
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    if (this.requestedId) {
      clearTimeout(this.requestedId);
    }

    if (value.length < this.props.suggestionThreshold) {
      this.setState({ suggestions: [] });
    } else {
      // Save value into local closure
      this.requestsCount++;
      const currentRequestCount = this.requestsCount;

      // This is throttling
      this.requestedId = setTimeout(() => {
        this.requestedId = null;
        Promise.resolve(this.props.suggestionsResolve(value)).then(suggestions => {
          // Nohting else was requested since this
          if (this.requestsCount == currentRequestCount) {
            this.setState({ suggestions });
          }
        });
      }, this.props.suggestionDelay);
    }
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleSuggestionSelected = (event, data) => {
    if (data.method === "enter") event.preventDefault();
    this.props.onSuggestionSelected(event, data);
  };

  handleNumberKeypress = e => {
    if (e.which < 48 || e.which > 57) {
      e.preventDefault();
      return false;
    }
    return true;
  };

  render() {
    const {
      input,
      label,
      placeholder,
      disabled,
      type,
      meta,
      getSuggestionValue,
      renderSuggestion,
      alwaysRenderSuggestions,
      theme,
    } = this.props;
    const { suggestions } = this.state;
    const isInvalid = () => meta.invalid && meta.touched;

    const inputClassNames = classNames(theme.input, inputStyles.input, { [inputStyles.inputInvalid]: isInvalid() });

    const inputProps = {
      ...input,
      type,
      placeholder,
      disabled,
      className: inputClassNames,
      onChange: this.handleChange,
      onKeyPress: type === "number" ? this.handleNumberKeypress : null,
      autoComplete: "none",
    };

    const inputContainerClassNames = classNames(theme.inputContainer, inputStyles.inputContainer, styles.inputAutosuggest);

    const baseContainerClassNames = classNames(
      layout.form_control,
      theme.form_control,
      styles.baseContainer,
      theme.baseContainer
    );
    const labelClassNames = classNames(theme.label, inputStyles.label);
    const error = isInvalid() && showErrorMessage(meta.error) && <div className={inputStyles.errorMessage}>{meta.error}</div>;

    return (
      <div className={baseContainerClassNames}>
        <label>
          <div className={labelClassNames}>{label}</div>
          <div className={inputContainerClassNames}>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
              onSuggestionSelected={this.handleSuggestionSelected}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              alwaysRenderSuggestions={alwaysRenderSuggestions}
              inputProps={inputProps}
            />
            {error}
          </div>
        </label>
      </div>
    );
  }
}
