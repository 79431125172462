import merge from "lodash/merge";
import cs from "./dictionaries/cs";

const locs = dictionary => {
  return (key, values: (string | number)[] | string | number = []) => {
    // @ts-ignore
    let args: (string | number)[] = values.push ? values : [values]; // check if the argument is passed as an array
    let value;
    const path = key.split(".");
    path.forEach(p => {
      value = (value || dictionary)[p];
    });
    value = value || key;
    /*
    Some terms might shape differently depending on the value passed.
    Following block should solve it if we stick to the prescription.

    Example:

    'chars_remaining': {
      value: '%form1 %1 %form2',
      rules: {
        form1: {
          defaultValue: 'Zbývá',
          forms: [
            [[2, 3, 4], 'Zbývají'],
          ],
        },
        form2: {
          defaultValue: 'znaků',
          forms: [
            [[1], 'znak'],
            [[2, 3, 4], 'znaky'],
          ],
        },
      },
    },
    */
    if (typeof value === "object") {
      const subject = args[0];
      const rules = value.rules;
      let replacement;
      let replacedValue = value.value;
      const formKeys = Object.keys(rules);
      formKeys.forEach((k, i) => {
        replacement = null;
        for (let f = 0; f < rules[k].forms.length; f++) {
          if (rules[k].forms[f][0].indexOf(subject) !== -1) {
            replacement = rules[k].forms[f][1];
            break;
          }
        }
        if (!replacement) {
          replacement = rules[k].defaultValue;
        }
        replacedValue = replacedValue.replace(`%${k}`, replacement);
      });
      value = replacedValue;
    }
    args.forEach((a, i) => {
      value = value.replace(`%${i + 1}`, a);
    });
    return value;
  };
};

export const addDictionary = dictionary => {
  merge(cs, dictionary);
  return locs(cs);
};

export default locs(cs);
