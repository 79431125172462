import mapKeys from "lodash/mapKeys";
import mapValues from "lodash/mapValues";
import isObject from "lodash/isPlainObject";
import isArray from "lodash/isArray";

export default function mapKeysDeep(obj, cb) {
  return mapValues(mapKeys(obj, cb), val => {
    if (isArray(val)) {
      return isObject(val[0]) ? val.map(innerObj => mapKeysDeep(innerObj, cb)) : val;
    } else if (isObject(val)) {
      return mapKeysDeep(val, cb);
    }
    return val;
  });
}
