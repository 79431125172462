import React from "react";
import classNames from "classnames";
import styles from "./styles.scss";

interface FixedTooltipProps extends React.HTMLProps<HTMLDivElement> {
  theme?: {
    tooltipContainer?: string;
    tooltip?: string;
  };
  position?: "bottom" | "top" | "none";
}

function FixedTooltip({ theme = {}, position = "bottom", children }: FixedTooltipProps) {
  const containerClassNames = classNames(styles.tooltipContainer, theme.tooltipContainer);
  const tooltipClassNames = classNames("rc-tooltip", `rc-tooltip-placement-${position}`, theme.tooltip);

  return (
    <div className={containerClassNames}>
      <div className={tooltipClassNames}>
        <div className="rc-tooltip-content">
          <div className="rc-tooltip-arrow" />
          <div className="rc-tooltip-inner">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default FixedTooltip;
