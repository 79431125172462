import React, { PureComponent } from "react";
import { Field, InjectedFormProps, reduxForm, WrappedFieldProps } from "redux-form";
import { connect, ConnectedProps } from "react-redux";
import locs from "../../../localization";

import Container from "../../../containers/Container";
import Column from "../../../containers/Column";
import Title from "../../../components/Title";
import Button from "../../../components/Button";

import ProgressBar from "../../../components/ProgressBar";
import styles from "../styles.scss";
import theme from "../../Contact/theme.scss";

import { compose } from "redux";
import Link from "../../../components/Link";
import { getLifecycle, getProcess, getRegionRuianCode, isElectricity } from "../../../selectors/contract";
import PreviousCustomer from "../../Contact/PreviousCustomer";
import { getEnumeration } from "../../../selectors/enumerations";
import { ENUMERATION_TITLE_PREFIX, ENUMERATION_TITLE_SUFFIX } from "../../../constants/enumerations";
import { TitlePrefix, TitleSuffix } from "../../../constants/interfaces";

import TransferDocuments from "../../../composites/TransferDocuments";
import PreviousConsumption from "../../../composites/PreviousConsumption";
import { TYPE_NEW_OM_OWNER_CHANGE_DEATH } from "../../../constants";
import { Pages } from "../../../lifecycles";
import InlineLoader from "../../../components/Loader/InlineLoader";

const formKey = "calculator";

const mapStateToProps = state => ({
  regionRuianCode: getRegionRuianCode(state),
  isElectricity: isElectricity(state),
  titlePrefixes: getEnumeration<TitlePrefix>(state, ENUMERATION_TITLE_PREFIX),
  titleSuffixes: getEnumeration<TitleSuffix>(state, ENUMERATION_TITLE_SUFFIX),
  process: getProcess(state),
  lifecycle: getLifecycle(state),
});

interface PreviousCustomerProps {
  previousPage: () => void;
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

function ProcessSelectPage(props: PreviousCustomerProps & PropsFromRedux & InjectedFormProps) {
  const {
    handleSubmit,
    previousPage,
    isElectricity,
    titlePrefixes,
    titleSuffixes,
    process,
    regionRuianCode,
    lifecycle,
    submitting,
  } = props;

  return (
    <Container>
      <div>
        <ProgressBar page={Pages.PreviousCustomer} />

        <Column>
          <Title name={locs("titles.currentCustomer")} tag="h1" />
        </Column>

        <Field
          name="transfer.previousState"
          component={PreviousConsumption}
          props={{
            isElectricity,
            theme,
            valueNt: true,
          }}
        />

        <Column weights={["1_11", 1]}>
          <Field
            name="transfer"
            component={PreviousCustomer}
            props={{
              titleSuffixes,
              titlePrefixes,
              death: process === TYPE_NEW_OM_OWNER_CHANGE_DEATH,
              disabled: false,
              valueNt: true,
            }}
          />
        </Column>

        <br />

        <Column>
          <Title name={locs("titles.requiredDocuments")} tag="h1" />
        </Column>

        <Field
          name="transfer.documents"
          component={TransferDocuments}
          props={{
            regionRuianCode,
            death: process === TYPE_NEW_OM_OWNER_CHANGE_DEATH,
            isElectricity,
            terminationRequest: false,
            lifecycle,
          }}
        />

        <div className={styles.footer}>
          <Link label={locs("actions.go_back")} onClick={previousPage} />
          <Button disabled={submitting} onClick={handleSubmit}>
            {submitting ? <InlineLoader height={12} /> : locs("actions.next")}
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default compose(
  reduxForm({
    form: formKey, // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  }),
  connector
)(ProcessSelectPage);
