import React, { useEffect } from "react";
import classNames from "classnames";
import getValue from "lodash/get";
import Input from "../../components/Input";
import Title from "../../components/Title";
import Column from "../../containers/Column";
import addressFormatter, { Address as AddressType } from "../../formatters/address";

import styles from "./styles.scss";
import theme from "./theme.scss";

import locs from "../../localization";
import formValidation from "../../validations/formValidation";
import zipFormatter from "../../formatters/zip";
import isZip from "../../validations/isZip";
import isStreetNumber from "../../validations/isStreetNumber";
import isHouseNumber from "../../validations/isHouseNumber";
import isLength from "../../validations/isLength";

import SwitchButton from "./SwitchButton";
import { change, Field, WrappedFieldProps } from "redux-form";
import isNotEmpty from "../../validations/isNotEmpty";
import formatName from "../../helpers/formatName";
import Autosuggest from "../../components/Autosuggest";
import { fullAddressLoader } from "./ruian";
import { SuggestionProps } from "./Suggestion";
import { useDispatch } from "react-redux";
import { applyRuianData, clearAddress } from "../../actions/ruian";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../store";
import { AnyAction } from "redux";

export interface AddressProps {
  title?: string;
  disabled?: boolean;
  className?: string;
}

export default function Address(props: WrappedFieldProps & AddressProps) {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();

  const { input, title, className, disabled } = props;
  const fields = input.value || {};
  const name = input.name;

  const addressClassNames = classNames(className);

  const isAnyAddressNumberFilled = (_value: any, allValues: any) => {
    const parcel = getValue(allValues, formatName(name, "parcelNumber"));
    const registration = getValue(allValues, formatName(name, "registrationNumber"));
    const house_number = getValue(allValues, formatName(name, "houseNumber"));
    const street = getValue(allValues, formatName(name, "streetNumber"));

    if (registration || house_number || street || parcel) {
      return null;
    }
    return locs("errors.isNotEmpty");
  };

  const isRuianSelected = (_value, allValues) => {
    const ruianCode = getValue(allValues, formatName(name, "ruianCode"));
    if (ruianCode) {
      return null;
    }
    return locs("errors.selectAddressFromList");
  };

  const street = getValue(fields, "street");
  const houseNumber = getValue(fields, "houseNumber");
  const streetNumber = getValue(fields, "streetNumber");
  const city = getValue(fields, "city");
  const zip = getValue(fields, "zip");
  const addressToggled = getValue(fields, "addressToggle");

  useEffect(() => {
    if (addressToggled === true) {
      dispatch(change("calculator", `${name}.ruianCode`, ""));
      dispatch(change("calculator", `${name}.searchAddress`, ""));
    }
  }, [street, houseNumber, streetNumber, city, zip]);

  const handleSuggestionSelected = (_event: unknown, data: { suggestion: SuggestionProps }) => {
    dispatch(applyRuianData(data.suggestion, name));
  };

  const addressToggle = () => {
    return (
      <Field
        name={`${name}.addressToggle`}
        type="hidden"
        component={SwitchButton}
        labelOff={locs("labels.addressToggleOff")}
        labelOn={locs("labels.addressToggleOn")}
      />
    );
  };

  const searchAddress = () => {
    const format = (value: AddressType) => {
      return addressFormatter(value, {
        singleLine: true,
        includeRegistrationNumber: true,
      });
    };

    return (
      <Column columns={2} weights={["1_11", 1]}>
        <Field
          name={`${name}.searchAddress`}
          type="text"
          autoComplete="off"
          component={Autosuggest}
          props={{
            disabled,
            theme,
            onChange: () => {
              dispatch(clearAddress(name));
            },
          }}
          placeholder={locs("placeholders.searchAddress")}
          validate={!disabled && [isRuianSelected]}
          suggestionsResolve={fullAddressLoader()}
          suggestionDelay={500}
          suggestionThreshold={3}
          getSuggestionValue={(value: AddressType) => format(value)}
          renderSuggestion={(suggestion: AddressType) => <span>{format(suggestion)}</span>}
          onSuggestionSelected={handleSuggestionSelected}
        />
        {addressToggle()}
      </Column>
    );
  };

  const splitAddress = () => {
    return (
      <div className={styles.addressContent}>
        <Field
          name={`${name}.street`}
          type="text"
          autoComplete="street-address"
          component={Input}
          props={{
            disabled,
            theme,
          }}
          styte={{ gridArea: "street" }}
          label={locs("labels.street")}
          validate={!disabled && [formValidation(isNotEmpty), formValidation(isLength({ max: 60 }))]}
        />

        {addressToggle()}
        <Field
          name={`${name}.houseNumber`}
          type="text"
          component={Input}
          props={{
            disabled,
            theme: {
              ...theme,
              baseContainer: theme.baseContainerHouseNumber,
            },
          }}
          styte={{ gridArea: "houseNumber" }}
          label={locs("labels.house_number")}
          placeholder={locs("placeholders.house_number")}
          validate={!disabled && [isAnyAddressNumberFilled, formValidation(isHouseNumber), formValidation(isLength({ max: 10 }))]}
        />
        <Field
          name={`${name}.streetNumber`}
          type="text"
          component={Input}
          disabled={disabled}
          props={{
            disabled,
            theme: {
              inputContainer: theme.inputContainerShort,
              baseContainer: theme.baseContainerShort,
            },
          }}
          validate={
            !disabled && [isAnyAddressNumberFilled, formValidation(isStreetNumber), formValidation(isLength({ max: 10 }))]
          }
          styte={{ gridArea: "streetNumber" }}
          label={locs("labels.street_number")}
          placeholder={locs("placeholders.street_number")}
        />
        <Field
          name={`${name}.city`}
          type="text"
          component={Input}
          disabled={disabled}
          label={locs("labels.city")}
          styte={{ gridArea: "city" }}
          validate={!disabled && [formValidation(isNotEmpty), formValidation(isLength({ max: 40 }))]}
          theme={theme}
        />
        <Field
          name={`${name}.zip`}
          autoComplete="postal-code"
          type="text"
          component={Input}
          disabled={disabled}
          format={zipFormatter}
          validate={!disabled && [formValidation(isNotEmpty), formValidation(isZip), formValidation(isLength({ max: 40 }))]}
          label={locs("labels.zip")}
          styte={{ gridArea: "zip" }}
          theme={{
            inputContainer: theme.inputContainerShort,
            baseContainer: theme.baseContainerShort,
          }}
        />
      </div>
    );
  };

  return (
    <div className={addressClassNames ? addressClassNames : null}>
      <Title name={title} tag="h3" className={styles.title} />
      {fields.addressToggle ? splitAddress() : searchAddress()}
      <Field name={`${name}.ruianCode`} type="hidden" component={Input} />
      {/* This one is not showed anywhere in the form, but might be useful for address lookup later. */}
      <Field name={`${name}.registrationNumber`} type="hidden" component={Input} />
    </div>
  );
}
