import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import styles from "./styles/main.scss";

const container = document.getElementById("ppas");
container.className = styles.ppas;
const rootEl = document.body.appendChild(container);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootEl
);
