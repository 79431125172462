export const ENUMERATION_REQUEST = "ENUMERATION_REQUEST";
export const ENUMERATION_SUCCESS = "ENUMERATION_SUCCESS";
export const ENUMERATION_FAILURE = "ENUMERATION_FAILURE";

export const CONTRACT_SAVE_REQUEST = "CONTRACT_SAVE_REQUEST";
export const CONTRACT_SAVE_SUCCESS = "CONTRACT_SAVE_SUCCESS";
export const CONTRACT_SAVE_FAILURE = "CONTRACT_SAVE_FAILURE";

export const CONTRACT_RESTORE_REQUEST = "CONTRACT_RESTORE_REQUEST";
export const CONTRACT_RESTORE_SUCCESS = "CONTRACT_RESTORE_SUCCESS";
export const CONTRACT_RESTORE_FAILURE = "CONTRACT_RESTORE_FAILURE";

export const CONTRACT_CONFIRM_REQUEST = "CONTRACT_CONFIRM_REQUEST";
export const CONTRACT_CONFIRM_SUCCESS = "CONTRACT_CONFIRM_SUCCESS";
export const CONTRACT_CONFIRM_FAILURE = "CONTRACT_CONFIRM_FAILURE";

export const CONTRACT_PRODUCTS_REQUEST = "CONTRACT_PRODUCTS_REQUEST";
export const CONTRACT_PRODUCTS_SUCCESS = "CONTRACT_PRODUCTS_SUCCESS";
export const CONTRACT_PRODUCTS_FAILURE = "CONTRACT_PRODUCTS_FAILURE";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILURE = "USER_LOGOUT_FAILURE";

export const USER_GET_DATA_REQUEST = "USER_GET_DATA_REQUEST";
export const USER_GET_DATA_SUCCESS = "USER_GET_DATA_SUCCESS";
export const USER_GET_DATA_FAILURE = "USER_GET_DATA_FAILURE";

export const CONTRACT_CREATE_PDF_REQUEST = "CONTRACT_CREATE_PDF_REQUEST";
export const CONTRACT_CREATE_PDF_FAILURE = "CONTRACT_CREATE_PDF_FAILURE";
export const CONTRACT_CREATE_PDF_SUCCESS = "CONTRACT_CREATE_PDF_SUCCESS";

export const CONTRACT_CHECK_NEW_TRANSFER_REQUEST = "CONTRACT_CHECK_NEW_TRANSFER_REQUEST";
export const CONTRACT_CHECK_NEW_TRANSFER_FAILURE = "CONTRACT_CHECK_NEW_TRANSFER_FAILURE";
export const CONTRACT_CHECK_NEW_TRANSFER_SUCCESS = "CONTRACT_CHECK_NEW_TRANSFER_SUCCESS";
