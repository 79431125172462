import React, { useEffect } from "react";

// ! A "fake component" that injects a script tag into the DOM.
// This is a workaround for the fact that the survey widget is not a React component
// and we want to "render" it in a React app
const Survey = () => {
  // Inject the script tag into the DOM once
  useEffect(() => {
    const script = document.createElement("script");
    // Code is taken from the survey provider (via PPAS)
    script.innerHTML = `(function(a,b,c){var d=window,e=document,f=e.getElementById,g=e.createElement,h=e.getElementsByTagName,i='//surviocdn.com/s1/static/widgets/';if(function(m,n,o){return m._survEmbed=m._survEmbed||[],'function'==typeof m._survEmbed?m._survEmbed(n,o,a):m._survEmbed.push({p:n,c:o,i:a}),!1}(d,b,c),!f.call(e,'survio-share-sc')){var j=g.call(e,'script');j.id='survio-share-sc',j.type='text/javascript',j.src=i+'share.js';var k=h.call(e,'script')[0];k.parentNode.insertBefore(j,k)}if(!f.call(e,'survio-share-css')){var l=g.call(e,'link');l.rel='stylesheet',l.type='text/css',l.id='survio-share-css',l.href=i+'survio-2.css?t='+b,h.call(e,'head')[0].appendChild(l)}})('X6D3A1V8A8F1F9Y6J','popup',{"url":"https://www.survio.com/survey/p/R6A8P3U9K4Y7K0A1Y","timeout":5000});`;
    document.body.appendChild(script);

    // Clean up the script tag when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Nothing to render, the script tag does all the work
  return null;
};

export default Survey;
