import React from "react";
import { PARTNER_COMPANY, PARTNER_HOUSEHOLD } from "../../../constants";
import locs from "../../../localization";

import Group, { GroupProps } from "../../../components/Group";

interface PartnerTypeProps extends Omit<Omit<GroupProps, "name">, "data"> {
  name?: string;
  disabled?: boolean;
}

export default function PartnerType(props: PartnerTypeProps) {
  const { name = "partnerId", disabled = false, ...rest } = props;

  return (
    <Group
      name={name}
      data={[
        { label: locs("labels.household"), value: PARTNER_HOUSEHOLD, disabled },
        { label: locs("labels.company"), value: PARTNER_COMPANY, disabled },
      ]}
      normalize={Number}
      {...rest}
    />
  );
}
