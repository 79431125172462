/**
 * This function calculate consumption for SupplyPoint info in recap or product select page
 */

import { Estimate } from "../constants/interfaces";
import { SupplyPointConsumption } from "../reducers/types";
import locs from "../localization";

export interface ConsumptionData {
  consumption?: number;
  consumptionHigh?: number;
  consumptionLow?: number;
  prefix: string;
}

export const calculateConsumption = (
  estimate: Estimate,
  knownConsumption: boolean,
  spConsumption: SupplyPointConsumption,
  electricity: boolean
): ConsumptionData => {
  const { consumption, consumptionHigh, consumptionLow } = spConsumption;

  let consumptionValue = 0;
  let prefix = knownConsumption ? locs("labels.exact_consumption") : locs("labels.expected_consumption");

  let consumptionFloat = parseFloat(consumption || "0");
  let consumptionHighFloat = parseFloat(consumptionHigh || "0");
  let consumptionLowFloat = parseFloat(consumptionLow || "0");

  if (!knownConsumption && !estimate) {
    consumptionValue = consumptionFloat;
  } else if (!knownConsumption && estimate) {
    consumptionValue = consumptionFloat = estimate.consumption;
    consumptionHighFloat = estimate.consumptionHigh;
    consumptionLowFloat = estimate.consumptionLow;

    if (electricity) {
      consumptionValue = consumptionHighFloat + consumptionLowFloat;
    }
  } else if (electricity) {
    consumptionValue = consumptionHighFloat + consumptionLowFloat;
  } else if (!electricity) {
    consumptionValue = consumptionFloat;
  }

  return {
    prefix,
    consumption: consumptionValue,
    consumptionHigh: consumptionHighFloat,
    consumptionLow: consumptionLowFloat,
  };
};
