import React, { PureComponent } from "react";
import { connect, ConnectedProps } from "react-redux";
import Dialog from "../../../containers/Dialog";

import { getPartners, isSigned } from "../../../selectors/user";

import SignIn from "./SignIn";
import LoginSelector from "./LoginSelector";
import { login } from "../../../actions/user";
import { isElectricity, isGas } from "../../../selectors/contract";
import ChoosePartner from "./ChoosePartner";

interface LoginProps {
  // External props
  onClose?: () => void;
  onNotCustomer?: () => void;
  onSelectPartner: (id: string, partnerType: number) => void;
}

const mapStateToProps = state => ({
  signed: isSigned(state),
  electricity: isElectricity(state),
  gas: isGas(state),
  partners: getPartners(state),
});

const mapDispatchToProps = {
  onSignIn: login,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface LoginState {
  showSignIn: boolean;
}

class Login extends PureComponent<LoginProps & PropsFromRedux, LoginState> {
  static defaultProps = {
    onClose: () => {},
  };

  state = {
    showSignIn: false,
  };

  handleSignInView = () => {
    this.setState({ showSignIn: true });
  };

  handleSelectPartner = (data: { partnerId: string; partnerType: number }) => {
    this.props.onSelectPartner(data.partnerId, data.partnerType);
  };

  render() {
    const { showSignIn } = this.state;
    const { signed, electricity, gas, partners } = this.props;

    let view;

    if (signed) {
      view = <ChoosePartner partners={partners} electricity={electricity} gas={gas} onSubmit={this.handleSelectPartner} />;
    } else if (showSignIn) {
      view = <SignIn onSubmit={this.props.onSignIn} />;
    } else {
      view = <LoginSelector signInView={this.handleSignInView} onNotCustomer={this.props.onNotCustomer} />;
    }

    return <Dialog onClose={this.props.onClose}>{view}</Dialog>;
  }
}

export default connector(Login);
