import validator from "./util/createValidator";

const defaults = {
  nullable: true,
};

export function isIn<T = string>(value: T, settings: { values: T[] }) {
  const { values } = settings;

  return values.indexOf(value) >= 0;
}

export default validator(isIn, "isIn", defaults);
