import isEqual from "lodash/isEqual";
import {
  CONTRACT_CONFIRM_SUCCESS,
  CONTRACT_PRODUCTS_FAILURE,
  CONTRACT_PRODUCTS_REQUEST,
  CONTRACT_PRODUCTS_SUCCESS,
  CONTRACT_RESTORE_SUCCESS,
  CONTRACT_SAVE_FAILURE,
  CONTRACT_SAVE_REQUEST,
  CONTRACT_SAVE_SUCCESS,
} from "../constants/actions";
import { DATA_FAILURE, DATA_REQUEST, DATA_SUCCESS } from "../constants/async";
import { CalculatorValues } from "./types";

function getSourceData(formData: Partial<CalculatorValues> = {}) {
  return {
    commodityValue: formData.commodityValue,
    partnerValue: formData.partnerValue,
    calculate: formData.calculate,
    regionRuianCode: formData.regionRuianCode,
  };
}

export const productsInitialState = {
  contractStatus: null,
  sourceData: {},
  productsStatus: null,
  data: [],
};

export default (state = productsInitialState, action) => {
  switch (action.type) {
    case CONTRACT_RESTORE_SUCCESS:
      return {
        contractStatus: DATA_SUCCESS,
        sourceData: getSourceData(action.payload),
      };

    case CONTRACT_SAVE_REQUEST:
      if (isEqual(state.sourceData, getSourceData(action.payload))) return state;

      return {
        contractStatus: DATA_REQUEST,
        sourceData: getSourceData(action.payload),
      };

    case CONTRACT_SAVE_SUCCESS:
      return {
        ...state,
        contractStatus: DATA_SUCCESS,
      };

    case CONTRACT_SAVE_FAILURE:
      return {
        ...state,
        contractStatus: DATA_FAILURE,
        error: action.payload,
      };

    case CONTRACT_PRODUCTS_REQUEST:
      return {
        ...state,
        productsStatus: DATA_REQUEST,
        data: [],
      };

    case CONTRACT_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsStatus: DATA_SUCCESS,
        data: action.payload.sort((a, b) => b.top - a.top || a.prices.total - b.prices.total),
      };

    case CONTRACT_PRODUCTS_FAILURE:
      return {
        ...state,
        productsStatus: DATA_FAILURE,
        error: action.payload,
      };

    case CONTRACT_CONFIRM_SUCCESS:
      return {
        ...state,
        contractStatus: DATA_SUCCESS,
        bonus: action.payload.bonus,
      };

    default:
      return state;
  }
};
