import { post, get, del } from "../api";
import toCamelCase from "../helpers/toCamelCase";

import {
  USER_LOGIN_FAILURE,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE,
  USER_LOGOUT_REQUEST,
  USER_GET_DATA_SUCCESS,
  USER_GET_DATA_FAILURE,
  USER_GET_DATA_REQUEST,
} from "../constants/actions";

const SESSION_PATH = "/session";

export function getData(waitForData = false) {
  return dispatch => {
    dispatch({ type: USER_GET_DATA_REQUEST, waitForData });

    return get(SESSION_PATH, {})
      .then(data => {
        dispatch({ type: USER_GET_DATA_SUCCESS, payload: toCamelCase(data) });
      })
      .catch(error => dispatch({ type: USER_GET_DATA_FAILURE, payload: error }));
  };
}

function normalizeLogin(value = "") {
  if (String.prototype.toLocaleLowerCase) {
    return value.trim().toLocaleLowerCase();
  }
  return value.trim().toLowerCase();
}

export function login(payload: { email: string; password: string }) {
  return dispatch => {
    dispatch({ type: USER_LOGIN_REQUEST });

    const req = {
      email: normalizeLogin(payload.email),
      password: payload.password,
    };

    return post(SESSION_PATH, req)
      .then(data =>
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: toCamelCase(data),
          login: normalizeLogin(payload.email),
        })
      )
      .catch(error => dispatch({ type: USER_LOGIN_FAILURE, payload: error }));
  };
}

export function logout() {
  return dispatch => {
    dispatch({ type: USER_LOGOUT_REQUEST });
    del(SESSION_PATH)
      .then(data => dispatch({ type: USER_LOGOUT_SUCCESS, payload: data }))
      .catch(error => dispatch({ type: USER_LOGOUT_FAILURE, payload: error }));
  };
}
