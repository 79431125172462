import React, { PureComponent } from "react";
import styled from "styled-components";
import InlineLoader from "./InlineLoader";

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -24px;
  text-align: center;
`;

interface LoaderProps extends React.HTMLProps<HTMLDivElement> {
  loaded: boolean;
  title?: string;
  subTitle?: string;
}

class Loader extends PureComponent<LoaderProps> {
  private loader?: HTMLElement;

  componentDidMount() {
    // on first using target loader
    this.loader = document.getElementById("loader");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loaded === true && this.loader) {
      this.destroyLoader();
    }
  }

  componentWillUnmount() {
    this.destroyLoader();
  }

  destroyLoader = () => {
    if (this.loader) this.loader.parentNode.removeChild(this.loader);
    delete this.loader;
  };

  render() {
    const { loaded, children, title, subTitle } = this.props;
    if (loaded) {
      return children;
    }
    if (this.loader) {
      return null;
    }
    return (
      <LoaderWrapper>
        <InlineLoader title={title} subTitle={subTitle} />
      </LoaderWrapper>
    );
  }
}

export default Loader;
