import validator from "./util/createValidator";

const EIC_PREFIX = "27ZG";

const charToNumber = char => {
  if (char === "-") {
    return 36;
  } else if (/^\d$/.test(char)) {
    return parseInt(char, 10);
  } else if (/^[a-zA-Z]$/.test(char)) {
    return char.toUpperCase().charCodeAt(0) - "A".charCodeAt(0) + 10;
  }
  return -1;
};

const defaults = {
  prefix: EIC_PREFIX,
};

export function isEIC(value, settings) {
  if (!value || value === settings.prefix) return true;

  let formattedValue = value ? String(value).replace(/\s/g, "") : "";
  if (formattedValue.indexOf(settings.prefix) === -1) {
    formattedValue = `${settings.prefix}${formattedValue}`;
  }

  let valid = formattedValue.length === 16;
  let sum;

  if (valid && ["N", "Z", "V"].indexOf(formattedValue[7]) < 0) {
    valid = false;
  }

  if (valid) {
    let ch;
    let index;
    let length;
    let val;
    sum = 0;
    for (index = 0, { length } = formattedValue; index < length; index += 1) {
      ch = formattedValue[index];
      val = charToNumber(ch);
      if (val === -1) {
        valid = false;
        break;
      } else {
        sum += val * (16 - index);
      }
    }
  }

  valid = valid && sum % 37 === 0;
  return valid;
}

export default validator(isEIC, "isEIC", defaults);
