import React, { PureComponent } from "react";
import { InjectedFormProps, reduxForm } from "redux-form";
import { connect, ConnectedProps } from "react-redux";
import { compose } from "redux";
import classNames from "classnames";

import FinalPage from "../FinalPage";
import styles from "../FinalPage/styles.scss";

import { isElectricity, isGas } from "../../helpers";
import { COMMODITY_ELECTRICITY, COMMODITY_GAS } from "../../constants";

import initialValues from "../initialValues";
import { getCommodity, getProduct, isTransfer } from "../../selectors/contract";
import ProgressBar from "../../components/ProgressBar";
import { Pages } from "../../lifecycles";
import Survey from "../../components/Survey";

interface FinalPageFormProps {
  signed: boolean;
  toTheSpecificPage: (page: string) => void;
}

const formKey = "calculator";

const mapStateToProps = state => ({
  commodity: getCommodity(state),
  product: getProduct(state),
  transfer: isTransfer(state),
  state: state,
  bonus: state.products.bonus,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

class FinalPageForm extends PureComponent<FinalPageFormProps & PropsFromRedux & InjectedFormProps> {
  componentDidMount() {
    const { product, toTheSpecificPage, change, untouch, state } = this.props;
    if (!product) toTheSpecificPage("/");

    const fields = [
      "calculate",
      "supplyPoint",
      "supplyPointValue",
      "invoice",
      "recapitulation",
      "note",
      "transfer.documents.propertyRelation",
      "transfer.documents.powerOfAttorneyPreviousCustomer",
      "transfer.documents.deathCertificate",
      "transfer.documents.operatorDocument",
      "transfer.documents.contractTerminationRequest",
      "transfer",
      "signature",
      "contact.powerOfAttorneyNewCustomer",
      "contact.powerOfAttorneyCompany",
      "confirmContract",
      "confirmValidData",
    ];

    // reset fields
    fields.forEach(field => {
      if (field === "calculate") {
        change(field, initialValues.calculate);
      } else if (field === "supplyPoint") {
        change(field, initialValues.supplyPoint);
      } else {
        change(field, undefined);
      }
    });

    // untouch every possible field
    const registeredFields = state.form[formKey].registeredFields;
    if (!registeredFields) return;
    Object.keys(registeredFields).forEach(field => {
      // appliances are wonky and won't work
      if (field.includes("appliances")) {
        return;
      }
      fields.forEach(targetField => {
        if (field.startsWith(targetField)) {
          untouch(field);
        }
      });
    });
  }

  handleRedirectionToTheFirstPage = e => {
    const { change, commodity, toTheSpecificPage } = this.props;

    if (e) e.preventDefault();
    if (isElectricity(commodity)) change("commodityValue", COMMODITY_GAS);
    if (isGas(commodity)) change("commodityValue", COMMODITY_ELECTRICITY);
    toTheSpecificPage("/");
  };

  render() {
    const baseContainerClassName = classNames(styles.pageContainer);

    // if there is no product, do not render anything and let the user be redirected to the first page
    if (!this.props.product) {
      return null;
    }

    return (
      <div>
        <ProgressBar page={Pages.Final} />
        <div className={baseContainerClassName}>
          <FinalPage toTheFirstPage={this.handleRedirectionToTheFirstPage} {...this.props} />
        </div>
        <Survey />
      </div>
    );
  }
}

export default compose(
  reduxForm({
    form: formKey, // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  }),
  connector
)(FinalPageForm);
