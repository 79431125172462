import React, { PureComponent } from "react";
import { connect, ConnectedProps } from "react-redux";
import getValue from "lodash/get";
import locs from "../../localization";
import addressFormatter from "../../formatters/address";
import contractValidityFormatter from "../../formatters/contractValidity";

import { getEnumerationRecordById } from "../../reducers/enumerations";
import { ENUMERATION_INSTALLATION_FLOOR, ENUMERATION_SUPPLIER } from "../../constants/enumerations";

import Block from "./Block";
import Row from "./Row";
import {
  getConsumption,
  isElectricity,
  isGas,
  getFillDataType,
  getEstimate,
  getKnownConsumption,
  isTransfer,
  getLifecycle,
} from "../../selectors/contract";
import { Enumerations, InstallationFloor } from "../../constants/interfaces";
import { calculateConsumption } from "../../helpers/calculateConsumption";
import { getTariffs } from "../../selectors/enumerations";

interface SupplyPointInfoProps {
  data: any;
  toSupplyPoint?: () => void;
  toPreviousCustomer?: () => void;
  enumerations: Enumerations;
  isTransfer: boolean;
}

const mapStateToProps = state => ({
  isElectricity: isElectricity(state),
  isGas: isGas(state),
  consumption: getConsumption(state),
  fillDataType: getFillDataType(state),
  estimate: getEstimate(state),
  knownConsumption: getKnownConsumption(state),
  isTransfer: isTransfer(state), // Not customers change!
  lifecycle: getLifecycle(state),
  tariffs: getTariffs(state),
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

class SupplyPointInfo extends PureComponent<SupplyPointInfoProps & PropsFromRedux> {
  renderConsumption = () => {
    const { prefix, consumption, consumptionHigh, consumptionLow } = calculateConsumption(
      this.props.estimate,
      true,
      this.props.consumption,
      this.props.isElectricity
    );

    if (this.props.isElectricity && (consumptionHigh || consumptionLow)) {
      if (!consumptionLow) {
        return <Row term={prefix} value={`${consumptionHigh} ${locs("labels.mwh")}`} />;
      }
      return [
        <Row
          key="ht"
          term={`${prefix} (${locs("labels.high_tariff").toLowerCase()})`}
          value={`${consumptionHigh} ${locs("labels.mwh")}`}
        />,
        <Row
          key="lt"
          term={`${prefix} (${locs("labels.low_tariff").toLowerCase()})`}
          value={`${consumptionLow} ${locs("labels.mwh")}`}
        />,
      ];
    }
    if (this.props.isGas && consumption) {
      return <Row term={prefix} value={`${consumption} ${locs("labels.mwh")}`} />;
    }
    return <Row term={prefix} value="" />;
  };

  render() {
    const {
      data: {
        supplyPoint: { data },
        contact,
        transfer,
      },
      enumerations,
      toSupplyPoint,
      toPreviousCustomer,
      isElectricity,
      isTransfer,
      lifecycle,
      tariffs,
    } = this.props;
    const { tariffId, circuitBreakerPhase, circuitBreakerValue } = this.props.consumption;

    const { supplyPointAddressIsSame } = data;
    let address = {};
    if (supplyPointAddressIsSame && !isTransfer) {
      address = getValue(contact, "addresses.permanentAddress");
    } else {
      address = data.supplyPointAddress;
    }
    const tariff = tariffs.find(x => x.id === tariffId);

    // Text supplier
    const { currentSupplier = {} } = data;
    const { desc: supplier } =
      getEnumerationRecordById(enumerations, ENUMERATION_SUPPLIER, parseInt(currentSupplier.supplierId, 10)) || {};

    // Text floor
    let floor: string | undefined;
    if (data.installationFloorId) {
      floor = getEnumerationRecordById<InstallationFloor>(
        enumerations,
        ENUMERATION_INSTALLATION_FLOOR,
        parseInt(data.installationFloorId, 10)
      )?.name;
    }

    let previousState = null;

    if (lifecycle.showPreviousConsumption) {
      previousState = (
        <Block title={locs("titles.supplyPointPreviousState")} editData={toPreviousCustomer}>
          <Row
            term={locs(isElectricity ? "labels.high_tariff" : "labels.actual_state")}
            value={`${transfer.previousState.value} ${locs(isElectricity ? "labels.kw" : "labels.m3")}`}
          />
          {isElectricity && transfer.previousState.valueNt && (
            <Row term={locs("labels.low_tariff")} value={`${transfer.previousState.valueNt} ${locs("labels.kw")}`} />
          )}
          <Row term={locs("labels.deviceId")} value={transfer.previousState.deviceId} />
          <Row term={locs("labels.readingDate")} value={transfer.previousState.date} />
        </Block>
      );
    }

    return (
      <>
        <Block title={locs("titles.supplyPoint")} editData={toSupplyPoint}>
          <Row term={locs(`labels.${this.props.isElectricity ? "ean" : "eic"}`)} value={getValue(data, "code")} />
          {this.renderConsumption()}

          {isElectricity && (
            <>
              <Row term={locs("labels.distribution_rate")} value={tariff?.value} />
              <Row term={locs("labels.phases")} value={circuitBreakerPhase?.toString()} />
              <Row term={locs("labels.reserved_powers")} value={`${circuitBreakerValue || null} ${locs("labels.a")}`} />
            </>
          )}

          <Row term={locs("labels.supplyPointAddress")} value={addressFormatter(address)} />
          {data.supplyPointIsAFlat && (
            <Row term={locs("labels.flatAndFloor")} value={`${data.flatNumber || "-"}\n${floor || "-"}`} />
          )}
          {lifecycle.selectCurrentSupplier && (
            <>
              <Row term={locs("labels.currentSupplier")} value={supplier} />
              {lifecycle.showContractValidity && (
                <Row term={locs("labels.endOfValidity")} value={contractValidityFormatter(data.currentSupplier)} />
              )}
              {data.currentSupplier.previousContractTerminated && (
                <Row
                  term={""}
                  value={`${locs("labels.previousContractTerminated")} ${locs("labels.to")} ${data.currentSupplier.validTo}`}
                />
              )}
            </>
          )}
        </Block>
        {previousState}
      </>
    );
  }
}

export default connector(SupplyPointInfo);
