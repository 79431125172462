import React, { PureComponent } from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { connect, ConnectedProps } from "react-redux";
import locs from "../../../localization";

import Container from "../../../containers/Container";
import Column from "../../../containers/Column";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import Checkbox from "../../../components/Checkbox";
import FormGroup from "../../../components/UI/FormGroup";
import PreviousCustomer from "../../Contact/PreviousCustomer";
import styles from "../styles.scss";
import theme from "../../Contact/theme.scss";
import supplyPointTheme from "../../SupplyPoint/theme.scss";
import Address from "../../../composites/Address";
import File from "../../../components/File";

import { getEnumeration, getEstimates, getTariffs } from "../../../selectors/enumerations";
import formValidation from "../../../validations/formValidation";
import isLength from "../../../validations/isLength";

import {
  getCommodity,
  getSupplyPointData,
  isElectricity,
  isCompany,
  getTransfer,
  getPartner,
  isSupplyPointFlat,
  getTransferPreviousPartnerValue,
  isPartnerIdSelected,
  isTransferNewProcess,
} from "../../../selectors/contract";

import {
  ENUMERATION_CIRCUIT_BREAKER,
  ENUMERATION_INSTALLATION_FLOOR,
  ENUMERATION_TITLE_PREFIX,
  ENUMERATION_TITLE_SUFFIX,
} from "../../../constants/enumerations";

import paymentsTheme from "../../Payments/theme.scss";

import Calculate, { calculateFormChange } from "../../Calculator/Consumption/Calculate";
import Link from "../../../components/Link";
import FixedTooltip from "../../../components/FixedTooltip";
import Dialog from "../../../containers/Dialog";
import { compose } from "redux";
import { UPLOAD_ATTACHMENTS_PATH } from "../../../api";
import isNotEmpty from "../../../validations/isNotEmpty";
import PartnerType from "../../Calculator/PartnerType";
import { formatEnumerationData, seleniumClass } from "../../../helpers";
import InlineLoader from "../../../components/Loader/InlineLoader";
import classNames from "classnames";
import { fetchEnumeration } from "../../../actions/enumerations";
import { CircuitBreaker, InstallationFloor, TitlePrefix, TitleSuffix } from "../../../constants/interfaces";
import PreviousConsumption from "../../../composites/PreviousConsumption";
import { CalculatorValues } from "../../../reducers/types";
import { gasConsumptionChange } from "../../Calculator/Consumption/Gas/Consumption";
import { updateTariffAndCircuitBreakers } from "../../Calculator/Consumption/Electricity/Consumption";
import { MEGABYTE } from "../../../constants";

const requiredEnumerations = [ENUMERATION_INSTALLATION_FLOOR];

const mapStateToProps = state => ({
  partner: getPartner(state),
  company: isCompany(state),
  commodity: getCommodity(state),
  isElectricity: isElectricity(state),
  supplyPoint: getSupplyPointData(state),
  transfer: getTransfer(state),
  installationFloors: getEnumeration<InstallationFloor>(state, "installation_floors"),
  transferPreviousPartnerValue: getTransferPreviousPartnerValue(state),
  isTransferNewProcess: isTransferNewProcess(state),
  estimates: getEstimates(state),
  supplyPointIsAFlat: isSupplyPointFlat(state),
  isPartnerIdSelected: isPartnerIdSelected(state),
  titlePrefixes: getEnumeration<TitlePrefix>(state, ENUMERATION_TITLE_PREFIX),
  titleSuffixes: getEnumeration<TitleSuffix>(state, ENUMERATION_TITLE_SUFFIX),
  tariffs: getTariffs(state),
  circuitBreakers: getEnumeration<CircuitBreaker>(state, ENUMERATION_CIRCUIT_BREAKER),
});

const mapDispatchToProps = {
  fetchEnumeration,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface TransferSummaryFormState {
  invalid: boolean;
}

interface TransferSummaryFormProps {
  previousPage: () => void;
}

class TransferSummaryForm extends PureComponent<
  TransferSummaryFormProps & PropsFromRedux & InjectedFormProps,
  TransferSummaryFormState
> {
  state = {
    invalid: false,
  };

  componentDidMount() {
    requiredEnumerations.forEach(this.props.fetchEnumeration);
  }

  render() {
    const {
      handleSubmit,
      isElectricity,
      supplyPoint,
      transfer,
      supplyPointIsAFlat,
      change,
      untouch,
      estimates,
      commodity,
      submitting,
      transferPreviousPartnerValue,
      isPartnerIdSelected,
      isTransferNewProcess,
      installationFloors,
      titlePrefixes,
      titleSuffixes,
      previousPage,
    } = this.props;

    const { invalid } = this.state;

    // Set invalid window
    const toggleInvalidModal = () => {
      this.setState({ invalid: !invalid });
    };

    if (invalid) {
      return (
        <Dialog closable={true} onClose={toggleInvalidModal}>
          <div className={styles.transferInvalidContainer}>
            <Title tag="h3" name={locs("texts.contactCustomerService")} className={styles.title} />
            <div dangerouslySetInnerHTML={{ __html: locs("texts.customerNumbers") }} />
          </div>
        </Dialog>
      );
    }

    return (
      <Container>
        <div>
          <Column>
            <Title name={locs("titles.transfer")} tag="h1" big={true} />
          </Column>
          <Column>
            <Title name={locs("titles.checkOpmData")} underline />
          </Column>

          <Field
            component={PreviousConsumption}
            name="transfer.previousState"
            props={{
              isElectricity,
              disabled: !isTransferNewProcess,
              supplyPointCode: supplyPoint.code || "",
              theme,
              valueNt: isElectricity && ((transfer.previousState.valueNt || isTransferNewProcess) as boolean),
            }}
          />

          <Field
            name="supplyPoint.data.supplyPointAddress"
            component={Address as any}
            props={{
              disabled: true,
              title: locs("titles.supplyPointAddress"),
            }}
          />
          <div className={styles.addressCheckbox}>
            <Field
              name="supplyPoint.data.supplyPointIsAFlat"
              type="checkbox"
              props={{
                label: locs("labels.supplyPointIsAFlat"),
              }}
              component={Checkbox}
            />
          </div>
          {supplyPointIsAFlat && (
            <Column columns={3} weights={["1_11", 1, 1]}>
              <Field
                name="supplyPoint.data.flatNumber"
                component={Input}
                validate={[formValidation(isLength({ max: 5 }))]}
                props={{
                  label: locs("labels.flatNumber"),
                  theme: {
                    ...supplyPointTheme,
                    inputContainer: supplyPointTheme.inputContainerFlat,
                  },
                }}
              />
              <Field
                name="supplyPoint.data.installationFloorId"
                type="select"
                props={{
                  label: locs("labels.floor"),
                  theme: {
                    inputContainer: supplyPointTheme.inputContainerFlat,
                    label: supplyPointTheme.labelFloor,
                  },
                  data: formatEnumerationData<InstallationFloor>(installationFloors, "name"),
                }}
                component={Select}
              />
            </Column>
          )}
          <Field
            name="transfer"
            component={PreviousCustomer}
            props={{
              titlePrefixes,
              titleSuffixes,
              disabled: true,
              previousPartner: transferPreviousPartnerValue,
            }}
          />
          <Column>
            <Title name={locs("titles.transferInfo")} />
          </Column>

          <Column>
            <PartnerType name="partnerValue" label={locs("labels.iam")} theme={paymentsTheme} disabled={isPartnerIdSelected} />
          </Column>

          <Column weights={["2", 1]} columns={2}>
            <div className={styles.addressCheckbox} style={{ marginTop: "15px" }}>
              <FormGroup>
                <Field
                  name={`calculate.${commodity.value}`}
                  component={Calculate}
                  props={{
                    change,
                    untouch,
                    commodity,
                    estimates,
                    hideLabel: true,
                  }}
                />
              </FormGroup>
            </div>
          </Column>
          {!isTransferNewProcess && (
            <>
              <Column>
                <Title name={`3) ${locs("titles.transferDocuments")}`} underline />
              </Column>
              <div className={styles.file}>
                <FixedTooltip position="top" theme={{ tooltipContainer: styles.tooltipContainerTransfer }}>
                  <div
                    className={styles.inkasoTooltipContent}
                    dangerouslySetInnerHTML={{ __html: locs("texts.transferDocuments") }}
                  />
                </FixedTooltip>
                <Field
                  name="propertyRelation"
                  component={File}
                  fixedName={true}
                  type="file"
                  validate={[formValidation(isNotEmpty, locs("errors.fileIsRequired"))]}
                  props={{
                    endPoints: { request: UPLOAD_ATTACHMENTS_PATH },
                    theme: {
                      filesContainer: styles.tooltipFileContainer,
                      dropzoneWrapper: styles.tooltipFileContainer,
                      dropzoneElement: styles.tooltipFileContainer,
                    },
                    showDropzone: true,
                    sizeLimit: 6 * MEGABYTE,
                  }}
                />
              </div>
            </>
          )}
          <div className={styles.transferSummaryFooter}>
            <div className={styles.upperPart}>
              <Link label={locs("labels.invalid_transfer_data")} onClick={toggleInvalidModal} />
            </div>
            <div className={styles.lowerPart}>
              <Link label={locs("actions.go_back")} onClick={previousPage} />
              <Button
                className={classNames(seleniumClass("next"), styles.transferSubmit)}
                disabled={submitting}
                onClick={handleSubmit}
              >
                {submitting ? <InlineLoader height={12} /> : locs("actions.nextTransfer")}
              </Button>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const formKey = "calculator";

export default compose(
  connector,
  reduxForm({
    form: formKey, // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    onChange: (
      values: CalculatorValues,
      dispatch,
      props: InjectedFormProps & PropsFromRedux,
      previousValue: CalculatorValues
    ) => {
      // Estimates and toggling combinations
      calculateFormChange(values, props.estimates, props.commodity.value, dispatch, props, previousValue);

      if (!props.isElectricity) {
        // Gas callbacks
        gasConsumptionChange({
          values: values.calculate?.P2?.detail,
          previousValues: previousValue.calculate?.P2?.detail,
          dispatch,
          props,
          path: "calculate.P2.detail",
        });
      }

      // Update tariffs and circuit breakers
      if (props.isElectricity && values.calculate?.E1?.knownConsumption) {
        updateTariffAndCircuitBreakers({
          values: values.calculate?.E1?.detail,
          previousValues: previousValue?.calculate?.E1?.detail,
          dispatch,
          props,
          tariffs: props.tariffs,
          circuitBreakers: props.circuitBreakers,
          path: "calculate.E1.detail",
        });
      }
    },
  })
)(TransferSummaryForm);
