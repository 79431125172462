import uniqBy from "lodash/uniqBy";
import { fetchRuian } from "../../api";

export function fullAddressLoader() {
  return function loadSuggestions(value: string) {
    const tasks = [];

    tasks.push(fetchRuian({ field: value, distinct: "street" }));
    tasks.push(fetchRuian({ field: value }));

    return Promise.all(tasks)
      .then(result => result.reduce((acc, dataSet) => acc.concat(dataSet)))
      .then(result => uniqBy(result, "id"))
      .then(result => result.splice(0, 11));
  };
}
