import axios from "axios";
import toCamelCase from "./helpers/toCamelCase";
import toSnakeCase from "./helpers/toSnakeCase";

const API_URL = "/api/v1/";
export const UPLOAD_ATTACHMENTS_PATH = `${API_URL}/contract/attachments`;

export function get(path: string, params?: any) {
  return axios.get(`${API_URL}${path}`, { params }).then(response => response.data.data || []);
}

export function post(path: string, data?: any) {
  return axios.post(`${API_URL}${path}`, data).then(response => response.data.data || []);
}

export function put(path: string, data?: any) {
  return axios.put(`${API_URL}${path}`, data).then(response => response.data.data || []);
}

export function del(path: string) {
  return axios.delete(`${API_URL}${path}`).then(response => response.data.data || []);
}

export function fetchRuian(params: any) {
  return axios.get(`${API_URL}ruian/field`, { params: toSnakeCase(params) }).then(response =>
    (response.data.addresses || [])
      .map(row => toCamelCase(row))
      .map(row => ({
        ...row,
        houseNumber: row.p,
        streetNumber: row.o,
        registrationNumber: row.e,
        ruianCode: row.id,
      }))
  );
}

export function fetchEnumerations(name: string) {
  return get(`enumerations/${name}`).then(data => data.map(row => toCamelCase(row)));
}
