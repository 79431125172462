import React from "react";
import range from "lodash/range";
import { Field, FieldArrayFieldsProps, InjectedFormProps } from "redux-form";
import classNames from "classnames";
import locs from "../../localization";
import Input from "../../components/Input";
import Select from "../../components/Select";
import { formatEnumerationData } from "../../helpers";

import styles from "./styles.scss";
import uiStyles from "../../components/UI/styles.scss";
import selectStyles from "../../components/Select/styles.scss";
import theme from "./theme.scss";

import formValidation from "../../validations/formValidation";
import isNumber from "../../validations/isNumber";
import { Appliance } from "../../constants/interfaces";
import { CalculatorValues } from "../../reducers/types";
import isNotEmpty from "../../validations/isNotEmpty";

interface GasAppliancesProps {
  fields: FieldArrayFieldsProps<any>;
  appliances: Appliance[];
  member: string;
  index: number;
  disabled: boolean;
  consumptionRequired?: boolean;
}

export const prefillAppliances = (
  values: CalculatorValues,
  dispatch,
  props: InjectedFormProps,
  appliances: Appliance[],
  previousValue: CalculatorValues
) => {
  // Check if some applianceId in array was changed
  if (!values.supplyPoint?.data.gasUsage?.appliances?.length) {
    return;
  }

  values.supplyPoint.data.gasUsage.appliances.forEach((item, index) => {
    if (item && item.applianceId !== previousValue.supplyPoint?.data?.gasUsage?.appliances?.[index]?.applianceId) {
      const appliance = appliances.find(a => a.id === parseInt(item.applianceId, 10));

      if (appliance && parseInt(item.count, 10) !== 1) {
        dispatch(props.change(`supplyPoint.data.gasUsage.appliances[${index}].count`, 1));
      }
    }
  });
};

export default function GasAppliances(props: GasAppliancesProps) {
  const { fields, member, index, appliances, disabled, consumptionRequired = true } = props;

  const handleChange = i => (event, value) => {
    if (fields.length > 9) return;
    if (value) {
      // Add new row
      const filledAppliance = fields.get(i);
      if (!filledAppliance) {
        // this creates (pre-allocate) an empty new row, which is required to add more and more appliances
        // but TS requires an argument here (that's why ts-ignore)
        // @ts-ignore
        fields.push();
      }
    }
  };

  const filledAppliance = fields.get(index) && fields.get(index).applianceId;
  const numberedRange = range(1, 11).map(i => ({ label: i, value: String(i) }));

  return (
    <div className={classNames(uiStyles.form_control, styles.gasAppliance)}>
      <div className={classNames(selectStyles.label, theme.label)}>
        {index + 1}. {locs("labels.appliance")}
      </div>
      <Field
        name={`${member}.applianceId`}
        type="select"
        component={Select}
        onChange={handleChange(index)}
        props={{
          disabled,
          data: formatEnumerationData(appliances, "desc"),
          showBlankOption: true,
          theme: {
            baseContainer: styles.gasApplianceId,
          },
        }}
      />
      {filledAppliance && (
        <Field
          name={`${member}.consumption`}
          type="number"
          component={Input}
          props={{
            theme: {
              baseContainer: styles.gasApplianceInput,
              label: styles.gasApplianceLabel,
              inputContainer: styles.gasApplianceConsumption,
            },
            disabled,
            suffix: locs("labels.kw"),
          }}
          label={locs("labels.consumption")}
          validate={consumptionRequired ? [formValidation(isNotEmpty), formValidation(isNumber)] : undefined}
        />
      )}
      {filledAppliance && (
        <Field
          name={`${member}.count`}
          type="select"
          component={Select}
          disabled={disabled}
          showBlankOption={false}
          label={locs("labels.count")}
          theme={{
            baseContainer: styles.gasApplianceInput,
            label: styles.gasApplianceLabel,
            inputContainer: styles.gasApplianceCount,
          }}
          data={numberedRange}
        />
      )}
      {filledAppliance && !disabled && (
        <div className={styles.removeButton} onClick={disabled ? null : () => fields.remove(index)} role="button" tabIndex={0}>
          ×
        </div>
      )}
    </div>
  );
}
