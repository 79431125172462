import React from "react";
import Tooltip from "rc-tooltip";

// Styles import is necessary, we need to import and override global styles for rc-tooltip.
import styles from "./styles.scss";
import { TooltipProps } from "rc-tooltip/lib/Tooltip";

interface TooltipContainerProps extends TooltipProps {
  type?: "default" | "map";
  trigger?: string;
}

export default function TooltipContainer(props: TooltipContainerProps) {
  const { children, trigger = "hover", type = "default", ...rest } = props;

  if (!props.overlay) {
    return children;
  }

  return (
    <Tooltip trigger={[trigger]} {...rest} overlayClassName={styles[type]}>
      {children}
    </Tooltip>
  );
}
