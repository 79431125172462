const BANK_ACCOUNT_REGEXP = /^(([0-9]{0,6})-)?([0-9]{1,10})\/[0-9]{4}$/;

const prependZeros = (variable, maxLength) => {
  let output = variable;
  for (let a = variable.length + 1; a <= maxLength; a += 1) {
    output = `0${output}`;
  }
  return output;
};

const validateModulo = function validateModulo(variable, weights) {
  return weights.map((n, i) => n * variable[i]).reduce((a, b) => a + b, 0) % 11 === 0;
};

export default function isBankAccount(value: { prefix?: string; number?: string; bankCode?: string } = {}) {
  if (!value.prefix && !value.number && !value.bankCode) return true;
  const bankAccount = `${value.prefix || 0}-${value.number}/${value.bankCode}`;
  let valid = BANK_ACCOUNT_REGEXP.test(bankAccount.replace(" ", ""));

  if (valid) {
    if (value.prefix) {
      // logic borrowed from http://www.michalhaltuf.cz/blog/2016/04/validace-cisla-bankovniho-uctu-v-php/
      const prefix = prependZeros(value.prefix, 6);
      valid = validateModulo(prefix, [10, 5, 8, 4, 2, 1]);
    }
    if (valid) {
      const accountNumber = prependZeros(value.number, 10);
      valid = validateModulo(accountNumber, [6, 3, 7, 9, 10, 5, 8, 4, 2, 1]);
    }
  }
  return valid;
}
