const SIPO_WEIGHTS = "3731731731";
export default function isSipo(value) {
  if (!value) return true;
  let valid = false;
  if (/^(\d{10})$/.test(value)) {
    let sum = 0;
    for (let index = 0; index < 10; index += 1) {
      sum += parseInt(value[index], 10) * parseInt(SIPO_WEIGHTS[index], 10);
    }
    valid = sum % 10 === 0;
  }
  return valid;
}
