import React from "react";

import locs from "../../localization";
import styles from "./styles.scss";
import { Field } from "redux-form";
import { UPLOAD_ATTACHMENTS_PATH } from "../../api";
import Column from "../../containers/Column";
import formValidation from "../../validations/formValidation";
import isNotEmpty from "../../validations/isNotEmpty";
import Button from "../Button";
import Title from "../Title";
import File from "../File";

export interface PowerOfAttorneyUploadProps {
  name: string;
  title: string;
  required: boolean;
  showTemplate: boolean;
  variable?: string
}

export default function PowerOfAttorneyUpload({
  name,
  title,
  required,
  showTemplate,
  variable = "powerOfAttorneyNewCustomer",
}: PowerOfAttorneyUploadProps) {
  return (
    <>
      <div className={styles.spacer} key="custom_file_spacer_poa_new" />
      <Column key="header_power_of_attorney">
        <Title name={title} tag="h3" />
      </Column>
      {showTemplate && (
        <Button
          key="downloadPowerOfAttorney"
          className={styles.secondaryButton}
          onClick={() => window.open("pdf/power_of_attorney.pdf")}>
          {locs("labels.downloadTemplatePowerOfAttorney")}
        </Button>
      )}
      <div key="file_power_of_attorney" id="power_of_attorney_dropzone">
        <Field
          name={`${name}.${variable}`}
          component={File}
          fixedName={true}
          type="file"
          validate={required && [formValidation(isNotEmpty, locs("errors.fileIsRequired"))]}
          props={{
            dropzoneElementId: "power_of_attorney_dropzone",
            endPoints: { request: UPLOAD_ATTACHMENTS_PATH },
            theme: {
              filesContainer: styles.tooltipFileContainer,
              dropzoneWrapper: styles.tooltipFileContainer,
              dropzoneElement: styles.tooltipFileContainer,
              dropzoneOverlay: styles.dropzoneOverlay,
            },
            showDropzone: true,
          }}
        />
      </div>
    </>
  );
}
