import { fetchEnumerations } from "../api";
import { ENUMERATION_FAILURE, ENUMERATION_REQUEST, ENUMERATION_SUCCESS } from "../constants/actions";

export function fetchEnumeration(name: string) {
  return (dispatch, getState) => {
    const { enumerations } = getState();

    if (!Object.prototype.hasOwnProperty.call(enumerations, name)) {
      dispatch({ type: ENUMERATION_REQUEST, name });
      fetchEnumerations(name)
        .then(data => dispatch({ type: ENUMERATION_SUCCESS, name, payload: data }))
        .catch(error => dispatch({ type: ENUMERATION_FAILURE, name, payload: error }));
    }
  };
}
