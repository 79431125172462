export const VISIT_ROUTE = "VISIT_ROUTE";
export const RESET_VISITED_ROUTES = "RESET_VISITED_ROUTES";

export function visitRoute(name: string) {
  return dispatch => dispatch({ type: VISIT_ROUTE, payload: name });
}

export function resetVisitedRoutes() {
  return dispatch => dispatch({ type: RESET_VISITED_ROUTES });
}
