import {
  TYPE_NEW_OM,
  TYPE_NEW_OM_OWNER_CHANGE,
  TYPE_NEW_OM_OWNER_CHANGE_DEATH,
  TYPE_PROLONGATION,
  TYPE_TRANSFER,
  TYPE_TRANSFER_NEW,
  TYPE_TRANSFER_NEW_DEATH,
} from "../constants";

export enum Pages {
  Calculator = "Calculator",
  TransferSummary = "TransferSummary",
  TransferInit = "TransferInit",
  TransferDocuments = "TransferDocuments",
  Product = "Product",
  ProcessSelect = "ProcessSelect",
  PreviousCustomer = "PreviousCustomer",
  Contact = "Contact",
  Payment = "Payment",
  SupplyPoint = "SupplyPoint",
  Recapitulation = "Recapitulation",
  TransferRecapitulation = "TransferRecapitulation",
  Pdf = "Pdf",
  Final = "Final",
}

export interface Lifecycle {
  /**
   * Desktop page ORDER MATTERS!
   */
  desktopPages: Pages[];

  /**
   * Helper method to check desktop page.
   */
  hasDesktopPage(page: Pages): boolean;

  get death(): boolean;

  /**
   * Helper method for transfers (used in call me back form)
   */
  get transfer(): boolean;

  /**
   * Helper method for supply points - allow to choose current supplier?
   */
  get selectCurrentSupplier(): boolean;

  /**
   * Allow to choose supply point details in SupplyPoint Page
   */
  get selectSupplyPointsDetails(): boolean;

  /**
   * Contract validity fields in supply points
   */
  get showContractValidity(): boolean;

  /**
   * Show checkbox from #PPZP-927
   */
  get showPreviousContractTermination(): boolean;

  /**
   * For recapitulatione etc...
   */
  get showPreviousConsumption(): boolean;

  /**
   * Transfer documents - termination requests
   */
  get showTerminationRequestDocument(): boolean;

  /**
   * Transfer documents - power of attorney of previous customer
   */
  get showPowerOfAttorneyOfPreviousCustomer(): boolean;
}

export abstract class BaseLifecycle implements Lifecycle {
  constructor(
    public process: string,
    public skipProcessSelection: boolean,
    public isElectricity: boolean,
    public isHousehold: boolean,
    public isCrossSell: boolean
  ) {
    // Assign attributes
  }

  get desktopPages(): Pages[] {
    return [];
  }

  hasDesktopPage(page: Pages): boolean {
    return this.desktopPages.indexOf(page) >= 0;
  }

  get death() {
    return this.process === TYPE_TRANSFER_NEW_DEATH || this.process === TYPE_NEW_OM_OWNER_CHANGE_DEATH;
  }

  get transfer() {
    return false;
  }

  get selectCurrentSupplier() {
    return true;
  }

  get selectSupplyPointsDetails() {
    // If process is not cross sell and page is showed
    return !this.isCrossSell;
  }

  get showContractValidity() {
    return true;
  }

  get showPreviousContractTermination() {
    return false;
  }

  get showPreviousConsumption() {
    return false;
  }

  get showTerminationRequestDocument() {
    return false;
  }

  get showPowerOfAttorneyOfPreviousCustomer() {
    return false;
  }
}

/**
 * Aquisition
 */
export class Aquisition extends BaseLifecycle {
  get desktopPages() {
    const pages = [Pages.Calculator, Pages.Product];

    if (!this.skipProcessSelection) {
      pages.push(Pages.ProcessSelect);
    }

    pages.push(Pages.Contact, Pages.Payment, Pages.SupplyPoint, Pages.Recapitulation, Pages.Pdf, Pages.Final);

    return pages;
  }
}

/**
 * Prolongation is aquistion with known-values.
 */
export class Prolongation extends Aquisition {
  get desktopPages() {
    const pages = [Pages.Product, Pages.Contact, Pages.Payment, Pages.SupplyPoint, Pages.Recapitulation, Pages.Pdf, Pages.Final];

    return pages;
  }

  // Prolongation - we don't need informations about current supplier because it is PPAS
  get selectCurrentSupplier() {
    return false;
  }
}

/**
 * Normal transfer (from portal)
 */
export class Transfer extends BaseLifecycle {
  get desktopPages() {
    return [
      Pages.TransferSummary,
      Pages.Product,
      Pages.Contact,
      Pages.Payment,
      Pages.SupplyPoint,
      Pages.TransferRecapitulation,
      Pages.Pdf,
      Pages.Final,
    ];
  }

  get transfer() {
    return true;
  }

  get showPreviousConsumption() {
    return true;
  }
}

/**
 * Transfer from SAM
 */
export class TransferNew extends BaseLifecycle {
  get desktopPages() {
    return [
      Pages.TransferInit,
      Pages.TransferDocuments,
      Pages.TransferSummary,
      Pages.Product,
      Pages.Contact,
      Pages.Payment,
      Pages.SupplyPoint,
      Pages.TransferRecapitulation,
      Pages.Pdf,
      Pages.Final,
    ];
  }

  get transfer() {
    return true;
  }

  get showPreviousConsumption() {
    return true;
  }

  get selectCurrentSupplier() {
    return false;
  }

  get showTerminationRequestDocument() {
    return true;
  }
}

/**
 * New OM change owner.
 */
export class NewOmOwnerChange extends BaseLifecycle {
  get desktopPages() {
    const pages = [Pages.Calculator, Pages.Product];

    if (!this.skipProcessSelection) {
      pages.push(Pages.ProcessSelect);
    }

    pages.push(
      Pages.PreviousCustomer,
      Pages.Contact,
      Pages.Payment,
      Pages.SupplyPoint,
      Pages.TransferRecapitulation,
      Pages.Pdf,
      Pages.Final
    );

    return pages;
  }

  get showContractValidity() {
    return false;
  }

  get showPreviousContractTermination() {
    return !this.death;
  }

  get showPreviousConsumption() {
    return true;
  }

  get showPowerOfAttorneyOfPreviousCustomer() {
    return true;
  }
}

function createLifecycle(
  process: string,
  skipProcessSelection: boolean,
  isElectricity: boolean,
  isHousehold: boolean,
  isCrossSell: boolean
): Lifecycle {
  /** Determine lifecycle by process */
  switch (process) {
    case TYPE_PROLONGATION:
      // @ts-ignore
      return new Prolongation(...arguments);

    case TYPE_TRANSFER:
      // @ts-ignore
      return new Transfer(...arguments);

    case TYPE_TRANSFER_NEW:
    case TYPE_TRANSFER_NEW_DEATH:
      // @ts-ignore
      return new TransferNew(...arguments);

    case TYPE_NEW_OM_OWNER_CHANGE:
    case TYPE_NEW_OM_OWNER_CHANGE_DEATH:
      // @ts-ignore
      return new NewOmOwnerChange(...arguments);

    case TYPE_NEW_OM:
    default:
      // @ts-ignore
      return new Aquisition(...arguments);
  }
}

export default createLifecycle;
