import hasOwnProperty from "../helpers/hasOwnProperty";
import validator from "./util/createValidator";

const defaults = {
  nullable: true,
  integer: false,
};

export function isNumberFn(value, settings) {
  if (Number.isNaN(Number(value)) || (typeof value === "string" && value.endsWith("."))) {
    return false;
  } else if (hasOwnProperty(settings, "min") && value < settings.min) {
    return false;
  } else if (hasOwnProperty(settings, "max") && value > settings.max) {
    return false;
  } else if (hasOwnProperty(settings, "gte") && value < settings.gte) {
    // greater than OR equal (alias for min)
    return false;
  } else if (hasOwnProperty(settings, "lte") && value > settings.lte) {
    // lower than OR equal (alias for max)
    return false;
  } else if (hasOwnProperty(settings, "gt") && value <= settings.gt) {
    // greater than
    return false;
  } else if (hasOwnProperty(settings, "lt") && value >= settings.lt) {
    // lower than
    return false;
  }
  if (settings.integer && !Number.isInteger(Number(value))) {
    return false;
  }
  return true;
}

export default validator(isNumberFn, "isNumberFn", defaults);
