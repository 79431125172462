import { BLANK_OPTION_VALUE } from "../components/Select";

/**
 * Returns true if value
 *  - is defined
 *  - is not null
 *  - is not empty string
 *  - is not empty array
 *
 * @param value
 * @return {boolean}
 */
export default function isNotEmpty(value) {
  // This is required for file-upload, because it returns array of files!
  if (Array.isArray(value)) {
    return value.length > 0;
  }

  // Required for phone inputs
  if (value === "+420") {
    return false;
  }

  return (
    value !== undefined && value !== null && value !== "" && value !== 0 && value !== BLANK_OPTION_VALUE && !Number.isNaN(value)
  );
}
