import validator from "./util/createValidator";

const defaults = {
  min: 0,
  nullable: true,
};

export function isLength(value, settings: { min?: number; max?: number }) {
  const { min, max } = settings;

  const surrogatePairs = String(value).match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g) || [];
  const len = value.length - surrogatePairs.length;
  return len >= min && (typeof max === "undefined" || len <= max);
}

export default validator(isLength, "isLength", defaults);
