import moment, { isMoment } from "moment";
import isDate from "./isDate";

export default function dateIsInPast(value) {
  if (!value) return true;
  if (!isDate(value)) return false;

  const date = isMoment(value) ? value : moment(value, "D.M.YYYY", true);
  return date.isSameOrBefore(moment.now());
}
