import React from "react";
import classNames from "classnames";
import Radio from "../Radio";
import styles from "./styles.scss";
import layout from "../UI/styles.scss";
import { Normalizer, Field } from "redux-form";

export interface GroupItem {
  value: string | number;
  label: string | React.ReactNode;
  disabled?: boolean;
}

export interface GroupTheme {
  groupContainer?: string;
  baseContainer?: string;
  label?: string;
}

export interface GroupProps {
  data: GroupItem[];
  label?: React.ReactNode;
  direction?: "vertical" | "horizontal";
  name: string;
  normalize?: Normalizer;
  theme?: GroupTheme;
  onChange?: any;
}

function Group({ data = [], label, normalize, theme = {}, direction = "horizontal", name, onChange }: GroupProps) {
  const groupContainerClassNames = classNames(theme.groupContainer, styles.groupContainer, {
    [styles.groupContainerVertical]: direction === "vertical",
  });

  const baseContainerClassNames = classNames(theme.baseContainer, layout.form_control);

  const labelClassNames = classNames(styles.label, theme.label, { [styles.labelVertical]: direction === "vertical" });

  return (
    <div className={baseContainerClassNames}>
      {label && <div className={labelClassNames}>{label}</div>}
      <div className={groupContainerClassNames}>
        {data.map(child => (
          <Field
            key={child.value}
            type="radio"
            value={child.value}
            onChange={onChange}
            props={{
              disabled: child.disabled,
              theme: { ...styles, ...theme },
              label: child.label,
            }}
            component={Radio}
            name={name}
            normalize={normalize}
          />
        ))}
      </div>
    </div>
  );
}

export default Group;
