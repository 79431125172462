import React, { useRef } from "react";
import { Field, WrappedFieldProps } from "redux-form";
import Help from "../../components/Help";
import theme from "./theme.scss";
import styles from "./styles.scss";
import Title from "../../components/Title";
import Input from "../../components/Input";
import Signature from "../../components/Signature";
import formValidation from "../../validations/formValidation";
import isNotEmpty from "../../validations/isNotEmpty";
import dateFormatter from "../../formatters/date";
import moment from "moment";
import { DATE_FORMAT } from "../../constants";
import locs from "../../localization";
import SignatureCanvas from "react-signature-canvas";
import isValidSize from "../../validations/signature/isValidSize";
import isComplex from "../../validations/signature/isComplex";
import isLong from "../../validations/signature/isLong";

export default function SignatureArea(props: WrappedFieldProps) {
  const { input } = props;
  const name = input.name;
  const canvasRef = useRef<SignatureCanvas>();

  return (
    <div>
      <Title
        value={
          <div style={{ display: "flex" }}>
            {locs("titles.sign_here")} &nbsp;
            <Help>{locs("texts.signature_help")}</Help>
          </div>
        }
        tag="h3"
        className={styles.title}
      />
      <div className={styles.signatureAreaContanier}>
        <div className={styles.locationContainer}>
          <Field
            name={`${name}.location`}
            component={Input}
            type="text"
            props={{
              label: locs("labels.signature_location"),
              theme,
            }}
            validate={[formValidation(isNotEmpty)]}
          />
          <span className={styles.dateText}>
            {locs("labels.signature_day")} <b>{dateFormatter(moment().format(DATE_FORMAT))}</b>
          </span>
        </div>
        <Field
          name={`${name}.image`}
          component={Signature}
          canvasRef={canvasRef}
          validate={[
            formValidation(isNotEmpty, locs("titles.sign_here")),
            formValidation(isValidSize(canvasRef.current), locs("errors.isValidSize")),
            formValidation(isComplex(canvasRef.current), locs("errors.isComplex")),
            formValidation(isLong(canvasRef.current), locs("errors.isComplex")),
          ]}
        />
      </div>
    </div>
  );
}
