import React from "react";
import getValue from "lodash/get";
import locs from "../../localization";
import { PERIOD_MONTH, SEND_INVOICES_EMAIL } from "../../constants";

import paymentInfoFormatter from "../../formatters/paymentInfo";
import { priceFormatter } from "../../helpers";

import Block from "./Block";
import Row from "./Row";
import { getEnumerationData } from "../../reducers/enumerations";
import { PaymentMethod } from "../../constants/interfaces";
import { ENUMERATION_PAYMENT_METHOD } from "../../constants/enumerations";

interface PaymentsInfoProps {
  data: any;
  toTheSpecificPage?: () => void;
  transfer?: boolean;
  enumerations: any;
}

function paymentsInfo({ data, toTheSpecificPage, enumerations, transfer = false }: PaymentsInfoProps) {
  const paymentMethods = getEnumerationData<PaymentMethod>(enumerations, ENUMERATION_PAYMENT_METHOD);

  const paymentsFrequency = locs(`labels.${data.paymentsFrequency === PERIOD_MONTH ? "monthly" : "quarterly"}`);
  const sendInvoicesElectronically = getValue(data, "sendInvoices.sendInvoiceType") === SEND_INVOICES_EMAIL;
  const samePaymentType = getValue(data, "paymentMethods.depositPaymentTypeForAll");

  const deposits = paymentInfoFormatter(paymentMethods, getValue(data, "paymentMethods.deposits"));
  const arrears = samePaymentType ? deposits : paymentInfoFormatter(paymentMethods, getValue(data, "paymentMethods.arrears"));
  const overpayments = samePaymentType
    ? deposits
    : paymentInfoFormatter(paymentMethods, getValue(data, "paymentMethods.overpayments"));

  return (
    <Block title={`${locs("titles.paymentInfo")} ${transfer ? locs("titles.newCustomer") : ""}`} editData={toTheSpecificPage}>
      <Row term={locs("labels.depositAmount")} value={`${priceFormatter(data.depositAmount)} Kč`} bold />
      <Row term={locs("labels.paymentsFrequency")} value={paymentsFrequency} />
      <Row term={locs("labels.deposits")} value={deposits} />
      <Row term={locs("labels.arrears")} value={arrears} />
      <Row term={locs("labels.overpayments")} value={overpayments} />
      <Row term={locs("labels.sendInvoicesElectronically")} value={locs(`labels.${sendInvoicesElectronically ? "yes" : "no"}`)} />
    </Block>
  );
}

export default paymentsInfo;
