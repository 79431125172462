import React from "react";
import styles from "./styles.scss";
import locs from "../../localization";

import Item from "./Item";
import { getLifecycle } from "../../selectors/contract";
import { connect, ConnectedProps } from "react-redux";
import Column from "../../containers/Column";
import { compose } from "redux";
import { Pages } from "../../lifecycles";

function mapStateToProps(state) {
  return {
    lifecycle: getLifecycle(state),
  };
}

/** Selected page is required in props */
export interface ProgressBarProps {
  page: Pages;
}

/** Order of pages one by one */
const FLOW: Pages[] = [
  Pages.ProcessSelect,
  Pages.PreviousCustomer,
  Pages.Contact,
  Pages.Payment,
  Pages.SupplyPoint,
  Pages.Recapitulation,
  Pages.Final,
];

/** Check completed flag for selected and rendered page */
const completedFct = (selectedPage: Pages) => {
  const selectedIndex = FLOW.indexOf(selectedPage);
  return (renderedPage: Pages) => selectedIndex > FLOW.indexOf(renderedPage);
};

/** Bar item to array for rendering options */
interface ProgressBarItem {
  page: Pages;
  label: string;
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const progressBar = ({ page, lifecycle }: ProgressBarProps & PropsFromRedux) => {
  const items: ProgressBarItem[] = [];
  const completed = completedFct(page);

  if (lifecycle.hasDesktopPage(Pages.ProcessSelect)) {
    items.push({
      page: Pages.ProcessSelect,
      label: locs("labels.process_select"),
    });
  }

  if (lifecycle.hasDesktopPage(Pages.PreviousCustomer)) {
    items.push({
      page: Pages.PreviousCustomer,
      label: locs("labels.previous_customer"),
    });
  }

  if (lifecycle.hasDesktopPage(Pages.Contact)) {
    items.push({
      page: Pages.Contact,
      label: locs("labels.contact_info"),
    });
  }

  if (lifecycle.hasDesktopPage(Pages.Payment)) {
    items.push({
      page: Pages.Payment,
      label: locs("labels.payment_info"),
    });
  }

  if (lifecycle.hasDesktopPage(Pages.SupplyPoint)) {
    items.push({
      page: Pages.SupplyPoint,
      label: locs("labels.supply_point"),
    });
  }

  // Show the final step only at the end
  if (page == Pages.Final) {
    items.push({
      page: Pages.Final,
      label: locs("labels.done"),
    });
  }

  return (
    <div className={styles.progress_bar_container}>
      {items.map((item, index) => (
        <Item
          key={`${index}-${item.label}`}
          order={`${index + 1}`}
          completed={completed(item.page)}
          selected={item.page === page}
        >
          {item.label}
        </Item>
      ))}
    </div>
  );
};

export default compose(connector)(progressBar);
