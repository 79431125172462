import { EAN_PREFIX } from "../constants";
import validator from "./util/createValidator";

export const EAN_LENGTH = 18;

const defaults = {
  prefix: EAN_PREFIX,
  nullable: true,
};

export function isEAN(value, settings) {
  if (!value || value === settings.prefix) return true;

  let formattedValue = value ? String(value).replace(/\s/g, "") : "";
  if (formattedValue.indexOf(settings.prefix) === -1) {
    formattedValue = `${settings.prefix}${formattedValue}`;
  }

  if (formattedValue.length === EAN_LENGTH && /^\d+$/.test(formattedValue)) {
    let i: number;
    let index: number;
    let length: number;
    let sum = 0;

    for (index = 0, { length } = formattedValue; index < length; index += 1) {
      i = parseInt(formattedValue[index], 10);
      if (index % 2 === 0) {
        i *= 3;
      }
      sum += i;
    }
    if (sum % 10 === 0) {
      return true;
    }
  }
  return false;
}

export default validator(isEAN, "isEAN", defaults);
