import format from "string-template";

import isGreaterThan from "./isGreaterThan";
import isGreaterOrEqualTo from "./isGreaterOrEqualTo";
import isNotEmpty from "./isNotEmpty";
import isNumber from "./isNumber";
import isDate from "./isDate";
import isBankAccount from "./isBankAccount";
import isEmail from "./isEmail";
import isAdult from "./isAdult";
import isPhoneNumber from "./isPhoneNumber";
import isSipo from "./isSipo";
import isEIC from "./isEIC";
import isEAN from "./isEAN";
import isZip from "./isZip";
import isStreetNumber from "./isStreetNumber";
import isParcelNumber from "./isParcelNumber";
import isHouseNumber from "./isHouseNumber";
import isICO from "./isICO";
import isChecked from "./isChecked";
import isLength from "./isLength";
import dateIsInPast from "./dateIsInPast";

import locs from "../localization";
import isDIC from "./isDIC";

/**
 * Functions are with `.name` but `export validator` are with `.type`
 */
const errors = {
  [isGreaterThan().name]: locs("errors.isGreaterThan"),
  [isNotEmpty.name]: locs("errors.isNotEmpty"),
  [isNumber.type]: locs("errors.isNumber"),
  [isDate.name]: locs("errors.isDate"),
  [dateIsInPast.name]: locs("errors.dateIsInPast"),
  [isBankAccount.name]: locs("errors.isBankAccount"),
  [isEmail.name]: locs("errors.isEmail"),
  [isPhoneNumber.name]: locs("errors.isPhoneNumber"),
  [isSipo.name]: locs("errors.isSipo"),
  [isAdult.name]: locs("errors.isAdult"),
  [isEIC.type]: locs("errors.isEIC"),
  [isEAN.type]: locs("errors.isEAN"),
  [isZip.type]: locs("errors.isZip"),
  [isStreetNumber.type]: locs("errors.isStreetNumber"),
  [isParcelNumber.type]: locs("errors.isParcelNumber"),
  [isHouseNumber.type]: locs("errors.isHouseNumber"),
  [isICO.type]: locs("errors.isICO"),
  [isDIC.type]: locs("errors.isDIC"),
  [isChecked.name]: locs("errors.isNotChecked"),
  [isLength.type]: locs("errors.isLength"),

  default: locs("errors.default"),
};

export default function formValidation(isValidFn, errorMessage?: string) {
  const error = errorMessage || errors[isValidFn.type] || errors[isValidFn.name] || errors.default;
  return function validate(...args) {
    return isValidFn(...args) ? null : format(error, { ...isValidFn });
  };
}

export const NO_MESSAGE = "no_message";
