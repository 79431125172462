import React from "react";

type ContainerProps = {
  hidden?: boolean;
  children?: React.ReactNode;
};

const Container: React.FC<ContainerProps> = ({ hidden = false, children = null }) => {
  return <div style={{ display: hidden ? "none" : "block" }}>{children}</div>;
};

export default Container;
