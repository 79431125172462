import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./styles.scss";

const switchButton = props => {
  const buttonClassNames = classNames(styles.switch_button);
  const { labelOn, labelOff, input } = props;

  const onSwitchHandler = () => {
    input.onChange(!input.value);
  };

  return (
    <div style={{ gridArea: "toggle" }}>
      <input type="hidden" {...input} />
      <div onClick={onSwitchHandler} tabIndex={-1} className={buttonClassNames} role="button">
        {input.value ? labelOn : labelOff}
      </div>
    </div>
  );
};

switchButton.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  labelOn: PropTypes.string,
  labelOff: PropTypes.string,
};

switchButton.defaultProps = {
  labelOn: null,
  labelOff: null,
};

export default switchButton;
