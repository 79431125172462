import React, { PureComponent } from "react";
import { connect, ConnectedProps } from "react-redux";
import getValue from "lodash/get";
import locs from "../../../../localization";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import { formatEnumerationData } from "../../../../helpers";

import styles from "../styles.scss";

import formValidation from "../../../../validations/formValidation";
import isNotEmpty from "../../../../validations/isNotEmpty";
import isNumber from "../../../../validations/isNumber";
import isGreaterThan from "../../../../validations/isGreaterThan";
import isLength from "../../../../validations/isLength";
import { getTariffs } from "../../../../selectors/enumerations";
import { Field, InjectedFormProps, WrappedFieldProps } from "redux-form";
import Column from "../../../../containers/Column";
import { compose } from "redux";
import { CircuitBreaker, Tariff } from "../../../../constants/interfaces";
import { CalculationElectricityDetail, SupplyPointConsumption } from "../../../../reducers/types";
import isGreaterOrEqualTo from "../../../../validations/isGreaterOrEqualTo";

interface ConsumptionProps {
  theme?: any;
  doNotGroup?: boolean;
}

const mapStateToProps = state => ({
  tariffs: getTariffs(state),
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const updateTariffAndCircuitBreakers = ({
  values,
  dispatch,
  props,
  tariffs,
  circuitBreakers,
  previousValues,
  path,
}: {
  values?: CalculationElectricityDetail | SupplyPointConsumption;
  previousValues?: CalculationElectricityDetail | SupplyPointConsumption;
  dispatch;
  props: InjectedFormProps;
  tariffs: Tariff[];
  circuitBreakers: CircuitBreaker[];
  path: string;
}) => {
  // When tariff is changed, check proper allowed values and reset consumptions (if no lower is available)
  const { tariffId, circuitBreakerPhase, circuitBreakerValue, consumptionLow, circuitBreakerId } = values ?? {};

  const tariff = tariffs.find(x => x.id === tariffId);

  if (!tariff) {
    dispatch(props.change(`${path}.tariffId`, null));
    dispatch(props.change(`${path}.circuitBreakerId`, null));
  }

  if (tariff && consumptionLow && !tariff.secondaryTariff) {
    dispatch(props.change(`${path}.consumptionLow`, null));
  }

  // If tariff / circuit breaker changes - find circuit breaker and set it
  if (
    tariff &&
    circuitBreakerPhase &&
    circuitBreakerValue &&
    (tariffId !== previousValues?.tariffId ||
      circuitBreakerPhase !== previousValues?.circuitBreakerPhase ||
      circuitBreakerValue !== previousValues?.circuitBreakerValue)
  ) {
    // Find allowed circuit breakers from tariff (there are just IDs)
    // Map and filter them from circuit breakers array
    const allowedCircuitBreakers = circuitBreakers.filter(
      x => x.phase === circuitBreakerPhase && tariff.circuitBreakers.indexOf(x.id) >= 0
    );

    // Check if we could find a circuit breaker in value range
    const value = parseInt(circuitBreakerValue, 10);
    const circuitBreaker = allowedCircuitBreakers.find(x => x.valueFrom < value && value <= x.valueTo);

    if (circuitBreaker && circuitBreakerId !== circuitBreaker.id) {
      dispatch(props.change(`${path}.circuitBreakerId`, circuitBreaker.id));
    }
  }
};

class Consumption extends PureComponent<WrappedFieldProps & PropsFromRedux & ConsumptionProps> {
  static defaultProps = {
    theme: {},
    doNotGroup: false,
  };

  getTariff = (tariffId = this.props.input.value.tariffId) => this.props.tariffs.find(t => t.id === tariffId);

  hasLowTariff = (tariffId?: number) => getValue(this.getTariff(tariffId), "secondaryTariff", false);

  renderTariffIdInput = () => (
    <Field
      name={`${this.props.input.name}.tariffId`}
      component={Select}
      normalize={Number}
      validate={[formValidation(isNotEmpty)]}
      type="select"
      props={{
        data: formatEnumerationData(this.props.tariffs),
        theme: this.props.theme,
        showBlankOption: true,
        label: locs("labels.distribution_rate"),
        suffix: locs("labels.mwh_year"),
      }}
    />
  );

  renderConsumptionHighInput = () => (
    <Field
      name={`${this.props.input.name}.consumptionHigh`}
      component={Input}
      type="number"
      validate={[
        formValidation(isNumber),
        formValidation(isNotEmpty),
        formValidation(isLength({ max: 6 })),
        formValidation(isGreaterOrEqualTo(0.01), locs("errors.isGreaterOrEqualTo")),
      ]}
      props={{
        theme: this.props.theme,
        label: locs("labels.high_tariff"),
        suffix: locs("labels.mwh_year"),
      }}
    />
  );

  renderConsumptionLowInput = () => (
    <Field
      name={`${this.props.input.name}.consumptionLow`}
      component={Input}
      type="number"
      validate={[
        formValidation(isNotEmpty),
        formValidation(isLength({ max: 6 })),
        formValidation(isNumber),
        formValidation(isGreaterOrEqualTo(0.01), locs("errors.isGreaterOrEqualTo")),
      ]}
      props={{
        theme: this.props.theme,
        label: locs("labels.low_tariff"),
        suffix: locs("labels.mwh_year"),
      }}
    />
  );

  renderConsumptionSuffix = () => <span className={styles.row_suffix}>{locs("labels.mwh_year")}</span>;

  renderCircuitBreakerPhaseInput = () => (
    <Field
      name={`${this.props.input.name}.circuitBreakerPhase`}
      component={Select}
      validate={[formValidation(isNumber({ gt: 0 }), locs("errors.isNotEmpty")), formValidation(isNotEmpty)]}
      normalize={Number}
      type="select"
      props={{
        theme: this.props.theme,
        label: locs("labels.phases"),
        suffix: locs("labels.mwh_year"),
        showBlankOption: true,
        data: [
          { label: 1, value: 1 },
          { label: 3, value: 3 },
        ],
      }}
    />
  );

  renderCircuitBreakerValueInput = () => (
    <Field
      name={`${this.props.input.name}.circuitBreakerValue`}
      component={Input}
      validate={[
        formValidation(isNotEmpty),
        formValidation(isLength({ max: 6 })),
        formValidation(isNumber),
        formValidation(isGreaterThan(0)),
      ]}
      type="number"
      props={{
        theme: this.props.theme,
        label: locs("labels.reserved_powers"),
        suffix: locs("labels.a"),
      }}
    />
  );

  render() {
    const { doNotGroup } = this.props;

    return (
      <div>
        <Column>{this.renderTariffIdInput()}</Column>
        <Column> {this.renderConsumptionHighInput()} </Column>
        {this.hasLowTariff() && <Column>{this.renderConsumptionLowInput()}</Column>}

        <Column>
          {this.renderCircuitBreakerPhaseInput()}
          {!doNotGroup && this.renderCircuitBreakerValueInput()}
        </Column>

        {doNotGroup && <Column>{this.renderCircuitBreakerValueInput()}</Column>}
      </div>
    );
  }
}

export default compose(connector)(Consumption);
