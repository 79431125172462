import React from "react";

import locs from "../../localization";
import Person from "./Person";
import Addresses from "./Addresses";

import styles from "./styles.scss";
import OnBehalf from "./OnBehalf";
import { Commodity, TitlePrefix, TitleSuffix } from "../../constants/interfaces";
import { Field, WrappedFieldProps } from "redux-form";

interface HouseholdProps {
  titlePrefixes: TitlePrefix[];
  titleSuffixes: TitleSuffix[];
  signed: boolean;
  transfer?: boolean;
  newOmOwnerChange?: boolean;
  commodity?: Commodity;
}

function Household({
  titlePrefixes,
  titleSuffixes,
  signed = false,
  transfer = false,
  commodity,
  input: { name },
}: HouseholdProps & WrappedFieldProps) {
  return (
    <div className={styles.contact_container}>
      <Field
        name={`${name}.person`}
        component={Person}
        props={{
          titlePrefixes,
          titleSuffixes,
        }}
      />
      <Field
        name={`${name}.transfer`}
        component={OnBehalf}
        props={{
          signed,
          // In process of transfer or owner change we must show on behalf
          transfer: transfer,
          commodity,
        }}
      />
      <Field
        name={`${name}.addresses`}
        component={Addresses}
        props={{
          deliveryAddressIsSameLabel: locs("labels.person_delivery_address_is_same"),
        }}
      />
    </div>
  );
}

export default Household;
