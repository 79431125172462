import getValue from "lodash/get";
import {
  USER_GET_DATA_FAILURE,
  USER_GET_DATA_REQUEST,
  USER_GET_DATA_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_SUCCESS,
} from "../constants/actions";
import { DATA_FAILURE, DATA_REQUEST, DATA_SUCCESS } from "../constants/async";
import { AUTH_TYPE_LOGIN, AUTH_TYPE_TOKEN, NEW, TYPE_NEW_OM } from "../constants";
import { UserState } from "./types";

export const initialState: UserState = {
  status: DATA_SUCCESS,
  waitForData: false,
  partners: [],
};

export const transformUserDataToContract = payload => {
  if (!payload.formData) return {};

  return {
    contact: payload.formData.contact,
    payments: payload.formData.payments,
    partnerValue: payload.formData.partnerValue,
    skipProcessSelection: payload.formData.skipProcessSelection,
    supplyPoint: payload.formData.supplyPoint,
    distributionZoneValue: payload.formData.distributionZoneValue,
    commodityValue: payload.formData.commodityValue,
    supplyPointValue: getValue(
      payload.formData.supplyPoint,
      ["data", "code"],
      // user sent from PPAS without supply point
      // automatically creates new one
      payload.authType === AUTH_TYPE_TOKEN ? NEW : null
    ),
    transfer: payload.formData.transfer,
    process: payload.formData.process || TYPE_NEW_OM,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        status: DATA_REQUEST,
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        status: DATA_SUCCESS,
        authType: AUTH_TYPE_LOGIN,
        login: action.payload.login || action.login,
        name: action.payload.name,
        partners: action.payload.partners,
        error: null,
      };
    case USER_LOGIN_FAILURE:
      return {
        ...state,
        status: DATA_FAILURE,
        error: action.payload,
      };
    case USER_GET_DATA_REQUEST:
      return {
        ...state,
        status: DATA_REQUEST,
        waitForData: Boolean(action.waitForData),
      };
    case USER_GET_DATA_SUCCESS:
      return {
        ...state,
        status: DATA_SUCCESS,
        waitForData: false,
        authType: action.payload.authType,
        login: action.payload.login || action.login,
        name: action.payload.name,
        formData: transformUserDataToContract(action.payload),
        partners: action.payload.partners || [],
      };
    case USER_GET_DATA_FAILURE:
      // Session does not exists
      return {
        ...state,
        status: DATA_SUCCESS,
        waitForData: false,
      };
    case USER_LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
