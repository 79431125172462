import { PAYMENT_METHOD_BANK_TRANSFER, PAYMENT_METHOD_INKASO, PAYMENT_METHOD_SIPO } from "../constants";
import { PaymentMethod } from "../constants/interfaces";

export interface PaymentInfo {
  paymentType?: string;
  bankAccount?: {
    prefix?: string;
    number?: string;
    bankCode?: string;
  };
  sipo?: string;
}

export default function paymentInfo(paymentMethods: PaymentMethod[], value: PaymentInfo = {}) {
  let number = "";
  switch (value.paymentType) {
    case PAYMENT_METHOD_BANK_TRANSFER:
    case PAYMENT_METHOD_INKASO: {
      const { bankAccount } = value;
      number = bankAccount ? `${bankAccount.prefix || 0}-${bankAccount.number || 0}/${bankAccount.bankCode || "XXXX"}` : "";
      break;
    }
    case PAYMENT_METHOD_SIPO: {
      number = value.sipo || "";
      break;
    }
  }

  // Search type in payment methods and use translation as label
  const paymentMethod = paymentMethods.find(x => x.value === value.paymentType);
  return `${paymentMethod?.name}\n${number}`;
}
