/**
 * @param value
 * @return {boolean}
 */
export default function isGasUsageNotEmpty(value) {
  // Array of gas usage is always undefined or [undefined] or [{ applianceId: "" }] from beginning
  if (!Array.isArray(value)) {
    return false;
  }

  return value.filter(x => x && x["applianceId"]).length > 0;
}
