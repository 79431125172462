export const ENUMERATION_APPLIANCE = "appliances";
export const ENUMERATION_BANK = "banks";
export const ENUMERATION_CATEGORY = "categories";
export const ENUMERATION_CIRCUIT_BREAKER = "circuit_breakers";
export const ENUMERATION_COMMODITY = "commodities";
export const ENUMERATION_ESTIMATE = "estimates";
export const ENUMERATION_PARTNER = "partners";
export const ENUMERATION_PRODUCT = "products";
export const ENUMERATION_SUPPLIER = "suppliers";
export const ENUMERATION_TARIFF = "tariffs";
export const ENUMERATION_TITLE_PREFIX = "title_prefixes";
export const ENUMERATION_TITLE_SUFFIX = "title_suffixes";
export const ENUMERATION_POSITIONS = "positions";
export const ENUMERATION_INSTALLATION_FLOOR = "installation_floors";
export const ENUMERATION_PAYMENT_METHOD = "payment_methods";
export const ENUMERATION_SETTINGS = "settings";
