import { batch } from "react-redux";
import { change, untouch } from "redux-form";
import { SuggestionProps } from "../composites/Address/Suggestion";
import { toString } from "lodash";

export function applyRuianData(suggestion: SuggestionProps, addressCode: string) {
  return dispatch => {
    const getValue = key => {
      if (!suggestion.isGrouped) {
        // set all fields if not grouped
        return suggestion[key];
      } else if (suggestion.groupBy && suggestion.groupBy.includes(key)) {
        // if grouped, set only the field that is grouped
        return suggestion[key];
      }
      // clear non-grouped fields
      return "";
    };

    const applyChange = (key: string, fallbackValueKey?: string) => {
      let value = getValue(key) || getValue(fallbackValueKey);
      if (key === "street") {
        const appendValue = getValue("registrationNumber");
        if (appendValue) {
          value += ` č.ev. ${appendValue}`;
        }
      }
      dispatch(change("calculator", `${addressCode}.${key}`, toString(value), true));
      if (!value) dispatch(untouch("calculator", `${addressCode}.${key}`));
    };

    batch(() => {
      applyChange("street", "cityPart");
      applyChange("houseNumber");
      applyChange("streetNumber");
      applyChange("registrationNumber");
      applyChange("city");
      applyChange("zip");
      applyChange("ruianCode");
    });
  };
}

export function clearAddress(addressCode: string) {
  return dispatch => {
    batch(() => {
      const fields = ["street", "houseNumber", "streetNumber", "registrationNumber", "city", "zip", "ruianCode"];
      fields.forEach(key => {
        dispatch(change("calculator", `${addressCode}.${key}`, ""));
        dispatch(untouch("calculator", `${addressCode}.${key}`));
      });
    });
  };
}
