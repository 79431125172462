import validator from "./util/createValidator";

const defaults = {
  expression: /^\d*\/?\d+$/,
  nullable: true,
};

export function isParcelNumber(value, settings) {
  const formattedValue = String(value);
  return formattedValue.charAt(0) !== "/" && settings.expression.test(formattedValue);
}

export default validator(isParcelNumber, "isParcelNumber", defaults);
