import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import locs from "../../../localization";
import TooltipContainer from "../../../containers/TooltipContainer";

import styles from "./styles.scss";

export default function Region(props) {
  const { id, transform, children, selectedRegion, onMapClick, align } = props;

  return (
    <TooltipContainer
      overlay={<span className={styles.label}>{locs(`regions.${id}`)}</span>}
      placement="top"
      mouseEnterDelay={0.15}
      align={align}
      type="map">
      <g
        id={id}
        transform={transform}
        className={classNames(selectedRegion === id && styles.map_selected)}
        onClick={() => onMapClick(id)}>
        {children}
      </g>
    </TooltipContainer>
  );
}

Region.propTypes = {
  id: PropTypes.number.isRequired,
  transform: PropTypes.string,
  children: PropTypes.element,
  selectedRegion: PropTypes.number,
  onMapClick: PropTypes.func,
  align: PropTypes.shape({}),
};

Region.defaultProps = {
  transform: null,
  children: null,
  selectedRegion: null,
  onMapClick: null,
  align: { offset: [-4, 10] },
};
