import React from "react";
import { COMMODITY_ELECTRICITY, COMMODITY_GAS } from "../../../constants";
import locs from "../../../localization";

import Group, { GroupProps } from "../../../components/Group";

interface CommodityTypeProps extends Omit<Omit<GroupProps, "name">, "data"> {
  name?: string;
  disabled?: boolean;
}

export default function CommodityType(props: CommodityTypeProps) {
  const { name = "commodityValue", disabled = false, ...rest } = props;

  return (
    <Group
      name={name}
      data={[
        { label: locs("labels.gas"), value: COMMODITY_GAS, disabled },
        { label: locs("labels.electricity"), value: COMMODITY_ELECTRICITY, disabled },
      ]}
      {...rest}
    />
  );
}
