import React, { PureComponent } from "react";
import classNames from "classnames";
import styles from "./styles.scss";

interface DialogProps extends React.HTMLProps<HTMLDivElement> {
  theme?: {
    popup?: string;
  };
  hidden?: boolean;
  closable?: boolean;
  onClose?: () => void;
}

interface DialogState {
  hidden: boolean;
}

export default class Dialog extends PureComponent<DialogProps, DialogState> {
  static defaultProps = {
    hidden: false,
    closable: true,
    theme: {},
  };

  constructor(props) {
    super(props);
    this.state = { hidden: props.hidden };
    this.setBodyClass();
  }

  componentWillReceiveProps(nextProps) {
    const { hidden } = this.props;
    const { hidden: nextHidden } = nextProps;
    if (hidden !== nextHidden) {
      this.setState(
        {
          hidden: nextHidden,
        },
        this.setBodyClass
      );
    }
  }

  componentWillUnmount() {
    this.setBodyClass(true);
  }

  setBodyClass = (hidden = this.state.hidden) => {
    const bodyClass = document.body.className;
    const clsOpened = styles.body_popup_opened;
    document.body.className = hidden ? bodyClass.replace(clsOpened, "").trim() : `${bodyClass} ${clsOpened}`;
  };

  handleClose = e => {
    if (!this.props.closable) return;

    e.preventDefault();
    this.setState({ hidden: true });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  renderCloseIcon = () =>
    this.props.closable && (
      <button className={styles.close_button} onClick={this.handleClose} onKeyPress={this.handleClose} type="button">
        ×
      </button>
    );

  render() {
    const { theme, children } = this.props;
    const { hidden } = this.state;

    if (hidden) return null;

    return (
      <div className={styles.overlay} onClick={this.handleClose}>
        <div className={classNames(styles.popup, theme.popup)} onClick={e => e.stopPropagation()}>
          {this.renderCloseIcon()}
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    );
  }
}
