import validator from "./util/createValidator";

const defaults = {
  nullable: true,
};

export function isICO(value) {
  let a = 0;
  if (value.length !== 8) {
    return false;
  }
  const b = value.split("");
  for (let i = 0; i < 7; i += 1) {
    a += parseInt(b[i], 10) * (8 - i);
  }
  a %= 11;
  let c = 11 - a;
  if (a === 1) {
    c = 0;
  } else if (a === 0 || a === 10) {
    c = 1;
  }
  return parseInt(b[7], 10) === c;
}

export default validator(isICO, "isICO", defaults);
