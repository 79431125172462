import isObject from "./isObject";

/**
 * Higher-order function that wraps core simple validator
 *
 * @param {Validate} validate
 * @param {Object} [defaults]
 * @returns {Validator & {type}}
 */
export default function createValidator(
  validate: (value: any, options: any) => string | boolean | null,
  type: string,
  defaults = {}
) {
  function configureValidator(settings) {
    /**
     * @function
     * @type {Validator}
     *
     * @param {string|Object} valueOrOptions
     * @param {Object} [maybeOptions]
     * @returns {Validator|boolean}
     */
    function validator(valueOrOptions, maybeOptions) {
      let options = settings;

      if (isObject(valueOrOptions)) {
        return configureValidator(Object.assign({}, settings, valueOrOptions));
      } else if (isObject(maybeOptions)) {
        options = Object.assign({}, options, maybeOptions);
      }

      let value = valueOrOptions;
      if (value === undefined || value === null) return options.nullable;

      if (options.trimWhiteSpaces && typeof value === "string") {
        value = value.trim();
      }

      if (value === "") return options.nullable;
      return validate(value, options);
    }

    return Object.assign(
      validator,
      /** Append all settings as properties */
      settings,
      /** Append name of validate function as property `type` */
      { type }
    );
  }

  return configureValidator(Object.assign({ trimWhiteSpaces: true, nullable: false }, defaults));
}
