import React from "react";
import styles from "./styles.scss";
import Logo from "../../../img/logo-mono.svg";
import Phone from "../../../img/ic-phone.svg";
import Email from "../../../img/ic-mail.svg";
import { COMPANY_NAME, PPAS_EMAIL, PPAS_PHONE, PPAS_PHONE_LINK } from "../../constants";
import locs from "../../localization";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.leftBox}>
        <Logo alt={COMPANY_NAME} className={styles.logo} />

        <div className={styles.copyright}>{locs("texts.copyright")}</div>
      </div>
      <div className={styles.rightBox}>
        <div>
          <Phone />
          &nbsp;<a href={"tel:" + PPAS_PHONE_LINK}>{PPAS_PHONE}</a>
        </div>
        <div>
          <Email />
          &nbsp;<a href={"mailto:" + PPAS_EMAIL}>{PPAS_EMAIL}</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
