import validator from "./util/createValidator";

const defaults = {
  expression: /^(\d{5})$/,
  nullable: true,
};

export function isZip(value, settings) {
  const formattedValue = String(value).replace(" ", "");
  return settings.expression.test(formattedValue);
}

export default validator(isZip, "isZip", defaults);
