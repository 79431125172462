import React from "react";
import locs from "../../localization";

import Column from "../../containers/Column";
import Title from "../../components/Title";
import Button from "../../components/Button";
import styles from "../../forms/Desktop/styles.scss";

import File from "../../components/File";
import FixedTooltip from "../../components/FixedTooltip";
import { UPLOAD_ATTACHMENTS_PATH } from "../../api";
import formValidation from "../../validations/formValidation";
import isNotEmpty from "../../validations/isNotEmpty";
import { CEZ_REGIONS, EGD_REGIONS, MEGABYTE, PRE_REGIONS } from "../../constants";
import { Field, WrappedFieldProps } from "redux-form";
import classNames from "classnames";
import { Lifecycle } from "../../lifecycles";

interface TransferDocumentsProps {
  isElectricity: boolean;
  regionRuianCode?: number;
  distributionZone?: string;
  lifecycle: Lifecycle;
}

export default function TransferDocuments({
  isElectricity,
  regionRuianCode,
  distributionZone,
  lifecycle,
  input: { name },
}: TransferDocumentsProps & WrappedFieldProps) {
  let files = [];
  const death = lifecycle.death;

  // DOKLAD O VZTAHU K NEMOVITOSTI
  if (!death) {
    files.push(
      <Column key="header_property_relation">
        <Title name={locs("titles.transferDocumentsNew")} tag="h3" />
      </Column>,
      <div className={styles.file} key="file_property_relation" id="property_relation_dropzone">
        <FixedTooltip position="top" theme={{ tooltipContainer: styles.tooltipContainerTransfer }}>
          <div
            className={styles.inkasoTooltipContent}
            dangerouslySetInnerHTML={{ __html: locs(`texts.transferDocument${lifecycle.transfer ? "s" : ""}`) }}
          />
        </FixedTooltip>
        <Field
          name={`${name}.propertyRelation`}
          component={File}
          fixedName={true}
          type="file"
          validate={[formValidation(isNotEmpty, locs("errors.fileIsRequired"))]}
          props={{
            dropzoneElementId: "property_relation_dropzone",
            endPoints: { request: UPLOAD_ATTACHMENTS_PATH },
            theme: {
              filesContainer: styles.tooltipFileContainer,
              dropzoneWrapper: styles.tooltipFileContainer,
              dropzoneElement: styles.tooltipFileContainer,
              dropzoneOverlay: styles.dropzoneOverlay,
            },
            showDropzone: true,
            sizeLimit: 6 * MEGABYTE,
          }}
        />
      </div>,
      <div className={styles.spacer} key="property_spacer" />
    );
  }

  // DOKLAD O ÚMRTÍ
  if (death) {
    files.push(
      <Column key="header_death_certificate">
        <Title name={locs("titles.deathCertificate")} tag="h3" />
      </Column>,
      <div className={styles.file} key="file_death_certificate" id="death_certificate_dropzone">
        <FixedTooltip position="top" theme={{ tooltipContainer: styles.tooltipContainerTransfer }}>
          <div className={styles.inkasoTooltipContent} dangerouslySetInnerHTML={{ __html: locs(`texts.deathCertificate`) }} />
        </FixedTooltip>
        <Field
          name={`${name}.deathCertificate`}
          component={File}
          fixedName={true}
          type="file"
          validate={[formValidation(isNotEmpty, locs("errors.fileIsRequired"))]}
          props={{
            dropzoneElementId: "death_certificate_dropzone",
            endPoints: { request: UPLOAD_ATTACHMENTS_PATH },
            theme: {
              filesContainer: styles.tooltipFileContainer,
              dropzoneWrapper: styles.tooltipFileContainer,
              dropzoneElement: styles.tooltipFileContainer,
              dropzoneOverlay: styles.dropzoneOverlay,
            },
            showDropzone: true,
          }}
        />
      </div>
    );
  }

  // PODKLADY PRO PROVOZOVATELE DISTRIBUČNÍ SOUSTAVY
  if (regionRuianCode || distributionZone) {
    let file: string;
    if (isElectricity) {
      // CEZ
      if (CEZ_REGIONS.indexOf(regionRuianCode) >= 0 || distributionZone === "CE") {
        file = "https://www.ppas.cz/sites/default/files/faq/dokumenty/plna_moc_dodavatel_01072018_web.pdf";
      }

      if (PRE_REGIONS.indexOf(regionRuianCode) >= 0 || distributionZone === "PR") {
        file =
          "https://www.ppas.cz/sites/default/files/faq/dokumenty/%C5%BD%C3%A1dost%20o%20p%C5%99ipojen%C3%AD%20na%20%20hladin%C4%9B%20NN.pdf";
      }

      if (EGD_REGIONS.indexOf(regionRuianCode) >= 0 || distributionZone === "EO") {
        file = "https://www.egd.cz/sites/default/files/2023-09/d55.pdf";
      }
      // isGas
    } else {
      if (EGD_REGIONS.indexOf(regionRuianCode) >= 0 || distributionZone === "EON") {
        file = "https://www.egd.cz/sites/default/files/2023-09/d55.pdf";
      }
    }

    if (file) {
      files.push(
        <div className={styles.spacer} key="custom_file_spacer_operator_document" />,
        <Column key="header_operator_document">
          <Title name={locs("titles.operatorDocument")} tag="h3" />
        </Column>,
        <Button key="downloadOperatorDocument" className={styles.secondaryButton} onClick={() => window.open(file)}>
          {locs("labels.downloadTemplate")}
        </Button>,
        <div className={styles.file} key="file_operator_document" id="operator_document_dropzone">
          <Field
            name={`${name}.operatorDocument`}
            component={File}
            fixedName={true}
            type="file"
            validate={[formValidation(isNotEmpty, locs("errors.fileIsRequired"))]}
            props={{
              dropzoneElementId: "operator_document_dropzone",
              endPoints: { request: UPLOAD_ATTACHMENTS_PATH },
              theme: {
                filesContainer: styles.tooltipFileContainer,
                dropzoneWrapper: styles.tooltipFileContainer,
                dropzoneElement: styles.tooltipFileContainer,
                dropzoneOverlay: styles.dropzoneOverlay,
              },
              showDropzone: true,
              sizeLimit: 6 * MEGABYTE,
            }}
          />
        </div>
      );
    }
  }

  // PLNÁ MOC PŮVODNÍHO ZÁKAZNÍKA
  if (lifecycle.showPowerOfAttorneyOfPreviousCustomer && !death) {
    files.push(
      <div className={styles.spacer} key="custom_file_spacer_poa_previous" />,
      <Column key="header_power_of_attorney_previous">
        <Title name={locs("titles.powerOfAttorneyPrevious")} tag="h3" />
      </Column>,
      <Button
        key="downloadPowerOfAttorneyPrevious"
        className={styles.secondaryButton}
        onClick={() => window.open("pdf/power_of_attorney.pdf")}
      >
        {locs("labels.downloadTemplatePowerOfAttorneyPrevious")}
      </Button>,
      <div className={styles.file} key="file_power_of_attorney_previous" id="power_of_attorney_previous_dropzone">
        <Field
          name={`${name}.powerOfAttorneyPreviousCustomer`}
          component={File}
          fixedName={true}
          type="file"
          validate={[formValidation(isNotEmpty, locs("errors.fileIsRequired"))]}
          props={{
            dropzoneElementId: "power_of_attorney_previous_dropzone",
            endPoints: { request: UPLOAD_ATTACHMENTS_PATH },
            theme: {
              filesContainer: styles.tooltipFileContainer,
              dropzoneWrapper: styles.tooltipFileContainer,
              dropzoneElement: styles.tooltipFileContainer,
              dropzoneOverlay: styles.dropzoneOverlay,
            },
            showDropzone: true,
          }}
        />
      </div>
    );
  }

  // ŽÁDOST O UKONČENÍ SMLOUVY
  if (lifecycle.showTerminationRequestDocument) {
    files.push(
      <Column key="header_contract_termination_request">
        <Title name={locs(`titles.contractTerminationRequest${isElectricity ? "Power" : "Gas"}`)} tag="h3" />
      </Column>,
      <FixedTooltip
        position="top"
        theme={{ tooltipContainer: styles.tooltipContainerTransfer }}
        key="tooltip_contract_termination"
      >
        <div
          className={styles.inkasoTooltipContent}
          dangerouslySetInnerHTML={{ __html: locs(`texts.contractTermination${!death ? "" : "Death"}Document`) }}
        />
      </FixedTooltip>,
      <div className={styles.buttonContainer}>
        <Button
          key="header_contract_termination"
          className={styles.secondaryButton}
          onClick={() => window.open(`pdf/${isElectricity ? "power" : "gas"}_termination.pdf`)}
        >
          {locs("labels.downloadTemplateContractTermination")}
        </Button>
        {!death && (
          <Button
            key="header_contract_termination_announce"
            className={classNames(styles.secondaryButton, styles.marginLeft)}
            onClick={() => window.open(`pdf/${isElectricity ? "power" : "gas"}_termination_announce.pdf`)}
          >
            {locs("labels.downloadTemplateContractTerminationAnnounce")}
          </Button>
        )}
      </div>,
      <div className={styles.file} key="file_contract_termination_request" id="contract_termination_dropzone">
        <Field
          name={`${name}.contractTerminationRequest`}
          component={File}
          fixedName={true}
          type="file"
          validate={[formValidation(isNotEmpty, locs("errors.fileIsRequired"))]}
          props={{
            dropzoneElementId: "contract_termination_dropzone",
            endPoints: { request: UPLOAD_ATTACHMENTS_PATH },
            theme: {
              filesContainer: styles.tooltipFileContainer,
              dropzoneWrapper: styles.tooltipFileContainer,
              dropzoneElement: styles.tooltipFileContainer,
              dropzoneOverlay: styles.dropzoneOverlay,
            },
            showDropzone: true,
          }}
        />
      </div>
    );
  }

  return <>{files}</>;
}
