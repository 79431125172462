import React from "react";

import { Field, InjectedFormProps, reduxForm } from "redux-form";
import locs from "../../../localization";

import Button from "../../../components/Button";
import Select from "../../../components/Select";

import isNotEmpty from "../../../validations/isNotEmpty";
import formValidation, { NO_MESSAGE } from "../../../validations/formValidation";

import styles from "./styles.scss";

import { PartnerSessionItem } from "../../../reducers/types";
import { compose } from "redux";

interface ChoosePartnerProps {
  partners: PartnerSessionItem[];
  electricity: boolean;
  gas: boolean;
  onSubmit: (data: any) => void;
}

const ChoosePartner = ({ electricity, gas, partners, change, handleSubmit }: ChoosePartnerProps & InjectedFormProps) => {
  let title;

  if (electricity) {
    title = locs("titles.where_do_you_want_to_buy_el");
  } else if (gas) {
    title = locs("titles.where_do_you_want_to_buy_gs");
  }

  const changePartner = (e: any, value: string) => {
    const partner = partners.find(x => x.externalId === value);

    if (partner) {
      change("partnerType", partner.partnerType);
    }
  };

  return (
    <div className={styles.choose_sp_container}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.choose_sp_content}>
        <Field
          name="partnerId"
          component={Select}
          validate={[formValidation(isNotEmpty, NO_MESSAGE)]}
          props={{
            data: partners.map(code => ({ label: `${code.name} ${code.externalId}`, value: code.externalId })),
            blankOption: locs("placeholders.select_partner"),
            showBlankOption: true,
          }}
          onChange={changePartner}
        />
        <Button onClick={handleSubmit}>{locs("actions.continue")}</Button>
        {/*  <Separator>{locs('labels.or')}</Separator> */}
        {/*  <Link label={locs('actions.add_sp')} onClick={props.onNewSupplyPoint} /> */}
      </div>
    </div>
  );
};

export default compose(
  reduxForm({
    form: "signIn",
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })
)(ChoosePartner) as unknown as React.ComponentClass<ChoosePartnerProps>;
