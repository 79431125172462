import { EAN_PREFIX } from "../constants";
import { EAN_LENGTH } from "../validations/isEAN";

export default function eic(value: string | undefined) {
  let formattedValue = value ? String(value).replace(/\s/g, "") : "";

  if (formattedValue.indexOf(EAN_PREFIX) === 0) {
    formattedValue = formattedValue.slice(EAN_PREFIX.length);
  }
  return formattedValue.substring(0, EAN_LENGTH - EAN_PREFIX.length).toUpperCase();
}
