import validator from "./util/createValidator";

interface StreetNumberValidatorSettings {
  expression: RegExp;
  nullable: boolean;
}

const defaults: StreetNumberValidatorSettings = {
  expression: /^\d+[a-zA-Z]?$/,
  nullable: true,
};

export function isStreetNumber(value: string, settings: StreetNumberValidatorSettings) {
  return settings.expression.test(value);
}

export default validator(isStreetNumber, "isStreetNumber", defaults);
