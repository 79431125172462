import { RESET_VISITED_ROUTES, VISIT_ROUTE } from "../actions/routing";

export const initialState = {};

export default (state = initialState, action) => {
  if (action.type === VISIT_ROUTE && !state[action.payload]) {
    return {
      ...state,
      [action.payload]: true,
    };
  }

  if (action.type === RESET_VISITED_ROUTES) {
    return initialState;
  }

  return state;
};
