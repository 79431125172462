import { createSelector } from "reselect";
import { getFormValues } from "redux-form";
import merge from "../helpers/merge";
import {
  COMMODITY_ELECTRICITY,
  COMMODITY_GAS,
  NEW,
  PARTNER_COMPANY,
  PARTNER_HOUSEHOLD,
  TYPE_TRANSFER,
  TYPE_PROLONGATION,
  TYPE_TRANSFER_NEW,
  TYPE_TRANSFER_NEW_DEATH,
  TYPE_NEW_OM_OWNER_CHANGE,
  TYPE_NEW_OM_OWNER_CHANGE_DEATH,
} from "../constants";
import { getFormData } from "./user";
import { ENUMERATION_COMMODITY, ENUMERATION_ESTIMATE, ENUMERATION_PARTNER } from "../constants/enumerations";
import { getEnumerationRecordById, getEnumerationRecordByValue } from "./enumerations";
import { Commodity, Estimate, Partner } from "../constants/interfaces";
import { Calculation, CalculatorValues, SupplyPointConsumption, SupplyPointData } from "../reducers/types";
import createLifecycle from "../lifecycles";

const formValuesSelector = getFormValues("calculator");

export const getInitialValues = createSelector(
  state => state.contract,
  getFormData,
  (contract, userFormData) => merge(contract, userFormData)
);

export const isInitialValuesReady = state => state.contract !== null;

export const getContractValues = createSelector(
  formValuesSelector,
  getInitialValues,
  (values, initialValues) => (values || initialValues) as CalculatorValues
);

/**
 * Base values selectors
 */

export const getCommodityValue = createSelector(getContractValues, values => values.commodityValue);

export const getPartnerValue = createSelector(getContractValues, values => values.partnerValue);

export const getCalculate = createSelector(getContractValues, values => values.calculate);

export const getProduct = createSelector(getContractValues, values => values.product);

export const getSupplyPointData = createSelector(
  getContractValues,
  values => (values.supplyPoint?.data || {}) as SupplyPointData
);

export const isSupplyPointFlat = createSelector(getSupplyPointData, values => (values.supplyPointIsAFlat || false) as boolean);

export const getPartnerIdValue = createSelector(getContractValues, values => values.partnerId);

export const getProcess = createSelector(getContractValues, values => values.process);

export const skipProcessSelection = createSelector(getContractValues, values => values.skipProcessSelection);

export const getTransfer = createSelector(getContractValues, values => values.transfer);

export const getTransferPreviousPartnerValue = createSelector(getTransfer, values => values && values.previousPartnerValue);

export const getRegionRuianCode = createSelector(getContractValues, values => values.regionRuianCode);

export const getDistributionZone = createSelector(getContractValues, values => values.distributionZoneValue);

export const getPreviousContractValidTo = createSelector(getSupplyPointData, values => values.currentSupplier?.validTo);
/**
 * Higher logic
 */

export const isElectricity = createSelector(getCommodityValue, value => value === COMMODITY_ELECTRICITY);

export const isGas = createSelector(getCommodityValue, value => value === COMMODITY_GAS);

export const isCompany = createSelector(getPartnerValue, value => value === PARTNER_COMPANY);

export const isHousehold = createSelector(getPartnerValue, value => value === PARTNER_HOUSEHOLD);

export const isCrossSell = createSelector(
  getPartnerValue,
  getTransferPreviousPartnerValue,
  (currentPartnerValue, previousPartnerValue) => !!previousPartnerValue && currentPartnerValue !== previousPartnerValue
);

/**
 * Form lifecycle container
 */
export const getLifecycle = createSelector(
  getProcess,
  skipProcessSelection,
  isElectricity,
  isHousehold,
  isCrossSell,
  (process, skipProcessSelection, isElectricity, isHousehold, isCrossSell) =>
    createLifecycle(process, skipProcessSelection, isElectricity, isHousehold, isCrossSell)
);

export const isTransferProcess = createSelector(getProcess, value => value === TYPE_TRANSFER);

export const isTransferNewProcess = createSelector(
  getProcess,
  value => value === TYPE_TRANSFER_NEW || value === TYPE_TRANSFER_NEW_DEATH
);

export const isNewOmOwnerChange = createSelector(
  getProcess,
  value => value === TYPE_NEW_OM_OWNER_CHANGE || value === TYPE_NEW_OM_OWNER_CHANGE_DEATH
);

/**
 * Transfer or transfer new process (suitable for most of forms)
 */
export const isTransfer = createSelector(
  getProcess,
  value => value === TYPE_TRANSFER || value == TYPE_TRANSFER_NEW || value == TYPE_TRANSFER_NEW_DEATH
);

export const isProlongation = createSelector(getProcess, value => value === TYPE_PROLONGATION);

export const getCurrentCalculate = createSelector(
  getCalculate,
  getCommodityValue,
  (calculate, commodityValue) => calculate[commodityValue] as Calculation<any>
);

export const getKnownConsumption = createSelector(getCurrentCalculate, calculate => calculate.knownConsumption as boolean);

export const getConsumption = createSelector(
  getCurrentCalculate,
  getSupplyPointData,
  (calculate, supplyPoint): SupplyPointConsumption => {
    // This could be empty, but calculate detail is filled...
    if (supplyPoint?.consumption?.consumption?.length || supplyPoint?.consumption?.consumptionHigh?.length) {
      return supplyPoint?.consumption as SupplyPointConsumption;
    }

    return {
      ...(calculate.detail || {}),
    };
  }
);

export const getFillDataType = createSelector(getContractValues, contract => contract.supplyPoint?.fillDataType || "myself");

export const getCommodity = state => {
  const commodityValue = getCommodityValue(state);
  return getEnumerationRecordByValue<Commodity>(state, ENUMERATION_COMMODITY, commodityValue);
};

export const getPartner = state => {
  const { partnerValue } = getContractValues(state);
  return getEnumerationRecordByValue<Partner>(state, ENUMERATION_PARTNER, partnerValue);
};

export const isPartnerIdSelected = createSelector(getPartnerIdValue, value => (value && value !== NEW) || false);

export const getEstimate = state => {
  const { estimateId } = getCurrentCalculate(state);
  return getEnumerationRecordById<Estimate>(state, ENUMERATION_ESTIMATE, estimateId);
};
