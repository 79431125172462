import styles from "../styles/environment.scss";

/*
 * Coefficient of gas computation (mwh/m3)
 */
export const GAS_CALORIFIC_VALUE = 0.01062;

export const COMPANY_NAME = "Pražská plynárenská , a.s";

export const PPAS_WEB_URL = "https://www.ppas.cz/";
export const PPAS_REGISTRATION = "https://moje.ppas.cz/#/registration";
export const PPAS_FORGOTTEN_PASSWORD = "https://moje.ppas.cz/#/forgottenPassword";

export const PPAS_EMAIL = "callcentrum@ppas.cz";
export const PPAS_PHONE = "+420 267 175 333";
export const PPAS_PHONE_LINK = "00420267175333";

/*
 * api/enumerations/commodities
 */
export const COMMODITY_ELECTRICITY = "E1"; // Elektřina
export const COMMODITY_GAS = "P2"; // Plyn

/*
 * api/enumerations/partners
 */
export const PARTNER_HOUSEHOLD = 1; // Domácnost
export const PARTNER_COMPANY = 2; // Podnikatel

export const UNKNOWN = "unknown";
export const NEW = "new";

export const PERIOD_MONTH = "month";
export const PERIOD_YEAR = "year";
export const PERIOD_QUARTER = "quarter";

export const CONTRACT_DELIVERY_EMAIL = "email";
export const CONTRACT_DELIVERY_POST = "post";

export const CONTRACT_VALIDITY_INDEFINITE = "indefinite";
export const CONTRACT_VALIDITY_CERTAIN_TIME = "certain";
export const CONTRACT_VALIDITY_UNKNOWN = UNKNOWN; // Not used anymore, kept for compatibility
export const CONTRACT_VALIDITY_TERMINATED = "terminated";

export const PAYMENT_METHOD_BANK_TRANSFER = "zpusobPlatbyPrikazKUhrade";
export const PAYMENT_METHOD_INKASO = "zpusobPlatbyPrimeInkaso";
export const PAYMENT_METHOD_SIPO = "zpusobPlatbySIPO";
export const PAYMENT_METHOD_CASH = "zpusobPlatbyHotove";

export const SEND_INVOICES_EMAIL = "email";
export const SEND_INVOICES_POST = "post";

export const FILL_DATA_MYSELF = "myself";
export const FILL_DATA_WITH_PPAS = "ppas";

/** Processes */

/* Acquisition */
export const TYPE_NEW_OM = "new_op";

/* Prolongation */ // should not be used right now
export const TYPE_PROLONGATION = "prolongation";

/* Transfer from portal */ // not used?
export const TYPE_TRANSFER = "transfer";

/* Transfer from SAM - customer change */
export const TYPE_TRANSFER_NEW = "transfer_new";

/* Transfer from SAM - death */
export const TYPE_TRANSFER_NEW_DEATH = "transfer_new_death";

/* Acquisition with suppliers change */
export const TYPE_NEW_OM_OWNER_CHANGE = "new_op_owner_change";

/* Acquisition with supplier change because of death */
export const TYPE_NEW_OM_OWNER_CHANGE_DEATH = "new_op_owner_change_death";

export const REGIONS = {
  praha: 19,
  jihocesky: 35,
  jihomoravsky: 116,
  karlovarsky: 51,
  kralovehradecky: 86,
  liberecky: 78,
  moravskoslezsky: 132,
  olomoucky: 124,
  pardubicky: 94,
  plzensky: 43,
  stredocesky: 27,
  ustecky: 60,
  vysocina: 108,
  zlinsky: 141,
};

export const CEZ_REGIONS = [
  REGIONS.karlovarsky,
  REGIONS.kralovehradecky,
  REGIONS.liberecky,
  REGIONS.moravskoslezsky,
  REGIONS.olomoucky,
  REGIONS.pardubicky,
  REGIONS.plzensky,
  REGIONS.stredocesky,
  REGIONS.ustecky,
];

export const PRE_REGIONS = [REGIONS.praha];
export const EGD_REGIONS = [REGIONS.jihocesky, REGIONS.vysocina, REGIONS.jihomoravsky];

export const RESOLUTION_THRESHOLD = parseInt(styles.desktopThreshold, 10);

export const EIC_PREFIX = "27ZG";
export const EAN_PREFIX = "8591824";

export const DATE_FORMAT = "D.M.YYYY";

export const AUTH_TYPE_LOGIN = "login_type";
export const AUTH_TYPE_TOKEN = "token_type";
export const AUTH_TYPE_TRANSFER = "transfer_type";

export const MEGABYTE = 1000000;
