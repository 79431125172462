import React from "react";
import PropTypes from "prop-types";
import getValue from "lodash/get";
import locs from "../../localization";
import { isCompany } from "../../helpers";

import dateFormatter from "../../formatters/date";
import addressFormatter from "../../formatters/address";
import fullNameFormatter from "../../formatters/fullName";

import { CONTRACT_DELIVERY_EMAIL } from "../../constants";
import { ENUMERATION_POSITIONS } from "../../constants/enumerations";

import Block from "./Block";
import Row from "./Row";
import { getEnumerationRecordById } from "../../reducers/enumerations";
import { Enumerations, Partner } from "../../constants/interfaces";

interface ContactInfoProps {
  toTheSpecificPage: () => void;
  data?: any;
  enumerations: Enumerations;
  partner?: Partner;
  transfer?: boolean;
}

function contactInfo({ toTheSpecificPage, data = {}, partner, enumerations, transfer = false }: ContactInfoProps) {
  const deliveryAddressIsSame = getValue(data, "addresses.deliveryAddressIsSame");
  const permanentAddress = getValue(data, "addresses.permanentAddress");
  const deliveryAddress = deliveryAddressIsSame ? permanentAddress : getValue(data, "addresses.deliveryAddress");

  const cDelivery = getValue(data, "contractDelivery");
  const cDeliveryLabel = locs(`labels.${cDelivery === CONTRACT_DELIVERY_EMAIL ? "toEmail" : "toDeliveryAddress"}`);

  const positionId = getValue(data, "person.positionId");
  const { desc: position } = getEnumerationRecordById(enumerations, ENUMERATION_POSITIONS, parseInt(positionId, 10)) || {};

  return (
    <Block title={`${locs("titles.contactInfo")} ${transfer ? locs("titles.newCustomer") : ""}`} editData={toTheSpecificPage}>
      {isCompany(partner) && [
        <Row key={1} term={locs("labels.companyName")} value={data.name} />,
        <Row key={2} term={locs("labels.companyIn")} value={data.in} />,
        <Row key={3} term={locs("labels.companyTin")} value={data.tin} />,
      ]}
      <Row term={locs("labels.fullName")} value={fullNameFormatter(getValue(data, "person"), enumerations)} />
      {isCompany(partner) && <Row key={1} term={locs("labels.position")} value={position} />}
      <Row term={locs("labels.birthDate")} value={dateFormatter(getValue(data, "person.birthDate"))} />
      <Row term={locs("labels.email")} value={getValue(data, "person.email")} />
      <Row term={locs("labels.phone")} value={getValue(data, "person.phone")} />
      <Row
        term={isCompany(partner) ? locs("titles.company_address") : locs("labels.permanentAddress")}
        value={addressFormatter(permanentAddress)}
      />
      <Row term={locs("labels.deliveryAddress")} value={addressFormatter(deliveryAddress)} />
      {!transfer && <Row term={locs("labels.sendContractDraftTo")} value={cDeliveryLabel} />}
    </Block>
  );
}

export default contactInfo;
