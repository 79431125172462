import React from "react";
import classNames from "classnames";
import locs from "../../../localization";
import Button from "../../../components/Button";
import Title from "../../../components/Title";
import Column from "../../../containers/Column";
import styles from "./styles.scss";

interface LoginProps {
  signInView: () => void;
  onNotCustomer: () => void;
}

export default (props: LoginProps) => {
  const { signInView, onNotCustomer } = props;

  return (
    <div className={styles.login_container}>
      <Title name="titles.pp_customer" tag="h3" className={styles.login_title} />
      <div className={styles.centered}>
        <div>{locs("texts.login_description")}</div>
      </div>
      <div className={styles.divider} />
      <Column>
        <div className={styles.button_container}>
          <div className={classNames(styles.icon, styles.icon_existing)} />
          <Button onClick={signInView}>{locs("actions.yes")}</Button>
        </div>
        <div className={styles.button_container}>
          <div className={classNames(styles.icon, styles.icon_new)} />
          <Button onClick={onNotCustomer}>{locs("actions.no")}</Button>
        </div>
      </Column>
    </div>
  );
};
