import { createSelector } from "reselect";
import { AUTH_TYPE_TRANSFER } from "../constants";
import { DATA_SUCCESS } from "../constants/async";
import { GlobalState } from "../reducers/types";

export const getUserData = (state: GlobalState) => state.user;

export const getStatus = (state: GlobalState) => state.user.status;

export const getWaitForData = (state: GlobalState) => state.user.waitForData;

export const getAuthType = (state: GlobalState) => state.user.authType;

export const getFormData = (state: GlobalState) => state.user.formData;

export const getError = state => state.user.error;

export const getPartners = (state: GlobalState) => state.user.partners;

export const isSigned = createSelector(getAuthType, authType => !!authType && authType !== AUTH_TYPE_TRANSFER);

export const isUserReady = createSelector(
  getStatus,
  getWaitForData,
  (status, waitForData) => status === DATA_SUCCESS || !waitForData
);

export const getLogin = createSelector(isSigned, getUserData, (signed, { login }) => signed && login);

export const getName = createSelector(isSigned, getUserData, (signed, { name }) => signed && name);

export const getProcessFromUser = createSelector(isSigned, getFormData, (signed, formData) =>
  signed ? formData?.process : undefined
);
