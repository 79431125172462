import validator from "./util/createValidator";

const defaults = {
  expression: /^E?\d+[a-z]?$/i,
  nullable: true,
};

export function isHouseNumber(value, settings) {
  return settings.expression.test(value);
}

export default validator(isHouseNumber, "isHouseNumber", defaults);
