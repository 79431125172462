import isEmpty from "lodash/isEmpty";
import { CONTRACT_RESTORE_FAILURE, CONTRACT_RESTORE_REQUEST, CONTRACT_RESTORE_SUCCESS } from "../constants/actions";
import initialValues from "../forms/initialValues";
import merge from "../helpers/merge";

export const contractInitialState = initialValues;

export default (state = contractInitialState, action) => {
  switch (action.type) {
    case CONTRACT_RESTORE_REQUEST:
      return null;
    case CONTRACT_RESTORE_SUCCESS:
      return merge(action.payload, initialValues);
    case CONTRACT_RESTORE_FAILURE:
      return initialValues;
    default:
      return state;
  }
};
