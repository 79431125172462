import React from "react";
import classNames from "classnames";
import styles from "./styles.scss";
import { seleniumClass, showErrorMessage } from "../../helpers";
import { WrappedFieldProps } from "redux-form";

export interface CheckboxProps extends WrappedFieldProps {
  theme?: {
    checkbox?: string;
    baseContainer?: string;
    label?: string;
  };
  label?: string;
  disabled?: boolean;
  checked?: boolean;
  dangerHTML?: boolean;
}

export default function Checkbox({ input, label = null, disabled = false, theme = {}, dangerHTML = false, meta }: CheckboxProps) {
  const inputClassNames = classNames(theme.checkbox, styles.checkbox);
  const baseContainerClassNames = classNames(theme.baseContainer, styles.baseContainer);
  const labelClassNames = classNames(theme.label, styles.label);
  const isInvalid = () => meta?.invalid && meta?.touched;

  const error = isInvalid() && showErrorMessage(meta.error) && <div className={styles.errorMessage}>{meta.error}</div>;

  let labelDiv = <div className={labelClassNames}>{label}</div>;

  if (dangerHTML) {
    labelDiv = <div className={styles.labelClassNames} dangerouslySetInnerHTML={{ __html: label }} />;
  }

  return (
    <div className={baseContainerClassNames}>
      <label className={seleniumClass(input)}>
        <div className={styles.input_container}>
          <input type="checkbox" {...input} disabled={disabled} className={inputClassNames} />
          <span />
        </div>
        {labelDiv}
        {error}
      </label>
    </div>
  );
}
