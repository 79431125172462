import React from "react";
import Header from "./Header";

interface BlockProps {
  title: string;
  children?: React.ReactNode;
  editData?: () => void;
}

export default function block(props: BlockProps) {
  const { title, editData, children } = props;
  return (
    <div>
      <Header title={title} editData={editData} />
      <dl>{children}</dl>
    </div>
  );
}
