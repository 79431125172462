import React, { FunctionComponent } from "react";
import classNames from "classnames";
import styles from "./styles.scss";

export interface SeparatorProps {
  className?: string;
}

const Separator: FunctionComponent<SeparatorProps> = ({ className, children }) => {
  if (children) {
    return <p className={classNames(className, styles.separatorWithText)}>{children}</p>;
  }
  return <hr className={classNames(className, styles.separator)} />;
};

export default Separator;
