import locs from "../localization";
import {
  CONTRACT_VALIDITY_CERTAIN_TIME,
  CONTRACT_VALIDITY_TERMINATED,
  CONTRACT_VALIDITY_INDEFINITE,
  CONTRACT_VALIDITY_UNKNOWN,
} from "../constants";

interface ContractValidity {
  contractValidity?: string;
  validTo?: string;
  noticePeriod?: string;
}

export default function contractValidity(value: ContractValidity) {
  const contractValidities = {
    [CONTRACT_VALIDITY_CERTAIN_TIME]: locs("labels.contractValidityCertainTime"),
    [CONTRACT_VALIDITY_INDEFINITE]: locs("labels.contractValidityIndefinite"),
    [CONTRACT_VALIDITY_UNKNOWN]: locs("labels.contractValidityUnknown"),
    [CONTRACT_VALIDITY_TERMINATED]: locs("labels.contractValidityTerminated"),
  };

  const showDate =
    value.contractValidity === CONTRACT_VALIDITY_CERTAIN_TIME || value.contractValidity === CONTRACT_VALIDITY_TERMINATED;
  const showNoticePeriod =
    value.contractValidity == CONTRACT_VALIDITY_CERTAIN_TIME || value.contractValidity == CONTRACT_VALIDITY_INDEFINITE;

  let formattedValue = contractValidities[value.contractValidity] || "-";
  if (showDate && value.validTo) {
    const conjunction = value.contractValidity === CONTRACT_VALIDITY_TERMINATED ? `\n${locs("labels.to")}` : locs("labels.until");
    formattedValue = `${formattedValue} ${conjunction} ${value.validTo}`;
  }

  if (showNoticePeriod) {
    const noticePeriod = value.noticePeriod ? `${locs("labels.noticePeriod")} ${value.noticePeriod} ${locs("labels.days")}` : "";
    formattedValue = `${formattedValue}\n${noticePeriod}`;
  }
  return formattedValue;
}
