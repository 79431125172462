import React from "react";
import classNames from "classnames";
import styles from "./styles.scss";

export interface LinkProps {
  className?: string;
  onClick?: (e: any) => void;
  role?: string;
  label?: string;
}

export default function Link({ label, role = "button", className, onClick }: LinkProps) {
  const labelClassNames = classNames(className, styles.link);

  const onClickHandler = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <span className={labelClassNames} onClick={onClickHandler} role={role}>
      {label}
    </span>
  );
}
