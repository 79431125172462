import { getEnumerationRecordById } from "../reducers/enumerations";
import { ENUMERATION_TITLE_PREFIX, ENUMERATION_TITLE_SUFFIX } from "../constants/enumerations";

const getTitle = (enumerations, name, id) => {
  const { desc } = getEnumerationRecordById(enumerations, name, parseInt(id, 10)) || {};
  return desc;
};

export default function fullName(
  value: { name?: string; surname?: string; titlePrefixId?: number; titleSuffixId?: number },
  enumerations = {}
) {
  if (!value) return "";

  const titleBefore = getTitle(enumerations, ENUMERATION_TITLE_PREFIX, value.titlePrefixId);
  const titleAfter = getTitle(enumerations, ENUMERATION_TITLE_SUFFIX, value.titleSuffixId);

  const values = [titleBefore, value.name, value.surname, titleAfter];
  return values.filter(val => !!val).join(" ");
}
