import zipFormatter from "./zip";

export interface Address {
  street?: string;
  houseNumber?: string;
  streetNumber?: string;
  parcelNumber?: string;
  number?: string;
  registrationNumber?: string;
  zip?: string;
  city?: string;
  cityPart?: string;
}

interface OptionParams {
  singleLine?: boolean;
  includeRegistrationNumber?: boolean;
}

export default function address(value?: Address, opts?: OptionParams) {
  if (!value) return "";

  const { singleLine = false, includeRegistrationNumber = false } = opts || {};

  let formattedAddress = value.street || value.cityPart || "";

  let number = "";
  if (value.houseNumber && value.streetNumber) {
    number = `${value.houseNumber}/${value.streetNumber}`;
  } else if (value.houseNumber || value.streetNumber) {
    number = value.houseNumber || value.streetNumber;
  } else if (value.parcelNumber) {
    number = value.parcelNumber;
  } else if (includeRegistrationNumber && value.registrationNumber) {
    number = `č.ev. ${value.registrationNumber}`;
  }

  const city = (value.zip ? `${zipFormatter(value.zip)}, ${value.city}` : value.city) || "";
  const separator = singleLine ? ", " : "\n";
  formattedAddress = `${formattedAddress} ${number}${separator}${city}`;
  return formattedAddress;
}
