import { CONTRACT_CREATE_PDF_FAILURE, CONTRACT_CREATE_PDF_SUCCESS, CONTRACT_CREATE_PDF_REQUEST } from "../constants/actions";

import { DATA_FAILURE, DATA_REQUEST, DATA_SUCCESS } from "../constants/async";

export interface PdfState {
  pdfStatus?: string;
  error?: string;
}

export const pdfInitialState: PdfState = {
  pdfStatus: null,
};

export default (state = pdfInitialState, action) => {
  switch (action.type) {
    case CONTRACT_CREATE_PDF_REQUEST:
      return {
        ...state,
        pdfStatus: DATA_REQUEST,
      };

    case CONTRACT_CREATE_PDF_SUCCESS:
      return {
        ...state,
        pdfStatus: DATA_SUCCESS,
      };

    case CONTRACT_CREATE_PDF_FAILURE:
      return {
        ...state,
        pdfStatus: DATA_FAILURE,
        error: action.payload,
      };

    default:
      return state;
  }
};
