import SignatureCanvas from "react-signature-canvas";

/**
 * Returns a validation function that checks if the canvas is "complex enough"
 * Complexity is defined as the number of points (not pixels) in the canvas.
 * Simple lines do not need much points, and a generic signature should be more than a line
 * Currently checks for 5 points.
 *
 * @param canvasRef A reference to the SignatureCanvas component to validate.
 * @returns A validation function that returns true if the number of points is valid.
 */
export default function isComplexValidation(canvasRef: SignatureCanvas) {
  function isComplex(_value: unknown) {
    if (!canvasRef) {
      return true;
    }
    const strokes = canvasRef.toData();
    let count = 0;
    strokes.forEach(stroke => {
      count += stroke.length; // points in a stroke
    });
    return count >= 5;
  }

  return isComplex;
}
