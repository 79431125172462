import React from "react";
import { Field, formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import { connect, ConnectedProps } from "react-redux";
import locs from "../../localization";

import { getProduct, isProlongation } from "../../selectors/contract";

import Container from "../../containers/Container";
import Column from "../../containers/Column";
import Title from "../../components/Title";
import Button from "../../components/Button";
import Link from "../../components/Link";
import ProgressBar from "../../components/ProgressBar";
import Payments from "../Payments";
import styles from "./styles.scss";
import { compose } from "redux";
import { Pages } from "../../lifecycles";
import { handleFormChangePayments } from "../Payments/PaymentMethods";
import InlineLoader from "../../components/Loader/InlineLoader";

const formKey = "calculator";

// Decorate with connect to read form values
const selector = formValueSelector(formKey);

function mapStateToProps(state) {
  return {
    email: selector(state, "contact.person.email"),
    product: getProduct(state),
    isProlongation: isProlongation(state),
  };
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface PaymentsFormProps {
  signed: boolean;
  previousPage: () => void;
  onSubmit: () => void;
}

function PaymentsForm(props: PropsFromRedux & PaymentsFormProps & InjectedFormProps) {
  const { handleSubmit, previousPage, product = {}, email = null, isProlongation, submitting } = props;

  return (
    <Container>
      <ProgressBar page={Pages.Payment} />
      <Column>
        <Title name={locs("titles.payment_information")} underline />
      </Column>
      <Field
        name="payments"
        component={Payments}
        props={{
          product,
          defaultEmail: email,
          disabled: isProlongation,
        }}
      />
      <div className={styles.footer}>
        <Link label={locs("actions.go_back")} onClick={previousPage} />
        <Button disabled={submitting} onClick={handleSubmit}>
          {submitting ? <InlineLoader height={12} /> : locs("actions.next")}
        </Button>
      </div>
    </Container>
  );
}

export default compose(
  connector,
  reduxForm({
    form: formKey, // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    onChange: handleFormChangePayments,
  })
)(PaymentsForm);
