import { EIC_PREFIX } from "../constants";

export default function eic(value?: string) {
  let formattedValue = value ? String(value).replace(/\s/g, "") : "";

  if (formattedValue.indexOf(EIC_PREFIX) === 0) {
    formattedValue = formattedValue.slice(EIC_PREFIX.length);
  }
  return formattedValue.substring(0, 12).toUpperCase();
}
