import {
  ENUMERATION_FAILURE,
  CONTRACT_SAVE_FAILURE,
  CONTRACT_SAVE_SUCCESS,
  CONTRACT_CONFIRM_FAILURE,
  CONTRACT_CONFIRM_SUCCESS,
  CONTRACT_PRODUCTS_FAILURE,
  CONTRACT_PRODUCTS_SUCCESS,
  CONTRACT_CREATE_PDF_FAILURE,
  CONTRACT_CREATE_PDF_SUCCESS,
} from "../constants/actions";

export const initialState = {
  error: null,
  count: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ENUMERATION_FAILURE:
    case CONTRACT_SAVE_FAILURE:
    case CONTRACT_CONFIRM_FAILURE:
    case CONTRACT_PRODUCTS_FAILURE:
    case CONTRACT_CREATE_PDF_FAILURE:
      return {
        error: action.type,
        count: state.count + 1,
        query: action.query || null,
        status: action.status || null,
      };
    case CONTRACT_SAVE_SUCCESS:
      return state.error === CONTRACT_SAVE_FAILURE ? initialState : state;
    case CONTRACT_CONFIRM_SUCCESS:
      return state.error === CONTRACT_CONFIRM_FAILURE ? initialState : state;
    case CONTRACT_PRODUCTS_SUCCESS:
      return state.error === CONTRACT_PRODUCTS_FAILURE ? initialState : state;
    case CONTRACT_CREATE_PDF_SUCCESS:
      return state.error === CONTRACT_CREATE_PDF_FAILURE ? initialState : state;
    default:
      return state;
  }
};
