import React from "react";

import Radio from "../../../components/Radio";
import Column from "../../../containers/Column";

import { COMMODITY_ELECTRICITY, COMMODITY_GAS } from "../../../constants";

import styles from "./styles.scss";
import { Field } from "redux-form";

interface SelectCommodityProps {
  name: string;
  theme?: any;
}

const SelectCommodity = ({ name, theme = {} }: SelectCommodityProps) => (
  <Column columns={1}>
    <Field
      name={name}
      component={Radio}
      type="radio"
      value={COMMODITY_GAS}
      props={{
        highlightLabelOnChecked: true,
        theme: {
          ...styles,
          ...theme,
          radio: styles.radio_gas,
          radio_checked: styles.radio_gas_checked,
        },
        label: "Plyn",
      }}
    />
    <Field
      name={name}
      component={Radio}
      type="radio"
      value={COMMODITY_ELECTRICITY}
      props={{
        highlightLabelOnChecked: true,
        theme: {
          ...styles,
          ...theme,
          radio: styles.radio_electricity,
          radio_checked: styles.radio_electricity_checked,
        },
        label: "Elektřina",
      }}
    />
  </Column>
);

export default SelectCommodity;
