import React, { useEffect, useState } from "react";

import Column from "../../containers/Column";
import locs from "../../localization";
import Title from "../../components/Title";
import Group from "../../components/Group";
import Input from "../../components/Input";
import SendInvoices from "./SendInvoices";
import PaymentMethods from "./PaymentMethods";
import { priceFormatter } from "../../helpers";

import { PERIOD_MONTH, PERIOD_QUARTER, RESOLUTION_THRESHOLD } from "../../constants";

import styles from "./styles.scss";
import theme from "./theme.scss";
import { Field, InjectedFormProps, WrappedFieldProps } from "redux-form";
import { Product } from "../../reducers/types";
import TooltipContainer from "../../containers/TooltipContainer";

interface PaymentProps {
  product?: Product;
  defaultEmail?: string;
  disabled?: boolean;
}

function Payments(props: PaymentProps & WrappedFieldProps & InjectedFormProps) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < RESOLUTION_THRESHOLD);

  const {
    disabled,
    input: {
      name,
      value: { depositAmount, paymentsFrequency },
    },
  } = props;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < RESOLUTION_THRESHOLD);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const overlay = (
    <div className={styles.inkasoTooltipContent} dangerouslySetInnerHTML={{ __html: locs("texts.paymentsTooltip") }} />
  );

  const depositComponent = (
    <div className={styles.depositAmountContainer}>
      <div className={styles.labelContainer}>{locs("labels.depositAmount")}</div>
      <div>
        <div className={styles.depositAmount}>
          {priceFormatter(depositAmount)} Kč {locs(`labels.${paymentsFrequency}`)}
        </div>
        <div>Cena je včetně DPH</div>
      </div>
    </div>
  );

  return (
    <div className={styles.paymentsContainer}>
      <Title name={locs("titles.setup_payments")} tag="h3" className={styles.title} />
      {isMobile ? (
        <>
          {depositComponent}
          {overlay}
        </>
      ) : (
        <TooltipContainer
          placement="rightTop"
          overlay={overlay}
          trigger="none"
          visible={true}
          defaultVisible={true}
          align={{ offset: [15, -2] }}
        >
          {depositComponent}
        </TooltipContainer>
      )}
      <Column>
        <Group
          label={locs("labels.paymentsFrequency")}
          name={`${name}.paymentsFrequency`}
          theme={theme}
          data={[
            { label: locs("labels.monthly"), value: PERIOD_MONTH, disabled },
            { label: locs("labels.quarterly"), value: PERIOD_QUARTER, disabled },
          ]}
        />
      </Column>
      <Field name={`${name}.paymentMethods`} type="text" component={PaymentMethods} props={{ disabled }} />
      <Title name={locs("titles.howToSendInvoices")} tag="h3" className={styles.title} />
      <Field name={`${name}.sendInvoices`} type="text" component={SendInvoices} props={{ disabled }} />
      <Field name={`${name}.depositAmount`} type="hidden" component={Input} />
    </div>
  );
}

export default Payments;
