import React from "react";
import Expand from "../../containers/Expand";
import Input from "../../components/Input";
import { isHousehold } from "../../helpers";

import locs from "../../localization";

import BasicInfo from "./BasicInfo";
import ContactInfo from "./ContactInfo";
import PaymentsInfo from "./PaymentsInfo";
import SupplyPointInfo from "./SupplyPointInfo";
import InvoiceFile from "../SupplyPoint/InvoiceFile";

import styles from "./styles.scss";

import { Commodity, Enumerations, Partner } from "../../constants/interfaces";
import { Field, WrappedFieldProps } from "redux-form";
import { Lifecycle, Pages } from "../../lifecycles";

interface RecapitulationProps {
  commodity?: Commodity;
  partner?: Partner;
  enumerations?: Enumerations;
  lifecycle?: Lifecycle;
  toTheSpecificPage?: (page: string) => void;
  contractData: any;
}

export default function Recapitulation(props: WrappedFieldProps & RecapitulationProps) {
  const {
    commodity,
    partner,
    enumerations,
    toTheSpecificPage,
    contractData,
    lifecycle,
    input: { name, value: { isInvoiceVisible } = {} },
  } = props;

  const { invoicesCount } = contractData;

  const handlePageRedirect = i => () => {
    if (toTheSpecificPage) {
      toTheSpecificPage(i);
    }
  };

  return (
    <div className={styles.recapitulation}>
      <BasicInfo commodity={commodity} data={contractData.product} />
      <ContactInfo
        toTheSpecificPage={handlePageRedirect("/contact")}
        data={contractData.contact}
        partner={partner}
        enumerations={enumerations}
      />
      {lifecycle.hasDesktopPage(Pages.Payment) && (
        <PaymentsInfo
          data={contractData.payments}
          enumerations={enumerations}
          toTheSpecificPage={handlePageRedirect("/payments")}
        />
      )}
      {lifecycle.hasDesktopPage(Pages.SupplyPoint) && (
        <SupplyPointInfo
          key={2}
          data={contractData}
          enumerations={enumerations}
          toSupplyPoint={handlePageRedirect("/supply_point")}
        />
      )}
      <Field
        name={`${name}.isInvoiceVisible`}
        type="text"
        component={Expand}
        props={{
          showLabel: invoicesCount ? locs("actions.lastInvoiceUploaded", invoicesCount) : locs("actions.uploadLastInvoice"),
          hideLabel: invoicesCount ? locs("actions.lastInvoiceUploaded", invoicesCount) : locs("actions.uploadLastInvoice"),
          iconType: "arrows",
        }}
      />
      <div className={styles.invoice} style={{ display: isInvoiceVisible ? "block" : "none" }}>
        <InvoiceFile name={name} />
      </div>
    </div>
  );
}
