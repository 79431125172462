import SignatureCanvas from "react-signature-canvas";

/**
 * Returns a validation function that checks if the total length of the strokes is long enough.
 * Currently the length is set to 100 pixels
 *
 * @param canvasRef A reference to the SignatureCanvas component to validate.
 * @returns A validation function that returns true if the canvas area is valid.
 */
export default function isLongValidation(canvasRef: SignatureCanvas) {
  function isLong(_value: unknown) {
    if (!canvasRef) {
      return true;
    }
    const strokes = canvasRef.toData();
    let totalLength = 0;
    strokes.forEach(stroke => {
      for (let i = 0; i < stroke.length - 1; i++) {
        const x1 = stroke[i].x;
        const y1 = stroke[i].y;
        const x2 = stroke[i + 1].x;
        const y2 = stroke[i + 1].y;
        const length = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
        totalLength += length;
      }
    });
    return totalLength >= 100;
  }

  return isLong;
}
