const PHONE_PREFIX = "+420";
export const PHONE_MASK = [/\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/];

export function phoneNumberFormatter(value?: string) {
  let formattedValue = value ? String(value).replace(/\s/g, "") : "";

  // begins with 420 => change to +420
  if (formattedValue.substring(0, 4) === PHONE_PREFIX) {
    formattedValue = formattedValue.substr(4);
  }

  // add spaces between each 3 digits
  formattedValue = formattedValue.replace(/(\d{3})/g, "$1 ");
  formattedValue = formattedValue.trim();
  return formattedValue;
}

export function phoneNormalizer(value?: string) {
  let formattedValue = value ? String(value).replace(/\s/g, "") : "";
  if (formattedValue.substring(0, 4) !== PHONE_PREFIX) {
    formattedValue = `+420${formattedValue}`;
  }
  return formattedValue;
}
