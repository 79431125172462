import React from "react";
import BankAccount from "../../composites/BankAccount";
import Column from "../../containers/Column";
import Group from "../../components/Group";
import Input from "../../components/Input";
import FixedTooltip from "../../components/FixedTooltip";
import locs from "../../localization";
import styles from "./styles.scss";
import theme from "./theme.scss";

import formValidation from "../../validations/formValidation";
import isSipo from "../../validations/isSipo";
import isBankAccount from "../../validations/isBankAccount";
import isNumber from "../../validations/isNumber";
import { PaymentMethod as EnumerationPaymentMethod } from "../../constants/interfaces";

import { PAYMENT_METHOD_BANK_TRANSFER, PAYMENT_METHOD_INKASO, PAYMENT_METHOD_SIPO } from "../../constants";
import isLength from "../../validations/isLength";
import { Field, WrappedFieldProps } from "redux-form";
import isNotEmpty from "../../validations/isNotEmpty";

interface PaymentMethodProps {
  paymentMethods: EnumerationPaymentMethod[];
  disabled?: boolean;
  disabledValues?: boolean;
  inkasoTooltip?: string;
}

export default function PaymentMethod(props: PaymentMethodProps & WrappedFieldProps) {
  const {
    input: { name, value: { paymentType } = {} },
    paymentMethods,
    disabled = false,
    disabledValues,
  } = props;

  return (
    <div className={styles.paymentMethodContainer}>
      <Column>
        <Group
          label={locs("labels.paymentType")}
          name={`${name}.paymentType`}
          theme={theme}
          data={paymentMethods.map(p => ({ label: p.name, value: p.value, disabled }))}
        />
      </Column>
      {paymentType === PAYMENT_METHOD_SIPO && (
        <Column columns={2} weights={["1_11", 1]}>
          <Field
            name={`${name}.sipo`}
            type="text"
            component={Input}
            props={{
              disabled: disabled || disabledValues,
              theme: {
                inputContainer: theme.emailInputContainer,
                baseContainer: theme.emailBaseContainer,
              },
            }}
            validate={
              !disabled && [
                formValidation(isNotEmpty),
                formValidation(isSipo),
                formValidation(isLength({ max: 40 })),
                formValidation(isNumber),
              ]
            }
            label={locs("labels.connectionNumber")}
          />
        </Column>
      )}
      {(paymentType === PAYMENT_METHOD_BANK_TRANSFER || paymentType === PAYMENT_METHOD_INKASO) && (
        <Field
          name={`${name}.bankAccount`}
          type="text"
          label={locs("labels.yourBankAccount")}
          validate={!disabled && [formValidation(isBankAccount)]}
          component={BankAccount}
          props={{
            disabled: disabled || disabledValues,
          }}
        />
      )}
    </div>
  );
}
