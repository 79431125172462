import React, { useState } from "react";
import Dialog from "../../containers/Dialog";
import Title from "../../components/Title";
import Button from "../../components/Button";
import FormGroup from "../../components/UI/FormGroup";
import FailImage from "../../../img/ic-fail-image.svg";

import locs from "../../localization";
import styles from "./styles.scss";
import { PPAS_WEB_URL } from "../../constants";

export interface ErrorDialogProps {
  onClick: () => Promise<void> | void;
  message?: string;
}

function ErrorDialog(props: ErrorDialogProps) {
  const { message, onClick } = props;
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleClick = () => {
    if (submitting) return;

    const cb = onClick();
    if (cb instanceof Promise) {
      setSubmitting(true);
      cb.then(() => setSubmitting(false)).catch(() => setSubmitting(false));
    }
  };

  return (
    <Dialog closable={false}>
      <div className={styles.container}>
        <FailImage className={styles.icon} />
        <Title tag="h3" name={message || locs("titles.error")} className={styles.title} />
        <FormGroup className={styles.buttonContainer}>
          <Button onClick={handleClick}>{submitting ? locs("actions.tryingAgain") : locs("actions.tryAgain")}</Button>
        </FormGroup>
        <a href={PPAS_WEB_URL}>{locs("actions.backToBeginning")}</a>
      </div>
    </Dialog>
  );
}

export default ErrorDialog;
