import validator from "./util/createValidator";

const defaults = {
  nullable: true,
};

export function isDIC(value: string): boolean {
  const regex = /^CZ\d{8,10}$/i;
  return regex.test(value);
}

export default validator(isDIC, "isDIC", defaults);
