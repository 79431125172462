import React from "react";
import { isElectricity } from "../../helpers";
import locs from "../../localization";

import Block from "./Block";
import Row from "./Row";
import { Commodity } from "../../constants/interfaces";

interface BasicInfoProps {
  commodity?: Commodity;
  data?: { title?: string; prices?: { contractDuration?: number } };
}

export default function basicInfo(props: BasicInfoProps) {
  const {
    commodity,
    data,
    data: { prices: { contractDuration } = {} },
  } = props;

  return (
    <Block title={locs("titles.basicInfo")}>
      <Row term={locs("labels.commodity")} value={locs(`labels.${isElectricity(commodity) ? "electricity" : "gas"}`)} bold />
      <Row term={locs("labels.selectedProduct")} value={data.title} bold />
      {contractDuration > 0 && (
        <Row term={locs("labels.contractDuration")} value={locs(`contractDuration.${contractDuration}`)} bold />
      )}
    </Block>
  );
}
