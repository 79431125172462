import { PERIOD_MONTH, PERIOD_QUARTER, PERIOD_YEAR } from "../constants";
import { ProductPrices } from "../reducers/types";

/**
 * @param {Number} price
 * @param input
 * @param output
 */
export function priceConversion(price = 0, input = PERIOD_MONTH, output = PERIOD_MONTH) {
  const durations = {
    [PERIOD_MONTH]: 1,
    [PERIOD_QUARTER]: 3,
    [PERIOD_YEAR]: 12,
  };
  const ratio = durations[input] / durations[output];
  return price / ratio;
}

export function productPriceConversion(
  price: ProductPrices,
  input = PERIOD_MONTH,
  output = PERIOD_MONTH
): Omit<ProductPrices, "contractDuration"> {
  const supplies = Math.ceil(priceConversion(price.supplies, input, output));
  const distribution = Math.ceil(priceConversion(price.distribution, input, output));
  const taxes = Math.ceil(priceConversion(price.taxes, input, output));

  return {
    supplies,
    distribution,
    taxes,
    total: supplies + distribution + taxes,
  };
}

/**
 * @param {Number} price
 * @param {Number} fractionDigits
 * @param {String} [s = ' '] sections delimiter
 * @param {String} [c = ','] decimal delimiter
 * @returns {string}
 */
export function priceFormatter(price = 0, fractionDigits = 0, s = " ", c = ",") {
  const number = (price || 0).toFixed(Math.max(0, fractionDigits));

  const re = `\\d(?=(\\d{3})+${fractionDigits > 0 ? "\\D" : "$"})`;
  return (c ? number.replace(".", c) : number).replace(new RegExp(re, "g"), `$&${s}`);
}
