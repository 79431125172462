import React, { ComponentType } from "react";
import classNames from "classnames";
import locs from "../../localization";
import styles from "./styles.scss";

export interface TitleProps {
  name?: string;
  value?: any;
  className?: string;
  tag?: string;
  underline?: boolean;
  big?: boolean;
}

export default function Title({ name, value, className, underline = false, tag = "h2", big = false }: TitleProps) {
  if (!value) {
    value = locs(name);
  }

  const tagClassNames = classNames(styles.title, className, { [styles.underline]: underline, [styles.big]: big });
  const Tag = `${tag}` as unknown as ComponentType<{ className?: string }>;

  return <Tag className={tagClassNames}>{value}</Tag>;
}
