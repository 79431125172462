import React, { RefObject, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import Link from "../Link";
import styles from "./styles.scss";
import classNames from "classnames";
import locs from "../../localization";
import { WrappedFieldProps } from "redux-form";
import { showErrorMessage } from "../../helpers";

export interface SignatureProps {
  canvasRef: RefObject<SignatureCanvas>;
}

export default function Signature({ input, meta, canvasRef }: SignatureProps & WrappedFieldProps) {
  const isInvalid = () => meta?.invalid && meta?.touched;
  const error = isInvalid() && showErrorMessage(meta.error) && <div className={styles.errorMessage}>{meta.error}</div>;
  const canvasClassNames = classNames(styles.signatureCanvas, { [styles.invalidCanvas]: isInvalid() });

  useEffect(() => {
    clearSignatureCanvas();
  }, []);

  const clearSignatureCanvas = () => {
    canvasRef.current.clear();
    saveSignature();
  };

  const saveSignature = () => {
    if (canvasRef.current.isEmpty()) {
      input.onChange(null);
      return;
    }
    input.onChange(canvasRef.current.toDataURL());
  };

  return (
    <div className={styles.signatureContainer}>
      <SignatureCanvas
        penColor="#096daf" // taken from PPas logo manual as their "lighter blue"
        clearOnResize={false}
        canvasProps={{ className: canvasClassNames }}
        ref={canvasRef}
        onEnd={saveSignature}
      />
      <div className={styles.bottomRow}>
        <div style={{ flexGrow: 1 }}>{error}</div>
        <Link label={locs("labels.delete")} onClick={clearSignatureCanvas} />
      </div>
    </div>
  );
}
