import { FormAction, change, untouch } from "redux-form";
import { get } from "../api";
import { batch } from "react-redux";

/**
 * Applies Ares data to the form fields.
 * @param param - The parameter for the Ares request.
 * @param addressCode - The code representing the address in the form.
 */
export function applyAresData(param: string, addressCode: string) {
  return async (dispatch: (arg: FormAction) => void) => {
    const response = await get(`/ares/${param}`);
    const {
      name,
      tin,
      address: { street, house_number, street_number, city, postal_code, ruian_code },
    } = response;

    // Batch to prevent multiple re-renders
    batch(() => {
      dispatch(change("calculator", "contact.name", name, true));
      dispatch(change("calculator", "contact.tin", tin, true));
      dispatch(change("calculator", `${addressCode}.street`, street, true));
      dispatch(change("calculator", `${addressCode}.houseNumber`, house_number, true));
      dispatch(change("calculator", `${addressCode}.streetNumber`, street_number, true));
      dispatch(change("calculator", `${addressCode}.city`, city, true));
      dispatch(change("calculator", `${addressCode}.zip`, postal_code, true));
      dispatch(change("calculator", `${addressCode}.addressToggle`, true, true));
      dispatch(change("calculator", `${addressCode}.ruianCode`, ruian_code, true));
    });
  };
}

/**
 * Cleans the Ares data by resetting and marking specified fields as untouched.
 * @param addressCode - The code representing the address in the form.
 */
export function cleanAresData(addressCode: string) {
  return (dispatch: (arg: FormAction) => void) => {
    batch(() => {
      const fields = [
        "contact.name",
        "contact.tin",
        `${addressCode}.street`,
        `${addressCode}.houseNumber`,
        `${addressCode}.streetNumber`,
        `${addressCode}.city`,
        `${addressCode}.zip`,
        `${addressCode}.addressToggle`,
        `${addressCode}.ruianCode`,
      ];

      fields.forEach(field => {
        dispatch(change("calculator", field, "")); // reset the field to initial value
        dispatch(untouch("calculator", field)); // mark the field as untouched
      });
    });
  };
}
